import { APIConfiguration, axiosBION, axiosMYLAB } from 'api';
import { AxiosResponse } from 'axios';
import i18n from 'i18n';
import { GetNewsProps } from 'models/news.model';
import { FAQ } from 'models/faq.model';
import { Center, CenterIncludedFile, CenterIncludedService, CenterIncludedUpdate, CentersSearchResults } from 'models/centers.model';
import { DrupalTypes } from 'models/drupal.model';
import { BaseService } from './base.service';
import { FavoriteLab } from 'pages/patient/centers/types';

type SearchCenter = {
  lang: string;
  city?: string | number;
  specialities?: string[];
  title?: string;
  PMR?: boolean;
  parking?: boolean;
  laboratoireTypes?: string[];
};
class BNLService extends BaseService {
  static readonly AVAILABLE_LANGUAGES = ['fr', 'en', 'pt', 'de'];

  async searchCenters({ lang, city, specialities, title, PMR, parking, laboratoireTypes }: SearchCenter): Promise<
    {
      center: Center;
      file: CenterIncludedFile;
      services: CenterIncludedService[];
      updates: CenterIncludedUpdate[];
    }[]
  > {
    const language = BNLService.AVAILABLE_LANGUAGES.includes(lang.toLowerCase()) ? lang.toLowerCase() : 'fr';

    this.log(`searchCenters()`);
    const laboratoireFilters = laboratoireTypes?.length && laboratoireTypes?.map((type, index) => `filter[field_type_de_laboratoire][value][${index + 1}]=${type}`);
    const laboratoireFiltersString = laboratoireFilters ? `&filter[field_type_de_laboratoire][operator]=IN&${laboratoireFilters ? laboratoireFilters.join('&') : ''}` : '';
    const titleSearchFilter = title ? `&filter[title][value]=${title}&filter[title][operator]=CONTAINS` : '';

    return axiosBION
      .get(
        `/${language}${this.apiConfig.BNL.GET_CENTERS}${titleSearchFilter}${PMR ? '&filter[field_prelevement_handicape]=true' : ''}${parking ? '&filter[field_prelevement_parking]=true' : ''}${
          city && city !== -1 ? `&filter[field_prelevement_ville]=${city}` : ''
        }${laboratoireFiltersString}`,
      )
      .then((res: AxiosResponse<CentersSearchResults, any>) =>
        res.data.data.map((center) => ({
          center,
          file: res.data.included.filter(
            (included) => included.type === DrupalTypes.file && center.relationships.field_prelevement_photo.data !== null && center.relationships.field_prelevement_photo.data.id === included.id,
          )[0] as CenterIncludedFile,
          services: res.data.included.filter(
            (included) => included.type === DrupalTypes.service && center.relationships.field_prelevement_services.data.find((service) => service.id === included.id),
          ) as CenterIncludedService[],
          updates: res.data.included.filter(
            (included: CenterIncludedService) => included.type === DrupalTypes.lab_update && !!center.relationships.field_updates.data.find((updateItem) => included.id === updateItem.id),
          ) as CenterIncludedUpdate[],
        })),
      );
  }

  async getCenters(): Promise<
    {
      center: Center;
      file: CenterIncludedFile;
      services: CenterIncludedService[];
      updates: CenterIncludedUpdate[];
    }[]
  > {
    return axiosBION.get(`/fr${this.apiConfig.BNL.GET_CENTERS}`).then((res: AxiosResponse<CentersSearchResults, any>) =>
      res.data.data.map((center) => ({
        center,
        file: res.data.included.filter(
          (included) => included.type === DrupalTypes.file && center.relationships.field_prelevement_photo.data !== null && center.relationships.field_prelevement_photo.data.id === included.id,
        )[0] as CenterIncludedFile,
        services: res.data.included.filter(
          (included) => included.type === DrupalTypes.service && center.relationships.field_prelevement_services.data.find((service) => service.id === included.id),
        ) as CenterIncludedService[],
        updates: res.data.included.filter(
          (included: CenterIncludedService) => included.type === DrupalTypes.lab_update && !!center.relationships.field_updates.data.find((updateItem) => included.id === updateItem.id),
        ) as CenterIncludedUpdate[],
      })),
    );
  }
  async getCenter(id: string): Promise<{
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }> {
    this.log(`getCenter()`);
    const res = await axiosBION.get(`/${this.getLang()}${this.apiConfig.BNL.GET_CENTER(id)}`);
    return res.data;
  }

  async getFAQ(lang: string, category: string): Promise<FAQ> {
    // Check language available
    const language = BNLService.AVAILABLE_LANGUAGES.includes(lang.toLowerCase()) ? lang.toLowerCase() : 'fr';

    const { data } = await axiosBION.get<any>(`/${language}${this.apiConfig.BNL.GET_FAQ}?filter[field_categorie_type]=${category}&include=field_type_de_sujet_filtres`);

    this.log(`getFAQ()`);

    return data.data.map((elem: any) => {
      return {
        id: elem.id,
        language: elem.attributes.langcode,
        category: elem.attributes.field_categorie_type,
        tags: elem.relationships.field_type_de_sujet_filtres.data.map((type: { id: string }) => data.included.find((include: { id: string }) => include.id === type.id).attributes.name),
        question: elem.attributes.title,
        answer: elem.attributes.body.value,
      };
    });
  }

  async getNews() {
    const locale = i18n.language.split('-').shift();
    const { data } = await axiosBION.get<GetNewsProps>(`/${locale}${this.apiConfig.BNL.GET_NEWS(4, '-created')}`);

    this.log(`getNews()`);

    return data;
  }
  async getFavoriteLabs() {
    const { data } = await axiosMYLAB.get(this.apiConfig.BNL.FAVORITES(this.getLang()));

    this.log(`getFavorites()`);

    return data;
  }

  async addFavoriteLab(favoriteLab: FavoriteLab) {
    const { data } = await axiosMYLAB.post(this.apiConfig.BNL.FAVORITES(this.getLang()), favoriteLab);

    this.log('addFavoriteLab()');

    return data;
  }
  async deleteFavoriteLab(favoriteLab: FavoriteLab) {
    const { data } = await axiosMYLAB.delete(this.apiConfig.BNL.FAVORITES(this.getLang()), { data: favoriteLab });

    this.log('deleteFavoriteLab()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new BNLService(apiConfig);
};
