import { Grid } from '@mui/material';
import { FC } from 'react';
import Summary from 'components/summary';
import { CenterInfo } from 'pages/patient/centers/types';
import formatCenterAddress from 'pages/patient/centers/utils/formarCenterAddress';
import { RdvPatient } from 'models/appointment.model';

const SummaryStep: FC<{
  handleNext: () => void;
  date: Date | null;
  centerInfo: CenterInfo | null;
  patientRdv: RdvPatient | null;
}> = ({ date, centerInfo, patientRdv }) => {
  if (!date || !centerInfo || !patientRdv) {
    return null;
  }
  return (
    <Grid xs={12} sm={8} md={6} item sx={{ margin: '0 auto' }}>
      <Summary
        summaryFields={{
          labTitle: centerInfo.center.attributes.title,
          labAddress: formatCenterAddress(centerInfo.center),
          procedure: 'Fertility',
          duration: '30 minutes',
          date,
          timeSlot: '10:30',
          prescriptions: [
            {
              pacient: `${patientRdv.firstname} ${patientRdv?.lastname || ''}`,
              doctor: 'Dr. Emily Smith',
              file: 'some.png',
              validUntilDate: new Date(),
            },
          ],
        }}
      />
    </Grid>
  );
};

export default SummaryStep;
