import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import api from 'api';
import { convertDate } from 'utils/func/Date.func';
import { useContextRedirection, useIsMobileView, useNotification } from 'hooks';
import RoutePaths from 'utils/RoutePaths';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  token: string;
  birth_date: Date | null;
}

const defaultValues: FormValues = { token: '', birth_date: null };

const AddRelativeWithTokenForm: FC = () => {
  const isMobileView = useIsMobileView();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const history = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<FormValues>({ defaultValues, mode: 'onChange' });

  const onSubmit: SubmitHandler<FormValues> = useCallback(
    (data) => {
      api.users
        .addRelationWithToken(data.token, convertDate(data.birth_date, false, 'yyyy-MM-dd'))
        .then(() => {
          navigate(RoutePaths['PROFILE_RELATIVE']);
          notification(t('landing_page.login.token.notification.validation.tokenRelative'), 'success');
        })
        .catch(() => {
          notification(t('landing_page.login.token.notification.error.tokenRelative'), 'success');
        });
    },
    [notification, t, navigate],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ width: '100%', borderRadius: '0 0 8px 8px' }}>
        <CardContent>
          <Box mb={1}>
            <Typography>{t('profile.relatives.add.desc_to_access_a_result')}</Typography>
          </Box>
          <Box py={2} sx={{ width: { xs: '100%', sm: '50%' } }}>
            <TextField variant='filled' label={t('profile.relatives.add.token_received_by')} {...register('token', { required: true })} fullWidth />
          </Box>
          <Box py={2} sx={{ width: { xs: '100%', sm: '50%' } }}>
            <Controller
              name='birth_date'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <DatePicker
                  label={t('profile.relatives.add.birth_date_relative')}
                  disableFuture
                  openTo='year'
                  format='yyyy-MM-dd'
                  views={['year', 'month', 'day']}
                  {...field}
                  slotProps={{ textField: { variant: 'filled', fullWidth: true } }}
                />
              )}
            />
          </Box>
        </CardContent>
      </Card>
      <Grid item xs={12} mt={3}>
        <Button sx={{ width: isMobileView ? '100%' : 160, mr: isMobileView ? 0 : 2, mb: isMobileView ? 2 : 0 }} variant='contained' type='submit'>
          {t('common.action.submit')}
        </Button>
        <Button sx={{ width: isMobileView ? '100%' : 160 }} variant='outlined' fullWidth={isMobileView} type='submit' onClick={() => history(-1)}>
          {t('common.action.cancel')}
        </Button>
      </Grid>
    </form>
  );
};

export default AddRelativeWithTokenForm;
