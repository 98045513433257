import { Button, Card, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface LoginBtnProps {
   setLoginDialogOpen: (state: boolean) => void;
}

const LoginBtn: FC<LoginBtnProps> = ({setLoginDialogOpen}) => {
   const { t } = useTranslation();

   return (
      <Stack direction="row" spacing={2} alignItems="center">
         <Typography>{t('new_appointment.stepper.steps.introduction.login_text')}</Typography>
         <Button onClick={() => setLoginDialogOpen(true)} variant="outlined">{t('new_appointment.stepper.steps.introduction.login_btn')}</Button>
      </Stack>
   );
};

export default LoginBtn;