import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Chip, Dialog, DialogContent, DialogTitle, SvgIcon, TextField, Typography } from '@mui/material';
import { useIsMobileView, useNotification } from 'hooks';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctor.svg';
import { ReactComponent as AnalysesIcon } from 'resources/icons/catalogue_analyse.svg';
import { ResultNew } from 'models/result.model';
import { FC, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { convertDate } from 'utils/func/Date.func';
import TagColorPicker from './TagColorPicker';
import { resultTagColors } from '../utils/colors';
import resultsService from 'api/results.service';

interface TagForm {
  tagName: string;
  selectedTagName: string;
  color: string;
}

type Props = {
  result: ResultNew;
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const AddTagDialog: FC<Props> = ({ open, setOpen, result }) => {
  const defaultValues = { tagName: '', color: resultTagColors[0], selectedTagName: '' };
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const { notification } = useNotification();
  const [selectedTagName, setSelectedTagName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, watch, reset, setValue } = useForm<TagForm>({
    mode: 'onChange',
    defaultValues,
  });

  const { tagName, color } = watch();

  const handleClose = useCallback(() => {
    setOpen(false);
    reset(defaultValues);
  }, [reset, setOpen]);

  const onSubmit: SubmitHandler<TagForm> = useCallback(
    (formData) => {
      console.log('close');
    },
    [handleClose, notification, t],
  );

  const autocompleteOptions = useMemo(
    () => [
      { id: '1', value: `${t('result_doctor.tag_dialog.title')} "${tagName}"`, category: 'none', disabled: !tagName },
      { id: '2', value: `value 1`, category: 'recent' },
      { id: '3', value: `value 2`, category: 'recent' },
    ],
    [tagName],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 'fit-content',
          borderRadius: '28px',
          p: 3,
        },
      }}
      PaperProps={{ sx: { width: { xs: '300px', md: '440px' }, maxWidth: '600px !important' } }}
    >
      <DialogTitle sx={{ position: 'relative', p: 0, fontSize: '24px', mb: 2 }}>{t('result_doctor.tag_dialog.title')}</DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mb: 1.5 }}>
          <Typography variant='body1' sx={{ pb: 2, fontWeight: '600' }}>
            {result.patient.first_name} {result.patient.last_name}
          </Typography>
          {result.issuer &&<Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
            <SvgIcon fontSize='small' component={DoctorIcon} sx={{ mr: 1, color: redesignColors.blue.main }} />
            {result.issuer.first_name} {result.issuer.last_name}
          </Typography>}
          <Typography variant='body1' sx={{ display: 'flex', alignItems: 'center', pb: 1, fontSize: '14px' }}>
            <SvgIcon fontSize='small' component={AnalysesIcon} sx={{ mr: 1, color: redesignColors.blue.main }} />
            {convertDate(result.sampling_time, true, 'yyyy-MM-dd', 'HH:mm:ss')} | {result.reference}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Autocomplete
              inputValue={tagName}
              onChange={(e, value) => value && setSelectedTagName(value?.id === '1' ? tagName : value?.value)}
              getOptionLabel={(option) => option.value}
              filterOptions={(options) => options}
              groupBy={(option) => option.category}
              getOptionDisabled={(option) => !!option?.disabled}
              renderGroup={({ group, children, key }) =>
                group === 'none' ? (
                  children
                ) : (
                  <div key={key}>
                    <Typography variant='subtitle2' sx={{ padding: '12px 16px', color: redesignColors.grey.grey2, textTransform: 'uppercase' }}>
                      {group === 'recent' ? t('result_doctor.tag_dialog.recent_tags') : group}
                    </Typography>
                    {children}
                  </div>
                )
              }
              options={autocompleteOptions}
              renderInput={(params) => (
                <TextField {...params} {...register('tagName')} sx={{ borderRadius: '8px' }} variant='outlined' placeholder={t('result_doctor.tag_dialog.tag_name_placeholder')} multiline={false} inputProps={{
                  ...params.inputProps,
                  maxLength: 15,
                }} />
              )}
              fullWidth
              
            />

            <Box display='flex' p={1}>
              {selectedTagName && <TagColorPicker color={color} setColor={(color) => setValue('color', color)} />}
              {selectedTagName && <Chip variant='outlined' color='secondary' label={selectedTagName} onDelete={() => setSelectedTagName('')} sx={{ ml: 2 }} />}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton variant='text' fullWidth={isMobileView} loading={submitting} sx={{ mr: 2 }} onClick={handleClose}>
                {t('common.action.cancel')}
              </LoadingButton>
              <LoadingButton type='submit' variant='text' fullWidth={isMobileView} loading={submitting} disabled={selectedTagName ? false : true}>
                {t('common.action.save')}
              </LoadingButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default AddTagDialog;
