import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          '& .MuiAvatar-root': {
            borderColor: theme_color.palette.background.paper,
            boxSizing: 'border-box',
            fontSize: 12,
          },
          '& .MuiAvatar-root:last-child': {
            borderColor: theme_color.palette.primary.main,
            boxSizing: 'border-box',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: redesignColors.grey.grey3,
          color: redesignColors.dark,
          fontWeight: 600,
        },
      },
    },
  },
});

export default theme;
