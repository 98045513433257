import { Button, Divider, InputAdornment, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useContextRedirection, useNotification } from 'hooks';
import { ForgotPasswordRequest } from 'models/user.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconEmail } from 'resources/icons/messagerie.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import RoutePaths from 'utils/RoutePaths';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { register, handleSubmit } = useForm<any>();
  const { notification } = useNotification();
  const navigate = useContextRedirection();

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = useCallback(
    (data) => {
      setSubmitting(true);

      api.authentication
        .forgotPassword(data)
        .then(() => {
          notification(t('landing_page.forgotPwd.notification.success'), 'success');
          navigate(RoutePaths['HOME']);
        })
        .catch(() => {
          notification(t('landing_page.forgotPwd.notification.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [navigate, notification, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack
        direction='column'
        spacing={3}
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          justifyContent: 'center',
          marginTop: '5px',
          paddingTop: '8%',
        }}
      >
        <Typography fontWeight='700'>{t('landing_page.forgotPwd.title')}</Typography>

        <Typography textAlign='left' color={redesignColors.grey.grey1}>
          {t('landing_page.forgotPwd.header')}
        </Typography>

        <Stack direction='column' spacing={2}>
          <TextField
            variant='filled'
            sx={{
              backgroundColor: '#FFFFFF',
              '& .MuiInputAdornment-root': {
                marginTop: '4px !important',
              },
            }}
            {...register('email')}
            name={'email'}
            type={'email'}
            label={t('landing_page.login.email')}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={IconEmail} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
              autoComplete: 'email',
            }}
            required
          />
          <Stack direction='column' spacing={1} alignItems='center'>
            <Button type='submit' variant='contained' disabled={isSubmitting} fullWidth>
              {t('landing_page.login.reset_password')}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default ForgotPassword;
