import { Modal, RadioGroup, Stack, Typography, FormControl, FormControlLabel, Radio, FormGroup, Button } from '@mui/material';
import { FC, FormEvent, useState } from 'react';
import { PatientType } from './types/Patient';
import RadioLabel from './RadioLabel';
import { useTranslation } from 'react-i18next';

interface AddPatientModalProps {
  open: boolean;
  onClose: () => void;
  onAddPatient: (selected: PatientType) => void;
}

const modalButtonsStyle = { color: '#0F6F99', border: 'none' };

const AddPatientModal: FC<AddPatientModalProps> = ({ open, onClose, onAddPatient }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(PatientType.Adult);

  return (
    <Modal
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
      open={open}
      onClose={onClose}
      aria-labelledby='add-patient-modal'
      aria-describedby='add-patient-modal'
    >
      <Stack
        spacing={3}
        sx={{
          minWidth: {
            xs: '80%',
            sm: '50%',
            md: '40%',
            lg: '30%',
            xl: '30%',
          },
          borderRadius: '38px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '24px',
        }}
      >
        <Typography variant='h2'> {t('checkin.add_patient_modal.title')}</Typography>

        <form
          style={{ width: '100%' }}
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            onAddPatient(selected);
          }}
        >
          <Stack spacing={3}>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup aria-labelledby='add-patient-radio-group' defaultValue={selected} name='add-patient-radio-group' onChange={(e, v) => setSelected(e.target.value as PatientType)}>
                <FormControlLabel
                  value={PatientType.Adult}
                  control={<Radio color='default' />}
                  label={<RadioLabel label={t('checkin.add_patient_modal.adult')} description={t('checkin.add_patient_modal.adult_description')} />}
                />
                <FormControlLabel
                  value={PatientType.Child}
                  control={<Radio color='default' />}
                  label={<RadioLabel label={t('checkin.add_patient_modal.child')} description={t('checkin.add_patient_modal.child_description')} />}
                />
              </RadioGroup>
            </FormControl>

            <FormGroup sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', gap: '36px' }}>
              <Button sx={modalButtonsStyle} onClick={onClose}>
                {t('checkin.add_patient_modal.button.cancel')}
              </Button>
              <Button sx={modalButtonsStyle} type='submit'>
                {t('checkin.add_patient_modal.button.add_patient')}
              </Button>
            </FormGroup>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddPatientModal;
