import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          height: 56,
          backgroundColor: redesignColors.blue.blue2,
          borderRadius: 8,
          border: '1px solid',
          borderColor: redesignColors.grey.grey3,
          padding: '0px 16px',
          '& .MuiSvgIcon-root': {
            color: redesignColors.grey.grey2,
          },
          '&:hover': {
            borderColor: theme_color.palette.primary.main,
            backgroundColor: redesignColors.blue.blue2,
          },
          '&.Mui-focused': {
            borderColor: theme_color.palette.primary.main,
            backgroundColor: redesignColors.inputBackground,
          },
          '& .MuiInputAdornment-root': {
            marginTop: '0 !important',
          },
          '&.Mui-disabled': {
            borderColor: theme_color.palette.text.disabled,
            backgroundColor: alpha(theme_color.palette.text.disabled, 0.1),
          },
          '&.Mui-error': {
            borderColor: theme_color.palette.error.main,
            backgroundColor: redesignColors.blue.blue2,
          },
        },
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
        },
        input: {
          color: redesignColors.dark,
          paddingLeft: '5px',
          '&:-webkit-autofill': {
            borderRadius: 0,
            backgroundColor: 'transparent',
          },
          '&:focus': { backgroundColor: 'transparent' },
        },
        multiline: {
          padding: '16px 8px',
          borderRadius: 8,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingLeft: '12px',
          paddingRight: '24px',
          boxSizing: 'border-box',
          color: redesignColors.grey.grey2,
          '&:has(+ .MuiInputBase-adornedStart)': {
            paddingLeft: '54px',
          },
          '&.Mui-focused': {
            color: theme_color.palette.text.secondary,
          },
        },
      },
    },
  },
});

export default theme;
