import { Address, Gender } from './profile.model';
import { Record } from './record.model';
import { Nullable, Paginate } from './utils.model';

export interface CreatePatientRequest {
  gender: Gender;
  first_name: string;
  last_name: string;
  birth_date: Date | string;
  cns: string;
  health_fund_id: string;
  marital_name?: string;
  home?: Address;
}

export type CreatePatientRequestForm = Omit<CreatePatientRequest, 'home' | 'health_fund_id'>;

export interface PatientsListRequestForm {
  first_name?: string;
  last_name?: string;
  gender?: Gender;
  cns?: string;
  birth_name?: string;
  birth_date?: string | Date | null;
}

export interface MPIPatientRequest {
  first_name: string;
  last_name: string;
  gender: Gender;
  matricule: string;
  birth_name: string;
  birth_date: string | Date;
}

export interface PatientsListRequest {
  first_name?: string;
  last_name?: string;
  gender?: Gender;
  cns?: string;
  birth_name?: string;
  birth_date?: string;
}
export type PatientsListRequestMPI = Omit<PatientsListRequest, 'cns'> & {
  matricule: string;
};

export interface PatientsListResponse extends Paginate<Patient> {}

export interface Patient {
  id: number;
  cns: string;
  first_name: string;
  last_name: string;
  marital_name?: string;
  birth_name?: string;
  title?: string;
  birth_date: string;
  gender: Gender;
  mobile_phone?: string;
  mobile_phone_info?: string;
  phone?: string;
  phone_info?: string;
  fax?: string;
  fax_info?: string;
  email?: string;
  email_info?: string;
  addresses: {
    home?: Address;
    work?: Address;
  };
  last_dossier?: Record;
  is_active: boolean;
  lis_id?: string;
  dossiers?: Record[];
  nationality?: string;
  observation?: string;
  communication_language?: string;
  health_fund_id?: string;
  insurance1_id?: string;
  insurance1_number?: string;
  insurance2_id?: string;
  insurance2_number?: string;
  mobile_phone_is_relative: boolean;
  family_doctor?: string;
  mylab_refused: boolean;
  last_update: string;
}

export type FilterProps = {
  size: number | 0;
  page: number | 0;
  patientName?: string | null;
  from?: string | null;
  to?: string | null;
  recordReference?: string | null;
  cnsNumber?: string | null;
  status?: string | null;
  consulted?: boolean;
  tagged?: boolean;
  abnormal?: boolean;
  urgent?: boolean;
};

export type PatientForm = {
  patients: {
    gender: string;
    first_name: string;
    birth_name: string;
    last_name: string;
    birth_date: Date | null;
    cns?: string;
    email: string;
    phone?: string;
    mobile_phone?: string;
  }[];
};

export const sexes = [
  {
    label: 'common.sex.woman',
    value: Gender.FEMALE_FULL,
  },
  {
    label: 'common.sex.man',
    value: Gender.MALE_FULL,
  },
];

export interface PatientResponseMPI {
  first_name: Nullable<string>;
  marital_name: Nullable<string>;
  birth_name: Nullable<string>;
  birth_date: Nullable<string>;
  gender: Nullable<string>;
  phone: Nullable<string>;
  mobile_phone: Nullable<string>;
  fax: Nullable<string>;
  marital_status: Nullable<string>;
  birth_place: Nullable<string>;
  matricule: Nullable<string>;
  dsp_id: Nullable<string>;
  source_id: Nullable<string>;
  source_domain: Nullable<string>;
  external_ids: Nullable<ExternalIdMPI[]>;
  address: Nullable<AddressMPI>;
  nationality: Nullable<string>;
  death_date: Nullable<string>;
  is_dead: Nullable<string>;
  is_valid: Nullable<boolean>;
}

export interface ExternalIdMPI {
  id: Nullable<string>;
  domain: Nullable<string>;
  type: Nullable<string>;
}

export interface AddressMPI {
  street: Nullable<string>;
  complement: Nullable<string>;
  city: Nullable<string>;
  postal_code: Nullable<string>;
  country: Nullable<string>;
}
