import { APIConfiguration, axiosMYLAB } from 'api';
import { PrescriptionProfile } from 'models/prescription-profile.model';
import { PrescriptionProfileListResponse } from 'models/prescription.model';
import { BaseService } from './base.service';

class ePrescriptionsService extends BaseService {
  async getEprescription(id: number) {
    const { data } = await axiosMYLAB.get<PrescriptionProfileListResponse>(this.apiConfig.E_PRESCRIPTIONS.GET(this.getLang(), id));

    this.log(`getEprescription()`);

    return data;
  }

  async dispenseEprescription(id: number, params: any) {
    const { data } = await axiosMYLAB.post<PrescriptionProfile>(this.apiConfig.E_PRESCRIPTIONS.DISPENSE(this.getLang(), id), params);

    this.log(`dispenseEprescription()`);

    return data;
  }

  async createEprescription(params: any) {
    const { data } = await axiosMYLAB.post<PrescriptionProfile>(this.apiConfig.E_PRESCRIPTIONS.CREATE(this.getLang()), params);

    this.log(`createEprescription()`);

    return data;
  }

  async cancelEprescription(id: number) {
    const { data } = await axiosMYLAB.patch<PrescriptionProfile>(this.apiConfig.E_PRESCRIPTIONS.CANCEL(this.getLang(), id));

    this.log(`cancelEprescription()`);

    return data;
  }
}
export default (apiConfig: APIConfiguration) => {
  return new ePrescriptionsService(apiConfig);
};
