import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { alpha, Box, Card, CardActionArea, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { convertSecToMin } from 'utils/func/Date.func';
import VideoDialog from './VideoDialog';

const VideoCard: FC<any> = ({ video }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Card
        sx={{
          padding: { xs: 0, sm: 0 },
          borderRadius: '0px',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        <CardContent>
          <CardActionArea
            onClick={() => setOpen(true)}
            sx={{
              '&:hover, &:focus': {
                opacity: 0.8,
              },
            }}
          >
            <Box position='relative' display='flex' justifyContent='center' alignItems='center'>
              <PlayCircleOutlineIcon
                sx={{
                  position: 'absolute',
                  width: '50%',
                  height: '50%',
                  color: alpha(theme.palette.common.white, 0.8),
                }}
              />
              <Box
                display='grid'
                position='absolute'
                bottom={4}
                right={4}
                sx={{
                  px: 0.5,
                  backgroundColor: 'black',
                }}
              >
                <Typography variant='caption' justifySelf='center' color='white'>
                  {convertSecToMin(video.duration)}
                </Typography>
              </Box>
              <CardMedia component='video' image={video.video} poster={video.thumbnail} />
            </Box>
          </CardActionArea>
          <Box py={1}>
            <Typography variant='subtitle1' onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
              {video.title}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <VideoDialog video={video} open={open} setOpen={setOpen} />
    </>
  );
};

export default VideoCard;
