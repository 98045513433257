import { Autocomplete, IconButton, InputAdornment, Stack, styled, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as ZoomIcon } from 'resources/icons/zoom.svg';
import { ReactComponent as LocationIcon } from 'resources/icons/location.svg';
import { useTranslation } from 'react-i18next';
import SavedAddresses from './SavedAddresses';
import { useAuthenticatedUser } from 'hooks';
import { APPOINTMENT_STORAGE, AppointmentStorage, CHECKIN_STORAGE } from './types/Storage';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
interface AddressProps {
  setNextButtonActive: (state: boolean) => void;
  isCheckin?: boolean;
}

const stackStyles = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const StyledAddressInput = styled(TextField)(({ theme }) => ({
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '8px',
  width: '100%',
}));

const Address: FC<AddressProps> = ({ setNextButtonActive, isCheckin }) => {
  const { t } = useTranslation();
  const { getState, dispatch } = useStore();
  const authenticatedUser = useAuthenticatedUser();

  const [options, setOptions] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [longLat, setLongLat] = useState({ long: 0, lat: 0 });

  //TODO add api call and get long lat

  useEffect(() => {
    if (!selectedAddress) {
      setNextButtonActive(false);
    }
  }, [setNextButtonActive, selectedAddress]);

  useEffect(() => {
    const storage = !isCheckin ? getState().newAppointmentFlowReducer : null;

    if (!storage) return;
    setSelectedAddress(storage.address.address);
    setNextButtonActive(true);
    setAdditionalInfo(storage.address.additionalInfo);
    setLongLat(storage.address.longLat);
  }, [setNextButtonActive, setSelectedAddress, isCheckin, getState]);

  useEffect(() => {
    if (!selectedAddress) return;

    dispatch({
      type: NewAppointmentFlowActionType.SET_ADDRESS,
      address: {
        address: selectedAddress,
        additionalInfo,
        longLat,
      },
    });
  }, [selectedAddress, isCheckin, dispatch, additionalInfo, longLat]);

  const handleSelectAddress = (e: any, val: string | null) => {
    if (val) {
      setSelectedAddress(val);
      setNextButtonActive(true);
    } else {
      setSelectedAddress('');
      setNextButtonActive(false);
    }
  };

  const handleSelectSavedAddress = (address: string) => {
    if (address) {
      setSelectedAddress(address);
      setNextButtonActive(true);
    }
  };

  const handleSearch = async (val: string | null) => {
    if (val) {
      setOptions([val]);
    }
  };

  const handleLocation = async () => {
    let longitude = 0;
    let latitude = 0;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        longitude = position.coords.longitude;
        latitude = position.coords.latitude;

        //API Call to backend

        setLongLat({ long: longitude, lat: latitude });
      },
      () => {
        alert('Unexpected error while determining your location. Please try again.');
      },
    );
  };

  return (
    <>
      <Stack sx={stackStyles} spacing={2}>
        <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
          {t('checkin.stepper.steps.address.title')}
        </Typography>

        <Stack
          sx={{
            ...stackStyles,
            alignItems: 'flex-end',
            width: {
              xs: '100%',
              sm: '70%',
              md: '50%',
              lg: '45%',
            },
          }}
          spacing={0.5}
        >
          <Autocomplete
            id='search-address'
            sx={{
              width: '100%',
              height: '56px',
              borderRadius: '8px',
            }}
            freeSolo
            options={options}
            onChange={(e, v) => handleSelectAddress(e, v)}
            renderInput={(params) => (
              <StyledAddressInput
                {...params}
                placeholder={t('checkin.stepper.steps.address.search_placeholder')}
                variant='outlined'
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    borderRadius: '8px',
                  },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ZoomIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <IconButton
            sx={{
              display: 'flex',
              gap: '4px',
            }}
            onClick={handleLocation}
          >
            <LocationIcon />
            <Typography
              component='span'
              variant='button'
              sx={{
                color: '#0F6F99',
              }}
            >
              {t('checkin.stepper.steps.address.determine_location')}
            </Typography>
          </IconButton>

          {!selectedAddress && authenticatedUser && <SavedAddresses setSelectedAddress={handleSelectSavedAddress} />}

          {selectedAddress && (
            <Stack sx={{ ...stackStyles, alignItems: 'flex-start' }} spacing={2}>
              <Typography
                component='span'
                variant='button'
                sx={{
                  fontWeight: '600',
                }}
              >
                {selectedAddress}
              </Typography>

              <TextField
                multiline
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                sx={{ width: '100%', backgroundColor: 'white', borderRadius: '8px' }}
                id='address-additional-info'
                placeholder={t('checkin.stepper.steps.address.additional_info_placeholder')}
                rows={5}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default Address;
