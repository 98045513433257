import { IconButton, SxProps, TableCell, TableRow, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ReactComponent as SortIcon } from 'resources/icons/sort_table.svg';
import { redesignColors } from 'resources/theme/theme.colors';

export interface GenericHeader {
  id: string;
  label?: string;
  alignRight?: boolean;
  sx?: SxProps<Theme>;
  sort?: ((...args: any[]) => void) | undefined;
}

export interface GenericTableHeadProps {
  headers: GenericHeader[];
}

const GenericTableHead: FC<GenericTableHeadProps> = ({ headers }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      {headers.map((header) => {
        return (
          <TableCell {...(header.alignRight && { align: 'right' })} sx={{ background: redesignColors.blue.blue1, ...(header?.sx || {}) }} key={header.id}>
            <Typography variant='subtitle1' sx={{ fontWeight: '400', display: 'flex', alignItems: 'center', justifyContent: header?.alignRight ? 'flex-end' : 'none' }}>
              {header.label && t(header.label)}
              {header?.sort && (
                <IconButton onClick={header.sort} sx={{ ml: '2px' }}>
                  <SortIcon />
                </IconButton>
              )}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GenericTableHead;
