import { ArrowBack, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, Stack, ToggleButton, Typography } from '@mui/material';
import { DateCalendar, PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import TimeSlots from 'components/slot-picker';
import dayjs from 'dayjs';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';

interface AvailableSlotStepProps {
  setNextButtonActive: (state: boolean) => void;
}

const CalendarHeader = (date: Date, setDate: (date: Date) => void): FC<PickersCalendarHeaderProps<unknown>> => {
  const Header: FC<PickersCalendarHeaderProps<unknown>> = ({ currentMonth }) => (
    <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
      <IconButton sx={{ width: '24px', height: '24px' }} onClick={() => (dayjs(date).subtract(1, 'month').year() >= dayjs().year() ? setDate(dayjs(date).subtract(1, 'month').toDate()) : null)}>
        <ArrowBackIosNew />
      </IconButton>
      <Typography sx={{ mt: 0, mb: 0 }} variant='h6'>
        {dayjs(currentMonth as Date).format('MMMM YYYY')}
      </Typography>
      <IconButton sx={{ width: '24px', height: '24px' }} onClick={() => setDate(dayjs(date).add(1, 'month').toDate())}>
        <ArrowForwardIos />
      </IconButton>
    </Stack>
  );
  return Header;
};

const AvailableSlotStep: FC<AvailableSlotStepProps> = ({ setNextButtonActive }) => {
  const { t } = useTranslation();
  const { getState, dispatch } = useStore();

  const { notification } = useNotification();
  const [date, setDate] = useState<Date>(new Date());
  const [slot, setSelectedSlot] = useState<string>('');
  const long = 1; //TODO get from geolocation or previous step for API call
  const lat = 1;

  const [isSlotsView, setIsSlotsView] = useState(false);

  useEffect(() => {
    setSelectedSlot('');
    setNextButtonActive(false);
  }, [date, setSelectedSlot, setNextButtonActive]);

  useEffect(() => {
    setNextButtonActive(false);

    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;
    setSelectedSlot(dayjs(storage.slot).format('HH:mm'));
  }, [setNextButtonActive, getState]);

  useEffect(() => {
    if (!long || !lat) {
      notification(t('new_appointment.stepper.steps.available_slots.error'), 'error');
      return;
    }

    // api.appointments.getSlots()
  }, [long, lat, notification, t]);

  const handleSelectSlot = (slot: string) => {
    setNextButtonActive(true);
    setSelectedSlot(slot);

    dispatch({
      type: NewAppointmentFlowActionType.SET_SLOT,
      slot: dayjs(date).hour(parseInt(slot)).toDate(),
    });
  };

  return (
    <Stack spacing={2} justifyContent='center' width='100%'>
      {!isSlotsView ? (
        <>
          <Typography alignSelf='center' variant='h3' sx={{ fontWeight: 700 }}>
            {t('new_appointment.stepper.steps.available_slots.title')}
          </Typography>
          <DateCalendar
            value={date}
            onChange={(d) => {
              setDate(d as Date);
              setIsSlotsView(true);
            }}
            disablePast
            views={['day']}
            showDaysOutsideCurrentMonth
            shouldDisableDate={(d) => dayjs(d as Date).day() === 0}
            sx={(theme) => ({
              width: {
                xs: '100%',
                sm: '70%',
                md: '40%',
              },
              alignSelf: 'center',
              '.MuiDayCalendar-header': {
                justifyContent: 'space-between',
                borderBottom: '0.5px solid black',
              },
              '.MuiDayCalendar-weekContainer': {
                justifyContent: 'space-between',
              },
              '.MuiPickersDay-today': {
                border: '1px solid ' + theme.palette.primary.main,
              },
              '.MuiButtonBase-root': {
                '&.Mui-disabled': {
                  '&.MuiPickersDay-root': {
                    '&.MuiSelected': {
                      backgroundColor: theme.palette.primary.main,
                      color: 'black',
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light,
                    },
                    '&.Mui-disabled': {
                      color: 'grey',
                    },
                  },
                },
              },
              '.MuiPickersDay-dayOutsideMonth': {
                color: 'grey',
              },
              '.MuiPickersDay-root': {
                borderRadius: '8px',
                '&.MuiSelected': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: theme.palette.primary.light,
                },
                '&.Mui-disabled': {
                  color: 'grey',
                },
              },
            })}
            slots={{
              calendarHeader: CalendarHeader(date, setDate),
            }}
          />
        </>
      ) : (
        <TimeSlots
          title='Available time slots'
          selectedSlot={slot}
          setSelectedSlot={handleSelectSlot}
          slots={['8:00', '9:00', '10:00', '11:00', '12:00', '17:00', '18:00']}
          date={date}
          handleBack={() => setIsSlotsView(false)}
        />
      )}
    </Stack>
  );
};

export default AvailableSlotStep;
