import { APIConfiguration, axiosMYLAB } from 'api';
import { Analysis } from 'models/analysis.model';
import { Paginate } from 'models/utils.model';
import { BaseService } from './base.service';
import { CreateFeedbackRequest } from 'models/feedback.model';

class FeedbacksService extends BaseService {
  async createFeedback(params: CreateFeedbackRequest) {
    const { data } = await axiosMYLAB.post<Paginate<Analysis>>(`${this.apiConfig.FEEDBACKS.CREATE(this.getLang())}`, params);

    this.log('createFeedback()');

    return data;
  }
}

export default (apiConfig: APIConfiguration) => {
  return new FeedbacksService(apiConfig);
};
