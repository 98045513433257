import { Grid, Typography, useTheme } from '@mui/material';
import { useIsMobileView, useNotification } from 'hooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDateCalendar from 'components/custom-date-calendar';
import TimeSlots from 'components/slot-picker';
import dayjs from 'dayjs';
import { CenterInfo } from 'pages/patient/centers/types';

const defaultSlots = [
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
];

const getDisabledSlots = (date: Date | null, centerInfo?: CenterInfo | null) => {
  const dayOfWeek = dayjs(date).day();
  const currentDayOfWeek = centerInfo?.center?.attributes?.field_prelevement_horaires?.find((horaries) => horaries.day === dayOfWeek);

  if (!currentDayOfWeek) {
    return [];
  }
  const { endhours, starthours } = currentDayOfWeek;

  const timeStringToNumber = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 100 + minutes;
  };

  return defaultSlots.filter((slot) => {
    const timeInt = timeStringToNumber(slot);
    return timeInt < starthours || timeInt > endhours;
  });
};
const AvailaleSlot: FC<{
  handleIsActiveNextButton: () => void;
  setDate: (date: Date | null) => void;
  centerInfo?: CenterInfo | null;
  date: Date | null;
}> = ({ handleIsActiveNextButton, date, setDate, centerInfo }) => {
  const { t } = useTranslation();
  const [slot, setSelectedSlot] = useState<string>('');

  const { notification } = useNotification();
  const isMobileView = useIsMobileView();
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      {date ? (
        <TimeSlots
          sx={{ maxWidth: 'auto', width: isMobileView ? '220px' : '470px', margin: '0 auto' }}
          title={t('fertility.available_slot.title')}
          selectedSlot={slot}
          disabledSlots={getDisabledSlots(date, centerInfo)}
          setSelectedSlot={(slot) => {
            setSelectedSlot(slot);
            handleIsActiveNextButton();
          }}
          slots={defaultSlots}
          date={date}
          handleBack={() => setDate(null)}
        />
      ) : (
        <>
          <Typography sx={{}} fontSize='20px' fontWeight='600' textAlign='center'>
            {t('fertility.available_slot.select_date')}
          </Typography>
          <CustomDateCalendar
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
            }}
            minDate={new Date()}
            shouldDisableDate={(date) => {
              const dayOfWeek = dayjs(date).day();
              const currentDayOfWeek = centerInfo?.center?.attributes?.field_prelevement_horaires?.find((horaries) => horaries.day === dayOfWeek);
              return !currentDayOfWeek;
            }}
            loading={false}
          />
        </>
      )}
    </Grid>
  );
};

export default AvailaleSlot;
