import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const SelectFlowStep: FC<{
  handleNext: () => void;
}> = ({ handleNext }) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={6} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button variant='outlined' sx={{ width: '328px' }} onClick={handleNext}>
        {t('fertility.introduction.diagnostics_button')}
      </Button>
      <Button variant='outlined' sx={{ width: '328px', marginTop: '16px' }} onClick={handleNext}>
        {t('fertility.introduction.preparation_button')}
      </Button>
    </Grid>
  );
};

export default SelectFlowStep;
