import { Button, InputAdornment, Stack, SvgIcon, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VerifyTokenRequest } from 'models/user.model';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as TokenIcon } from 'resources/icons/token.svg';

interface VerifyTokenFormProps {
  onSubmit: (request: VerifyTokenRequest) => void;
  isSubmitting: boolean;
}

const VerifyTokenForm: FC<VerifyTokenFormProps> = ({ onSubmit, isSubmitting }) => {
  const { t } = useTranslation();

  const { register, handleSubmit, formState } = useForm<VerifyTokenRequest>({
    mode: 'onChange',
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack
        spacing={3}
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          justifyContent: 'center',
          marginTop: '0px',
        }}
      >
        <Stack direction='column' spacing={2}>
          <TextField
            fullWidth
            variant='filled'
            {...register('token', {
              required: true,
            })}
            autoFocus
            autoComplete='off'
            name={'token'}
            label={t('landing_page.login.token.fields.token')}
            error={formState.errors?.token !== undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={TokenIcon} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Stack direction='column' spacing={1} alignItems='center'>
          <Button type='submit' variant='contained' disabled={!formState.isValid || isSubmitting} fullWidth>
            {t('landing_page.login.token.fields.connect')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default VerifyTokenForm;
