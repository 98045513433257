import { LeftDrawerItem } from 'models/utils.model';
import { ReactComponent as DashboardIcon } from 'resources/icons/sidebar/home.svg';
import { ReactComponent as PatientsIcon } from 'resources/icons/patients.svg';
import { ReactComponent as ResultIcon } from 'resources/icons/sidebar/results.svg';

export const leftDrawerMenuLaboratory = (): LeftDrawerItem[] => [
  {
    title: 'bodyTemplate.dashboard',
    link: 'HOME',
    icon: DashboardIcon,
  },
  {
    title: 'bodyTemplate.doctor.patients',
    link: 'DOCTOR_PATIENTS',
    icon: PatientsIcon,
  },
  {
    title: 'common.results',
    link: 'DOCTOR_RESULTS',
    icon: ResultIcon,
  },
];
