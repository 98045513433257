import { UseFormRegister, UseFormSetValue, useFieldArray, useWatch } from 'react-hook-form';
import { FC, useRef, useState } from 'react';
import { Button, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import { useNotification } from 'hooks';
import { ReactComponent as PlusSquareIcon } from 'resources/icons/plus_square.svg';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';
import { MAX_SIZE_FILE } from 'utils/Constants';
import { arrayEquals } from 'utils';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

const Files: FC<{
  index: number;
  control: any;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  formState: any;
}> = ({ index, control, setValue, formState, register }) => {
  const { notification } = useNotification();
  const [fileExist, setFileExist] = useState(false);
  const { t } = useTranslation();
  const files = useWatch({ control, name: `addRelative.${index}.files` });
  const inputFile = useRef<HTMLInputElement>(null);

  const { fields, remove } = useFieldArray({
    control,
    name: `addRelative.${index}.files`,
  });

  const addRelativeOnClick = () => {
    inputFile.current?.click();
  };

  const onChangeFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const newFile = event.target.files[0];
    if (newFile && (!(newFile.type === 'application/pdf' || newFile.type.startsWith('image/')) || newFile.size > MAX_SIZE_FILE)) {
      notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
      setFileExist(false);
    } else {
      setValue(`addRelative.${index}.files`, [...files, newFile]);
      setFileExist(true);
    }
  };

  return (
    <>
      {fileExist && files?.length ? (
        <Grid item xs={12}>
          {files?.map((file: any, key: any) => (
            <div key={key}>
              {file.name && (
                <Grid sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}`, p: 2 }} item key={key} xs={12} display='flex' justifyContent='space-between' alignItems='center'>
                  <>
                    <Typography variant='body1'>{file?.name}</Typography>
                    <IconButton
                      onClick={() => {
                        if (fields.length === 0 && !arrayEquals(files[key], Array(undefined))) {
                          setValue(`addRelative.${index}.files.${key}`, [undefined]);
                        } else {
                          fields.length > 0 && remove(key);
                        }
                      }}
                    >
                      <SvgIcon component={DeleteIcon} inheritViewBox fontSize='small' sx={{ fill: redesignColors.red }}></SvgIcon>
                    </IconButton>
                  </>
                </Grid>
              )}
            </div>
          ))}
        </Grid>
      ) : null}
      <Grid item xs={12} sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}`, padding: '16px 0 16px !important', mb: 2 }}>
        <Button startIcon={<SvgIcon component={PlusSquareIcon} inheritViewBox></SvgIcon>} onClick={addRelativeOnClick}>{`${t('profile.relatives.add.form.upload_identity_certificate')} *`}</Button>
        <input type='file' {...register(`addRelative.${index}.files`, { required: true, onChange: onChangeFile })} ref={inputFile} style={{ display: 'none' }} />
        {formState.errors?.addRelative?.[index]?.files && files?.length === 1 && (
          <Typography variant='body2' color='error'>
            {t('preregistration.myData.form.error.invalidFile')}
          </Typography>
        )}
      </Grid>
    </>
  );
};
export default Files;
