import { Card, CardContent, Grid } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { GenericPaginator } from 'components/generic-table';
import ContextLink from 'components/_base/ContextLink';
import { useNotification } from 'hooks';
import { InvoicesResult, InvoiceStatus } from 'models/invoices.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import InvoicesTable from './components/InvoicesTable';
import Tabs from 'components/tabs';
import { matchPath, useLocation } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';

const Invoices: FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isOpenPath = matchPath(`${i18n.language}${RoutePaths.INVOICES_OPEN}`, location.pathname);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const [invoices, setInvoices] = useState<InvoicesResult[]>();
  const paginatorData: GenericPaginator = {
    total: 0,
    size: 5,
    page: 1,
  };
  const [paginator, setPaginator] = useState(paginatorData);
  const [isLoading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState<InvoiceStatus>(isOpenPath ? InvoiceStatus.OPEN : InvoiceStatus.PAID);
  const { notification } = useNotification();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    currentUser &&
      api.invoices
        .getInvoices(paginator.size, paginator.page, currentUser.id, selectedTab)
        .then((res) => {
          setInvoices(res.results);
          setPaginator({
            total: res.total,
            size: res.size,
            page: res.page,
          });
          setLoading(false);
        })
        .catch(() => notification(t('invoices.notification.error'), 'error'));
  }, [currentUser, dispatch, selectedTab, notification, paginator.size, paginator.page, t]);

  const handlePaginationChange = (pagination: GenericPaginator) => {
    setPaginator(pagination);
  };

  const handleChange = (selectedTab: InvoiceStatus) => {
    setSelectedTab(selectedTab);
  };

  const tabs = [
    {
      label: <ContextLink to={'INVOICES_OPEN'}>{t('invoices.to_pay')}</ContextLink>,
      value: InvoiceStatus.OPEN,
    },
    {
      label: <ContextLink to={'INVOICES_PAID'}>{t('invoices.paid_plural')}</ContextLink>,
      value: InvoiceStatus.PAID,
    },
  ];
  return (
    <BodyTemplate title={t('bodyTemplate.invoices')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Tabs value={selectedTab} onChange={handleChange} tabs={tabs} />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 0 }}>
            <CardContent>
              <InvoicesTable toPayTab={selectedTab === InvoiceStatus.OPEN} invoices={invoices} pagination={paginator} onPaginationChange={handlePaginationChange} isLoading={isLoading} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Invoices;
