import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import { mapStyle } from 'resources/theme/GoogleMapStyle';
import config from 'config';
import { useIsMobileView } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService } from 'models/centers.model';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MapMarker from './MapMarker';
import getMarkerIcon, { getPersonMarkerIcon } from '../utils/getMarkerIcon';
import { FavoriteLab } from '../types';
import customClusterRenderer from '../utils/customClusterRenderer';

interface MapCentersProps {
  resetZoomTrigger: number;
  centersInfo: {
    center: Center;
    file: CenterIncludedFile;
    services: CenterIncludedService[];
  }[];
  myPosition:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  markersState: boolean[];
  favorites: FavoriteLab[];
  handleOpenMapMarker: (index: number) => void;
  handleCloseMapMarker: () => void;
}

const defaultMapCenter = { lat: 49.8153, lng: 6.1296 }; // Luxembourg
const defaultMapZoom = 9;

const MapCenters: FC<MapCentersProps> = ({ centersInfo, myPosition, markersState, handleOpenMapMarker, favorites, resetZoomTrigger }) => {
  const { isLoaded: isGoogleLoaded /* , loadError: googleLoadError */ } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey || '',
  });
  const isMobileView = useIsMobileView();
  const [map, setMap] = useState<google.maps.Map>();
  const handleLoad = useCallback(function onLoad(mapInstance: google.maps.Map) {
    setMap(mapInstance);
  }, []);

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      renderer: customClusterRenderer(map),
    });
  }, [map]);

  useEffect(() => {
    if (map) {
      map?.setZoom(defaultMapZoom);
    }
  }, [resetZoomTrigger]);

  useEffect(() => {
    if (map && myPosition && !isMobileView) {
      map.panTo(myPosition);
      google.maps.event.trigger(map, 'resize');
    }
  }, [myPosition, map, isMobileView]);

  return (
    <>
      {isGoogleLoaded && (
        <GoogleMap onLoad={handleLoad} center={defaultMapCenter} zoom={defaultMapZoom} mapContainerStyle={{ height: '100%', width: '100%' }} options={{ styles: mapStyle }}>
          <>
            {centersInfo.map(
              (info, index) =>
                info.center.attributes.field_prelevement_latitude &&
                info.center.attributes.field_prelevement_longitude && (
                  <MapMarker
                    key={info.center.id}
                    position={{
                      lat: parseFloat(info.center.attributes.field_prelevement_latitude.replaceAll(',', '.')),
                      lng: parseFloat(info.center.attributes.field_prelevement_longitude.replaceAll(',', '.')),
                    }}
                    clusterer={clusterer ? clusterer : undefined}
                    opened={markersState[index]}
                    handleOpen={() => handleOpenMapMarker(index)}
                    icon={getMarkerIcon(info.center.attributes.field_type_de_laboratoire, favorites?.map(({ lab_id }) => lab_id)?.includes(info.center.id))}
                  />
                ),
            )}
          </>
          {myPosition && <Marker position={myPosition} animation={google.maps.Animation.DROP} icon={getPersonMarkerIcon()} />}
        </GoogleMap>
      )}
    </>
  );
};

export default MapCenters;
