import { AppointmentStorage } from 'components/appointment/types/Storage';
import { FC, useEffect, useState } from 'react';
import PatientInfoCard from './components/PatientInfoCard';
import { Stack } from '@mui/material';
import { useStore } from 'react-redux';
import { useAuthenticatedUser } from 'hooks';

interface InformationStepProps {
  setNextButtonActive: (state: boolean) => void;
}

const InformationStep: FC<InformationStepProps> = ({ setNextButtonActive }) => {
  const { getState } = useStore();
  const authenticatedUser = useAuthenticatedUser();

  const renderPatients = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.patients) return;

    const cards = [];

    for (let i = 0; i < storage.patients.length; i++) {
      if (storage.patients[i].selected) {
        cards.push(<PatientInfoCard key={'patient' + i} type={storage.patients[i].type} customId={storage.patients[i].id} setNextButtonActive={setNextButtonActive} />);
      }
    }

    return cards;
  };

  const renderAdults = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.adultsNumber) return;

    const cards = [];

    for (let i = 0; i < storage.adultsNumber; i++) {
      cards.push(<PatientInfoCard key={'adult' + i} type='Adult' customId={'adult' + i} setNextButtonActive={setNextButtonActive} />);
    }

    return cards;
  };

  const renderChild = () => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.childrenNumber) return;

    const cards = [];

    for (let i = 0; i < storage.childrenNumber; i++) {
      cards.push(<PatientInfoCard key={'child' + i} type='Child' customId={'child' + i} setNextButtonActive={setNextButtonActive} />);
    }

    return cards;
  };

  return (
    <Stack
      spacing={2}
      sx={{
        width: {
          xs: '100%',
          sm: '70%',
          md: '70%',
        },
      }}
    >
      {authenticatedUser && renderPatients()}
      {!authenticatedUser && renderAdults()}
      {!authenticatedUser && renderChild()}
    </Stack>
  );
};

export default InformationStep;
