import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

type SummaryFields = {
  labTitle: string;
  labAddress: string;
  procedure: string;
  duration: string;
  date: Date;
  timeSlot: string;
  prescriptions: { pacient: string; file: string; doctor: string; validUntilDate: Date }[];
};
type SummaryProps = {
  summaryFields: SummaryFields;
  type?: 'check-in' | 'appointment' | 'fertility';
};

const Summary: FC<SummaryProps> = ({ summaryFields, type }) => {
  const { labTitle, labAddress, date, duration, timeSlot, procedure, prescriptions } = summaryFields;
  const { t } = useTranslation();

  return (
    <Stack alignSelf='center' justifyContent='center' alignItems='start'>
      <Typography variant='body1' fontWeight='700'>
        {labTitle}
      </Typography>
      <Typography variant='body1' fontWeight='700'>
        {labAddress}
      </Typography>

      <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey3}` }} />

      <Typography variant='body1' fontSize='20px' fontWeight='500'>
        {dayjs(date).format('MMMM DD YYYY')}
      </Typography>
      <Typography variant='body1' fontSize='20px' fontWeight='500'>
        {timeSlot}
      </Typography>
      <Typography variant='body1'>
        {t(`appointment.book.steps.summary.duration`, { replace: { duration } })} {dayjs(date).format('MMMM DD YYYY')}
      </Typography>
      <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey3}` }} />
      <Typography variant='body1' fontWeight='700' mb={1} sx={{ color: redesignColors.grey.grey2 }}>
        {t(`appointment.book.steps.summary.medical_acts_other`, { replace: { duration: timeSlot } })} {dayjs(date).format('MMMM DD YYYY')}
      </Typography>

      <Typography variant='body1' fontWeight='700'>
        {procedure}
      </Typography>
      <Divider sx={{ my: 2, width: '100%', border: '0.5px solid grey' }} />
      <Typography variant='body1' fontWeight='700' sx={{ pl: 2, mb: 1 }}>
        {prescriptions.length === 1 ? 'Patient' : `${prescriptions.length} Patients`}
      </Typography>
      {prescriptions.map((prescription) => (
        <Card sx={{ width: '100%', mb: 2 }}>
          <CardContent>
            <Typography variant='body1' fontWeight='700'>
              {prescription.pacient}
            </Typography>
            <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey3}` }} />

            <Typography variant='body1' fontWeight='700' mb={1} sx={{ color: redesignColors.grey.grey2 }}>
              {type === 'fertility' ? t(`appointment.book.steps.summary.prescriptions`) : t(`new_appointment.stepper.steps.summary.prescriptions`)}:
            </Typography>
            <Typography variant='body1' fontWeight='500' mb={2}>
              {prescription.file}
            </Typography>
            {type === 'fertility' && (
            <Typography variant='body1' fontWeight='500'>
              {prescription.doctor}
            </Typography>
            )}
            {type === 'fertility' && (
            <Typography variant='body2'>
              <span style={{ color: redesignColors.grey.grey2 }}>{t(`fertility.summary.valid_until`)}: </span>
              {dayjs(date).format('MMMM DD YYYY')}
            </Typography>
            )}
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

export default Summary;
