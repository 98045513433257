import { DrupalTypes } from './drupal.model';

export type CityChoice = {
  value: string;
  label: string;
};

export type SpecialityChoice = {
  value: string;
  label: string;
};

export type ScheduleChoice = {
  value: string;
  label: string;
};

export type CentersFormValues = {
  city: string;
  specialities: string[];
  schedule: 'all' | 'open_now' | 'choose_day_time';
  distance: number;
  day: 1;
  time: number;
  labomobile: boolean;
  pharmacy: boolean;
  laboratoire: boolean;
  PRMAccessRequired: boolean;
  publicParkingRequired: boolean;
  foodZone: boolean;
  wifi: boolean;
  pharmacyService: boolean;
};

export type FieldPrelevementHoraire = {
  day: number;
  starthours: number;
  endhours: number;
  comment: string;
};

export type FieldPrelevementPhoto = {
  data: {
    type: string;
    id: string;
    meta: {
      alt: string;
      title: string;
      width: number;
      height: number;
    };
  };
  links: { self: { href: string } };
};

export type FieldPrelevementServices = {
  data: {
    type: string;
    id: string;
  }[];
  links: { self: { href: string } };
};

type CenterUpdate = {
  type: string;
  id: string;
  meta: {
    target_revision_id: number;
    drupal_internal__target_id: number;
  };
};
export type CenterRelationship = {
  node_type: {
    data: { type: string; id: string };
    links: { related: { href: string }; self: { href: string } };
  };
  revision_uid: {
    data: { type: string; id: string };
    links: { related: { href: string }; self: { href: string } };
  };
  uid: {
    data: { type: string; id: string };
    links: { related: { href: string }; self: { href: string } };
  };
  field_horaires_specifiques: {
    data: any[];
    links: { related: { href: string }; self: { href: string } };
  };
  field_updates: {
    data: CenterUpdate[];
  };
  field_prelevement_photo: FieldPrelevementPhoto;
  field_prelevement_services: FieldPrelevementServices;
};

export enum LaboratoireTypeEnum {
  pharmacy = 'Dépôt de prélèvements',
  labomobile = 'Labomobile',
  laboratoire = 'Laboratoire',
}

export type Center = {
  type: string;
  id: string;
  links: { self: { href: string } };
  attributes: {
    drupal_internal__nid: number;
    drupal_internal__vid: number;
    langcode: string;
    revision_timestamp: string;
    revision_log?: any;
    status: boolean;
    title: string;
    created: string;
    changed: string;
    promote: boolean;
    sticky: boolean;
    default_langcode: boolean;
    revision_translation_affected: boolean;
    path: any;
    content_translation_source: string;
    content_translation_outdated: boolean;
    body?: any;
    field_prelevement_centre_ferme: boolean;
    field_prelevement_code?: any;
    field_prelevement_code_postal: string;
    field_prelevement_complement?: any;
    field_prelevement_email: string;
    field_prelevement_handicape: boolean;
    field_prelevement_horaires: FieldPrelevementHoraire[];
    field_prelevement_horaires_exc: any[];
    field_prelevement_infos_importan?: any;
    field_prelevement_info_important?: any;
    field_prelevement_latitude: string;
    field_prelevement_longitude: string;
    field_prelevement_numero_rue: string;
    field_prelevement_parking: boolean;
    field_prelevement_parking_public: boolean;
    field_prelevement_park_latitude?: any;
    field_prelevement_park_longitude?: any;
    field_prelevement_pays: number;
    field_prelevement_rue: string;
    field_prelevement_telephone: string;
    field_prelevement_texte_libre?: any;
    field_prelevement_transport: boolean;
    field_prelevement_ville: string;
    field_prelevemen_ligne_transport: any[];
    field_prelevemtn_actif: boolean;
    field_type_de_laboratoire: LaboratoireTypeEnum;
  };
  relationships: CenterRelationship;
};

export type CenterIncludedFile = {
  type: string;
  id: string;
  attributes: {
    drupal_internal__fid: number;
    langcode: string;
    filename: string;
    uri: {
      value: string;
      url: string;
    };
    filemime: string;
    filesize: number;
    status: boolean;
    created: string;
    changed: string;
  };
  links: { self: { href: string } };
  relationships: {
    uid: {
      data: { type: string; id: string };
      links: { related: { href: string }; self: { href: string } };
    };
  };
};

export type CenterIncludedService = {
  type: string;
  id: string;
  attributes: {
    drupal_internal__tid: number;
    drupal_internal__revision_id: number;
    langcode: string;
    revision_created: string;
    revision_log_message?: any;
    status: boolean;
    name: string;
    description?: any;
    weight: number;
    changed: string;
    default_langcode: boolean;
    revision_translation_affected: boolean;
    path: any;
    content_translation_source: string;
    content_translation_outdated: boolean;
    content_translation_created?: any;
  };
  links: { self: { href: string } };
  relationships: any;
};

export enum CenterUpdateCategory {
  information = 'information',
  working_hours = 'working_hours',
  announcements = 'announcements',
}

type CenterUpdateAttributes = {
  drupal_internal__id: number;
  drupal_internal__revision_id: number;
  langcode: string;
  status: boolean;
  created: string;
  parent_id: string;
  parent_type: string;
  parent_field_name: string;
  behavior_settings: any[];
  default_langcode: boolean;
  revision_translation_affected: true;
  content_translation_source: string;
  content_translation_outdated: false;
  content_translation_changed: string;
  field_laboratory_category: CenterUpdateCategory;
  field_laboratory_message: {
    value: string;
    format: string;
    processed: string;
  };
};
export type CenterIncludedUpdate = {
  type: DrupalTypes.lab_update;
  id: string;
  links: { self: { href: string } };
  attributes: CenterUpdateAttributes;
};

export type CentersSearchResults = {
  jsonapi: any;
  data: Center[];
  included: any[];
  links: any;
};
