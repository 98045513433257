import theme_color, { redesignColors } from 'resources/theme/theme.colors';

import { alpha, createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

const theme = createTheme({
  components: {
    MuiButton: {
      variants: [
        // primary
        {
          props: { variant: 'contained' },
          style: {
            color: theme_color.palette.primary.contrastText,
            backgroundColor: theme_color.palette.primary.main,
            borderRadius: 8,
            border: '1px solid',
            borderColor: theme_color.palette.primary.main,
            disableElevation: true,
            boxShadow: 'none',
            padding: '8px 24px',
            height: 44,
            '&:hover': {
              backgroundColor: redesignColors.blue.blue_hover,
              border: '1px solid',
              borderColor: redesignColors.blue.blue_hover,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              borderColor: 'transparent',
            },
          },
        },
        // secondary
        {
          props: { variant: 'outlined' },
          style: {
            backgroundColor: theme_color.palette.common.white,
            color: theme_color.palette.text.primary,
            borderRadius: 8,
            border: '1px solid',
            borderColor: redesignColors.grey.grey2,
            disableElevation: true,
            boxShadow: 'none',
            padding: '6px 24px',
            height: 44,
            '&:hover': {
              border: '1px solid',
              borderColor: redesignColors.blue.main,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              border: '1px solid',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            backgroundColor: redesignColors.blue.main,
            color: theme_color.palette.text.primary,
            borderRadius: 8,
            border: '1px solid',
            borderColor: redesignColors.blue.main,
            disableElevation: true,
            boxShadow: 'none',
            padding: '6px 24px',
            height: 44,
            '&:hover': {
              backgroundColor: theme_color.palette.secondary.main,
              color: theme_color.palette.common.white,
              border: '1px solid',
              borderColor: theme_color.palette.secondary.main,
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              border: '1px solid',
            },
          },
        },
        // tertiary (previous, back, read more, etc)
        {
          props: { variant: 'text' },
          style: {
            padding: '0px 8px',
            borderRadius: 8,
            minWidth: 'auto',
            color: redesignColors.blue.dark,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            height: 44,
            '& .MuiSvgIcon-root': {
              color: redesignColors.blue.dark,
              backgroundColor: 'transparent',
            },
            '&:hover': {
              textDecoration: 'underline',
              backgroundColor: 'transparent',
              opacity: 0.9,
              '& .MuiSvgIcon-root': {
                color: redesignColors.blue.dark,
              },
            },
            '&.Mui-disabled': {
              borderColor: alpha(theme_color.palette.text.disabled, theme_color.palette.action.disabledOpacity),
            },
          },
        },
        // danger
        {
          props: { variant: 'danger' },
          style: {
            backgroundColor: theme_color.palette.common.white,
            color: redesignColors.red,
            borderRadius: 8,
            border: '1px solid',
            borderColor: redesignColors.red,
            disableElevation: true,
            boxShadow: 'none',
            padding: '8px 24px',
            height: 44,
            '&:hover': {
              borderColor: red[600],
              backgroundColor: theme_color.palette.common.white,
              color: red[600],
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              border: '1px solid',
            },
          },
        },
        // header - background light
        {
          props: { variant: 'header_light' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.text.primary,
            borderBottom: '2px solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            fontSize: 18,
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.primary.main,
            },
          },
        },
        // header - background dark
        {
          props: { variant: 'header_dark' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.primary.contrastText,
            borderBottom: '2px solid',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
            fontSize: 18,
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.primary.contrastText,
            },
          },
        },
        // link (footer)
        {
          props: { variant: 'link' },
          style: {
            padding: '0px',
            minWidth: 'auto',
            color: theme_color.palette.text.primary,
            borderBottom: '1px solid',
            borderColor: 'transparent',
            fontWeight: 400,
            borderRadius: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme_color.palette.text.primary,
            },
          },
        },
      ],
    },
  },
});

export default theme;

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    link: true;
    danger: true;
    header_light: true;
    header_dark: true;
  }
}
