import { combineReducers } from 'redux';
import appointmentReducer from './appointmentForm.reducer';
import navMenuReducer from './navMenu.reducer';
import notificationsReducer from './notifications.reducer';
import prescriptionProfileReducer from './prescriptionProfile.reducer';
import sessionReducer from './session.reducer';
import newAppointmentFlowReducer from './newAppointemtnFlow.reducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  navMenu: navMenuReducer,
  appointmentForm: appointmentReducer,
  prescriptionProfile: prescriptionProfileReducer,
  notification: notificationsReducer,
  newAppointmentFlowReducer: newAppointmentFlowReducer,
});

export type GlobalState = ReturnType<typeof rootReducer>;

export default rootReducer;
