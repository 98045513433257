import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import AnalysisAttributes from 'components/analysis-attributes';
import { AnalysisDataProps } from 'models/analysis.model';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

const AnalysisData: FC<AnalysisDataProps> = ({ analysis, open, handleChangeOpen, keyOpen }) => {
  const toggleOpenCollapse = () => {
    open &&
      handleChangeOpen(
        open.map((element: boolean, i: number) => {
          return i === keyOpen ? !element : false;
        }),
      );
  };

  return (
    <>
      <TableRow
        onClick={() => toggleOpenCollapse()}
        sx={{
          border: '0 !important',
        }}
      >
        <TableCell
          size='small'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '0 !important',
            cursor: 'pointer',
            height: '56px',
            backgroundColor: 'background.paper',
            marginBottom: 1,
            borderRadius: 1,
          }}
        >
          <Typography sx={{ overflowWrap: 'anywhere', color: redesignColors.dark }}>{analysis.name}</Typography>
          <IconButton size='small'>{open && open[keyOpen] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon sx={{ color: redesignColors.grey.grey2 }} />}</IconButton>
        </TableCell>
      </TableRow>

      <TableRow sx={{ border: '0 !important', p: 0 }}>
        <TableCell size='small' sx={{ p: 0, border: '0 !important', backgroundColor: 'background.paper', borderRadius: 1 }}>
          <Collapse in={open && open[keyOpen]} timeout='auto' unmountOnExit>
            <AnalysisAttributes analysis={analysis} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AnalysisData;
