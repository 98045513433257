import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, styled, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CentersFormValues, CityChoice, SpecialityChoice } from 'models/centers.model';
import { FC } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { redesignColors } from 'resources/theme/theme.colors';
import { Days, Hours } from '../utils/constants';

interface CentersFormProps {
  cities: CityChoice[];
  specialities: SpecialityChoice[];
  locationEnabled?: boolean;
  register: UseFormRegister<CentersFormValues>;
  watch: UseFormWatch<CentersFormValues>;
  setValue: UseFormSetValue<CentersFormValues>;
  defaultValues: CentersFormValues;
}

export const MaxDistance = 50;
const StyledSlider = styled(Slider)(({ theme }) => ({
  height: 8,
  padding: 0,
  marginTop: 2,
  width: '100%',
  '& .MuiSlider-mark': {
    width: 2,
    height: 6,
    opacity: 0.8,
  },
  '& .MuiSlider-rail': {
    border: 0,
    background: redesignColors.grey.grey4,
  },
  '& .MuiSlider-thumb': {
    border: `2px solid ${theme.palette.secondary.main}`,
    background: theme.palette.primary.main,
  },
}));

const CentersForm: FC<CentersFormProps> = ({ cities, specialities, setValue, register, watch, defaultValues, locationEnabled }) => {
  const { t } = useTranslation();
  const { schedule, distance, city, specialities: specialitiesForm } = watch();

  return (
    <form>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.speciality.title')}
            </Typography>
            <Select
              variant='filled'
              multiple
              label={t('centers.form.speciality.label')}
              onChange={(e) => {
                setValue('specialities', (e.target.value.filter((v: string) => v !== 'all') as any) || [], { shouldValidate: true, shouldDirty: true });
              }}
              value={specialitiesForm as any}
              defaultValue={defaultValues.specialities}
              inputProps={{
                name: 'speciality',
                id: 'speciality',
              }}
              fullWidth
            >
              <MenuItem value='all' disabled>
                {t('centers.form.speciality.all')}
              </MenuItem>
              {specialities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </Select>
            {!!specialitiesForm.length &&
              specialitiesForm.map(
                (speciality) =>
                  speciality !== 'all' && (
                    <Chip
                      variant='outlined'
                      color='secondary'
                      label={speciality}
                      onDelete={() =>
                        setValue(
                          'specialities',
                          specialitiesForm.filter((spec) => spec !== speciality),
                          { shouldValidate: true, shouldDirty: true },
                        )
                      }
                      sx={{ mt: 1, mb: 1 }}
                    />
                  ),
              )}
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.city.label')}
            </Typography>
            <TextField
              select
              variant='filled'
              label={t('centers.form.city.label')}
              {...register('city')}
              defaultValue={defaultValues.city}
              value={city}
              fullWidth
              InputProps={{
                sx: { paddingRight: '24px' },
                endAdornment: city && (
                  <IconButton onClick={() => setValue('city', '', { shouldValidate: true, shouldDirty: true })} sx={{ mr: 1.5 }}>
                    <ClearIcon sx={{ fill: `${redesignColors.red} !important` }} />
                  </IconButton>
                ),
              }}
            >
              <MenuItem value={-1}>{t('centers.form.city.all')}</MenuItem>
              {cities.map((e, i) => {
                return (
                  <MenuItem value={e.value} key={i}>
                    {e.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {locationEnabled && (
            <>
              <Grid item xs={12}>
                <Typography variant='body1' fontWeight='600' mb={1}>
                  {t('centers.form.distance.title')}
                </Typography>
                <Box>
                  <StyledSlider aria-label='Default' color='primary' {...register('distance')} max={MaxDistance} defaultValue={defaultValues.distance} disabled={false} min={0} />
                  <Typography variant='body1'>
                    {t('centers.form.distance.search_area')} {distance === MaxDistance ? `${distance}+` : distance} km
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.schedule.label')}
            </Typography>
            <RadioGroup aria-labelledby='demo-radio-buttons-group-label' defaultValue={defaultValues.schedule} name='schedule'>
              <FormControlLabel {...register('schedule')} value='all' control={<Radio />} label={t('centers.form.schedule.all')} />
              <FormControlLabel {...register('schedule')} value='open_now' control={<Radio />} label={t('centers.form.schedule.open_now')} />
              <FormControlLabel {...register('schedule')} value='choose_day_time' control={<Radio />} label={t('centers.form.schedule.choose_day_time')} />
            </RadioGroup>
            <Grid container>
              <Grid item xs={5}>
                <TextField
                  select
                  variant='filled'
                  label={t('centers.form.schedule.day_label')}
                  {...register('day')}
                  defaultValue={defaultValues.day}
                  disabled={schedule !== 'choose_day_time'}
                  fullWidth
                >
                  {Days.map((e, i) => {
                    return (
                      <MenuItem value={e.value} key={i}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  select
                  variant='filled'
                  label={t('centers.form.schedule.time_label')}
                  {...register('time')}
                  defaultValue={defaultValues.time}
                  disabled={schedule !== 'choose_day_time'}
                  fullWidth
                >
                  <MenuItem value={-1}>{t('centers.form.schedule.any')}</MenuItem>
                  {Hours.map((e, i) => {
                    return (
                      <MenuItem value={e.value} key={i}>
                        {e.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.center_types.title')}
            </Typography>
            <FormControlLabel
              {...register('laboratoire')}
              name='laboratoire'
              control={<Checkbox defaultChecked={!!defaultValues.laboratoire} sx={{ mr: 0.5 }} />}
              label={t('centers.form.center_types.laboratoire')}
              sx={{ ml: 0 }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              {...register('labomobile')}
              name='labomobile'
              control={<Checkbox defaultChecked={!!defaultValues.labomobile} sx={{ mr: 0.5 }} />}
              label={t('centers.form.center_types.labomobile')}
              sx={{ ml: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('pharmacy')}
              name='pharmacy'
              control={<Checkbox defaultChecked={!!defaultValues.pharmacy} sx={{ mr: 0.5 }} />}
              label={t('centers.form.center_types.pharmacy')}
              sx={{ ml: 0 }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item spacing={0.5}>
          <Grid item xs={12}>
            <Typography variant='body1' fontWeight='600' mb={1}>
              {t('centers.form.services_title')}
            </Typography>
            <FormControlLabel
              {...register('PRMAccessRequired')}
              name='PRMAccessRequired'
              control={<Checkbox defaultChecked={!!defaultValues.PRMAccessRequired} sx={{ mr: 0.5 }} />}
              label={t('centers.form.prm_access')}
              sx={{ ml: 0 }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              {...register('publicParkingRequired')}
              name='publicParkingRequired'
              control={<Checkbox defaultChecked={!!defaultValues.publicParkingRequired} sx={{ mr: 0.5 }} />}
              label={t('centers.form.parking')}
              sx={{ ml: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('foodZone')}
              name='foodZone'
              control={<Checkbox defaultChecked={!!defaultValues.publicParkingRequired} sx={{ mr: 0.5 }} />}
              label={t('centers.form.food_zone')}
              sx={{ ml: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('wifi')}
              name='wifi'
              control={<Checkbox defaultChecked={!!defaultValues.publicParkingRequired} sx={{ mr: 0.5 }} />}
              label={t('centers.form.wi_fi')}
              sx={{ ml: 0 }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('pharmacyService')}
              name='pharmacyService'
              control={<Checkbox defaultChecked={!!defaultValues.publicParkingRequired} sx={{ mr: 0.5 }} />}
              label={t('centers.form.pharmacy')}
              sx={{ ml: 0 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CentersForm;
