import { Button, Grid, SvgIcon } from '@mui/material';
import { ReactComponent as PlusIcon } from 'resources/icons/plus.svg';
import ContextLink from 'components/_base/ContextLink';
import { Person } from 'models/profile.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'utils/func/Person.func';
import ProfileCard from './ProfileCard';

const IndexRelativeProfile: FC<{ relativesCurrentUser: Person[] }> = ({ relativesCurrentUser }) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} display='grid' alignItems='stretch'>
        <ContextLink to='PROFILE_ADD_RELATIVE'>
          <Button startIcon={<SvgIcon component={PlusIcon} />} variant='contained'>
            {t('profile.relatives.add.add_relative')}
          </Button>
        </ContextLink>
      </Grid>
      {relativesCurrentUser?.map((relative, key) => {
        return (
          relative.is_relative && (
            <Grid item xs={12} display='flex' alignItems='stretch' key={key}>
              <ProfileCard title={getFullName(relative)} person={relative} />
            </Grid>
          )
        );
      })}
    </>
  );
};

export default IndexRelativeProfile;
