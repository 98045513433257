import { Female, Male, TypeSpecimen } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, Stack, styled, TextField, TextFieldProps, ToggleButton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { PatientType } from 'components/appointment/types/Patient';
import PhoneInput from 'components/phone-input';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { PatientInfo } from 'store/reducers/newAppointemtnFlow.reducer';

type Gender = 'male' | 'female';
type PatienValues = {
  firstname: string;
  birthname: string;
  lastname?: string;
  birthdate: string | Date;
  email: string;
  verifyemail: string;
  phone: string;
  cns?: string;
  gender: string;
  canRemove?: boolean;
  type: PatientType;
  selected?: boolean;
};

interface PatientInfoCardProps {
  customId?: string;
  type: 'Adult' | 'Child' | 'Patient';
  onlyMale?: boolean;
  values?: PatienValues;
  setValue?: (key: string, value: string | Date) => void;
  setNextButtonActive?: (state: boolean) => void;
}

const StyledGenderButton = styled(ToggleButton)(({ theme, selected }) => ({
  width: '100%',
  borderRadius: '8px',
  border: '0.5px solid grey',
  paddingLeft: '5px',
  justifyContent: 'space-around',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '.genderIcon': {
    '&.male': {
      color: selected ? 'black' : theme.palette.primary.main,
    },
    '&.female': {
      color: selected ? 'black' : 'pink',
    },
  },
}));

const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} variant='filled' />)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '.MuiFormLabel-root': {
    padding: 0,
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#F2FAFD',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '.MuiFormLabel-root': {
    padding: 0,
  },
}));

const isValidEmail = (value: string) => {
  const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailPattern.test(value);
};

const getEmailHelperText = (email: string): string | false => {
  if (email && !isValidEmail(email)) {
    return 'fertility.errors.invalid_email';
  }
  return false;
};
const getConfirmEmailHelperText = (email: string, confirmEmail: string): string | false => {
  if (email && !isValidEmail(email)) {
    return 'fertility.errors.invalid_email';
  }
  if (email && confirmEmail && email !== confirmEmail) {
    return 'fertility.errors.emails_not_match';
  }
  return false;
};

const PatientInfoCard: FC<PatientInfoCardProps> = ({ customId, type, onlyMale, values, setValue, setNextButtonActive = (state: boolean) => {} }) => {
  const { t } = useTranslation();
  const { getState, dispatch } = useStore();

  const [firstName, setFirstName] = useState(values?.firstname || '');
  const [lastName, setLastName] = useState(values?.lastname || '');
  const [birthName, setBirthName] = useState(values?.birthname || '');
  const [gender, setGender] = useState<'male' | 'female' | undefined>(onlyMale ? 'male' : (values?.gender as Gender) || undefined);
  const [email, setEmail] = useState(values?.email || '');
  const [emailConfirm, setEmailConfirm] = useState(values?.verifyemail || '');
  const [phone, setPhone] = useState(values?.phone || '');
  const [birthDate, setBirthDate] = useState<Date>(values?.birthdate instanceof Date ? values?.birthdate : new Date());
  const [cns, setCns] = useState(values?.cns || '');
  const [canRemove, setCanRemove] = useState(values?.canRemove || false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;

    if (storage.patients) {
      const patient = storage.patients.find((p: PatientInfo) => p.id === customId);
      if (patient) {
        if (patient.firstName) setFirstName(patient.firstName);
        if (patient.lastName) setLastName(patient.lastName);
        if (patient.birthName) setBirthName(patient.birthName);
        if (patient.birthDate) setBirthDate(patient.birthDate instanceof Date ? patient.birthDate : new Date());
        if (patient.cns) setCns(patient.cns || '');
        if (patient.phone) setPhone(patient.phone);
        if (patient.email) setEmail(patient.email);
        if (patient.emailConfirm) setEmailConfirm(patient.emailConfirm);
        if (patient.gender) setGender(patient.gender);
        if (patient.canRemove) setCanRemove(patient.canRemove);
        if (patient.selected !== undefined) setSelected(patient.selected);
      }
    }
  }, [customId, getState, setFirstName, setLastName, setBirthDate, setBirthName, setCns, setPhone, setEmail, setEmailConfirm, setGender]);

  useEffect(() => {
    if (!firstName || !birthName || !email || !emailConfirm || !phone) {
      setNextButtonActive(false);
    } else {
      setNextButtonActive(true);

      dispatch({
        type: NewAppointmentFlowActionType.SET_PATIENT_INFO,
        patientInfo: { id: customId, firstName, lastName, birthName, birthDate, email, emailConfirm, phone, gender, cns, canRemove, type, selected },
      });
    }
  }, [firstName, lastName, birthName, gender, email, emailConfirm, phone, birthDate, cns, customId, getState, dispatch, setNextButtonActive, canRemove, type, selected]);

  useEffect(() => {
    if (values) {
      setFirstName(values.firstname);
      setLastName(values.lastname || '');
      setBirthName(values.birthname);
      setGender(values?.gender as Gender);
      setEmail(values.email);
      setEmailConfirm(values.verifyemail);
      setPhone(values.phone);
      setBirthDate(values.birthdate instanceof Date ? values.birthdate : new Date());
      setCns(values.cns || '');
      setSelected(values.selected || false);
    }
  }, [setFirstName, setLastName, setBirthName, setGender, setEmail, setEmailConfirm, setPhone, setBirthDate, setCns, values]);

  const emailHelperText = getEmailHelperText(email);
  const emailConfirmHelperText = getConfirmEmailHelperText(email, emailConfirm);
  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 600 }} variant='h6'>
              {type}
            </Typography>
            <Divider />
          </Stack>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5} display='flex'>
                <StyledGenderButton
                  selected={gender === 'male'}
                  value='male'
                  onChange={(e) => {
                    setGender('male');
                    setValue?.('gender', 'male');
                  }}
                  sx={{ mr: 2 }}
                >
                  <Male className='genderIcon male' /> {t('new_appointment.stepper.steps.information.male')}
                </StyledGenderButton>
                <StyledGenderButton
                  selected={gender === 'female'}
                  value='female'
                  disabled={onlyMale}
                  onChange={(e) => {
                    setGender('female');
                    setValue?.('gender', 'male');
                  }}
                >
                  <Female className='genderIcon female' /> {t('new_appointment.stepper.steps.information.female')}
                </StyledGenderButton>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='text'
                  label={t('new_appointment.stepper.steps.information.first_name')}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setValue?.('firstname', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='text'
                  label={t('new_appointment.stepper.steps.information.birth_name')}
                  value={birthName}
                  onChange={(e) => {
                    setBirthName(e.target.value);
                    setValue?.('birthname', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  type='text'
                  label={t('new_appointment.stepper.steps.information.last_name')}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setValue?.('lastname', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledDatePicker
                  label={t('new_appointment.stepper.steps.information.birth_date') + '*'}
                  value={birthDate}
                  onChange={(val) => {
                    setBirthDate(val as Date);
                    setValue?.('birthdate', val as Date);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  type='text'
                  label={t('new_appointment.stepper.steps.information.cns')}
                  value={cns}
                  onChange={(e) => {
                    setCns(e.target.value);
                    setValue?.('cns', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!email && !isValidEmail(email)}
                  helperText={emailHelperText ? t(emailHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.email')}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValue?.('email', e.target.value);
                  }}
                />
                <Typography sx={{ color: '#7A9099' }} variant='button'>
                  {t('new_appointment.stepper.steps.information.email_info')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!emailConfirm && (!isValidEmail(emailConfirm) || emailConfirm !== email)}
                  helperText={emailConfirmHelperText ? t(emailConfirmHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.confirm_email')}
                  value={emailConfirm}
                  onChange={(e) => {
                    setEmailConfirm(e.target.value);
                    setValue?.('verifyemail', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <PhoneInput
                  sx={{
                    width: '100%',
                    backgroundColor: '#F2FAFD',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      padding: '5px 16px',
                    },
                    '.MuiFormLabel-root': {
                      padding: 0,
                    },
                  }}
                  label={t('new_appointment.stepper.steps.information.phone')}
                  required
                  value={phone}
                  onChange={(e) => {
                    setPhone(e as string);
                    setValue?.('phone', e as string);
                  }}
                />
                <Typography sx={{ color: '#7A9099' }} variant='button'>
                  {t('new_appointment.stepper.steps.information.phone_info')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PatientInfoCard;
