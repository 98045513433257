export enum PatientType {
  Adult = 'Adult',
  Child = 'Child',
}

export interface Patient {
  key: number;
  type: PatientType;
  firstName?: string;
  lastName?: string;
  cns?: string;
  birthDate?: Date;
  email?: string;
  phone?: string;
  isCurrentUser?: boolean;
  selected?: boolean;
  id?: string | number;
  canRemove?: boolean;
  gender?: string;
}

export interface AppointmentPatient {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: string;
  birthDate: Date;
  birthName: string;
  emailConfirm: string;
  type?: PatientType;
  cns?: string;
  canRemove?: boolean;
  selected?: boolean;
}

export interface PatientFiles {
  patientId: string | number;
  files: File[];
}
