import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ContactRequest } from 'models/contact-request.model';
import ContextLink from 'components/_base/ContextLink';
import { redesignColors } from 'resources/theme/theme.colors';

const ContactForm = () => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const [submitting, setSubmitting] = useState(false);
  const [readPrivacy, setReadPrivacy] = useState(false);
  const { register, handleSubmit, formState, reset, watch, setValue } = useForm<ContactRequest>({
    mode: 'onChange',
    defaultValues: {
      subject: '',
      message: '',
    },
  });
  const { subject } = watch();
  const onSubmit: SubmitHandler<ContactRequest> = useCallback(
    (formData) => {
      setSubmitting(true);
      const requestData: ContactRequest = {
        subject: formData.subject,
        message: formData.message,
      };

      api.contact
        .postContact(requestData)
        .then(() => {
          notification(t('contact.notifications.success'), 'success');
          reset();
          setReadPrivacy(false);
        })
        .catch((error) => {
          notification(t('contact.notifications.error'), 'error');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [notification, t, reset, setReadPrivacy],
  );

  return (
    <Card
      sx={{
        width: 'auto',
      }}
    >
      <CardContent>
        <Grid item xs={12} md={6} p={0}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'grid', gridTemplateRows: '1fr 6fr 1fr', gap: 2 }}>
              <FormControl fullWidth>
                <Select
                  value={subject}
                  onChange={(e) => {
                    setValue('subject', e.target.value, { shouldValidate: true, shouldDirty: true });
                  }}
                  defaultValue=''
                  variant='filled'
                  displayEmpty
                  renderValue={(selected) => {
                    return !selected ? `${t('contact.form.subject')} *` : t(`contact.category.${selected as string}`);
                  }}
                >
                  <MenuItem sx={{ display: 'none' }} value=''>
                    {t('contact.form.subject')}
                  </MenuItem>
                  <MenuItem value='home_or_workplace'>{t('contact.category.home_or_workplace')}</MenuItem>
                  <MenuItem value='results'>{t('contact.category.results')}</MenuItem>
                  <MenuItem value='covid_results'>{t('contact.category.covid_results')}</MenuItem>
                  <MenuItem value='billing'>{t('contact.category.billing')}</MenuItem>
                  <MenuItem value='mylab'>{t('contact.category.mylab')}</MenuItem>
                  <MenuItem value='req_partnerships'>{t('contact.category.req_partnerships')}</MenuItem>
                  <MenuItem value='recruitment'>{t('contact.category.recruitment')}</MenuItem>
                  <MenuItem value='press_and_com'>{t('contact.category.press_and_com')}</MenuItem>
                  <MenuItem value='webmaster'>{t('contact.category.webmaster')}</MenuItem>
                  <MenuItem value='data_protection'>{t('contact.category.data_protection')}</MenuItem>
                  <MenuItem value='patient_feedback'>{t('contact.category.patient_feedback')}</MenuItem>
                  <MenuItem value='other'>{t('contact.category.other')}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                variant='filled'
                multiline
                {...register('message', { required: true })}
                defaultValue={undefined}
                placeholder={`${t('contact.form.message')} *`}
                fullWidth
                sx={{
                  '&>.MuiFilledInput-root': {
                    height: '100%',
                    '&>.MuiFilledInput-input': {
                      height: '100% !important',
                      overflow: 'auto !important',
                    },
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox value={readPrivacy} checked={readPrivacy} onChange={(_, checked) => setReadPrivacy(checked)} sx={{ mx: 1 }} />}
                label={
                  <Typography display='flex'>
                    {t('contact.form.privacy_policy.label')}&nbsp;:&nbsp;
                    <ContextLink to='GDPR'>
                      <Typography sx={{ color: redesignColors.blue.dark, textDecoration: 'underline' }}> {t('contact.form.privacy_policy.link')}</Typography>
                    </ContextLink>
                  </Typography>
                }
              />
              <Box sx={{ display: 'flex' }}>
                <LoadingButton type='submit' variant='contained' disabled={!formState.isValid || !readPrivacy} loading={submitting} sx={{ width: '328px' }}>
                  {t('contact.form.submit')}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactForm;
