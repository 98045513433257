import { Box, Card, CardContent, Divider, Grid, Link, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PhoneIcon } from 'resources/icons/telephone.svg';
import LaboratoryMap from './LaboratoryMap';
import { redesignColors } from 'resources/theme/theme.colors';
import config from 'config';

const HeadquarterCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: 'auto',
      }}
    >
      <CardContent>
        <Grid container spacing={0} p={0}>
          <Grid item xs={12} md={6} pr={2}>
            <Typography variant='h6' fontWeight='600' mb={2}>
              {t('contact.info.headquaters')}
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: t('contact.info.address_html') }}></Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant='h6' fontWeight='600' mb={2}>
              {t('contact.info.telephone_reception')}
            </Typography>
            <Box display='flex' mb={2}>
              <SvgIcon fontSize='small' component={PhoneIcon} sx={{ color: redesignColors.grey.grey2, mr: 1.5, mt: 0.25 }} />
              <Box>
                <Typography fontWeight='500'>Phone </Typography>
                <Link href={`tel:${config.footer.priorityNumber}`} sx={{ color: redesignColors.blue.dark, textDecoration: 'none' }}>
                  {config.footer.priorityNumber}
                </Link>
              </Box>
            </Box>
            <Grid container spacing={0} p={0} wrap='nowrap'>
              <Grid item sm={4}>
                <Typography variant='body2' fontSize='14px' fontWeight='500'>
                  {t('contact.info.monday_friday')}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography variant='body2' fontSize='14px' fontWeight='500'>
                  {t('contact.info.time')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0} p={0} wrap='nowrap'>
              <Grid item sm={4}>
                <Typography variant='body2' fontSize='14px' fontWeight='500'>
                  {t('contact.info.saturday')}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography variant='body2' fontSize='14px' fontWeight='500'>
                  {t('contact.info.time')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 2, width: '100%', height: '291px' }}>
              <LaboratoryMap />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HeadquarterCard;
