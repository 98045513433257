import { Grid } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import Tabs from 'components/tabs';

export enum RelativeTab {
  me = 'me',
  relative = 'relative',
}
type Props = { selectedTab: RelativeTab; setSelectedTab: (tab: RelativeTab) => void };

const IndexProfileTabSwitcher: FC<Props> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();

  const handleChange = (selectedTab: RelativeTab) => {
    setSelectedTab(selectedTab);
  };

  const tabs = [
    {
      label: <ContextLink to={'PROFILE'}>{t('navigation.shared.me_or_my_relatives.me')}</ContextLink>,
      value: RelativeTab.me,
    },
    {
      label: <ContextLink to={'PROFILE_RELATIVE'}>{t('navigation.shared.me_or_my_relatives.my_relatives')}</ContextLink>,
      value: RelativeTab.relative,
    },
  ];
  return (
    <Grid item xs={12} sx={{
      '&.MuiGrid-item': {
        paddingTop: 0,
      },
    }}>
      <Tabs value={selectedTab} onChange={handleChange} tabs={tabs} />
    </Grid>
  );
};

export default IndexProfileTabSwitcher;
