import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  title: string;
  subtitle: string;
  submitTitle: string;
  cancelTitle?: string;
  hideCancel?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmDialog: FC<Props> = ({ open, onClose, title, subtitle, submitTitle, cancelTitle, onSubmit, hideCancel = false }) => {
  const { t } = useTranslation();

  const handleClose = () => onClose();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '28px',
          width: '440px',
          py: 2,
        },
      }}
    >
      <DialogTitle sx={{ position: 'relative' }}>
        <Typography variant='h2' fontSize='24px' fontWeight='400'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography fontSize='14px'>{subtitle}</Typography>
      </DialogContent>
      <DialogActions>
        {!hideCancel && <Button onClick={handleClose}>{cancelTitle || t('common.action.cancel')}</Button>}
        <Button
          onClick={() => {
            onSubmit();
            handleClose();
          }}
        >
          {submitTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
