import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import LoginForm from 'pages/shared/landing-page/form/LoginForm';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

type Props = {
  redirect?: string;
};

const ConnectAccountDialog: FC<Props> = ({ redirect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Card sx={{ background: redesignColors.blue.blue1 }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 2, borderRadius: '8px' }}>
            <Typography>{t('bodyTemplate.have_account')}</Typography>
            <Button variant='outlined' onClick={() => setOpen(true)}>
              {t('bodyTemplate.connect_to_account')}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ position: 'relative' }}>
          <Typography variant='h6' sx={{ pt: 2 }}>
            {t('landing_page.login.title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <LoginForm redirectionUrl={redirect} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConnectAccountDialog;
