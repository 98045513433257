import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useLocale } from 'hooks';
import { Directory } from 'models/directory.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgLocation } from 'resources/icons/find-doctor/location.svg';
import { ReactComponent as ExternalLinkIcon } from 'resources/icons/external-link.svg';
import { ReactComponent as SvgWeb } from 'resources/icons/web.svg';
import { ReactComponent as SvgPhone } from 'resources/icons/find-doctor/phone.svg';
import { ReactComponent as SvgOpenMap } from 'resources/icons/open-map.svg';
import DoctenaLogo from 'resources/images/logo_doctena.svg';
import FindDoctorCollapseResultGoogleMap from './FindDoctorCollapseResultGoogleMap';
import { redesignColors } from 'resources/theme/theme.colors';

const FindDoctorCollapseResult: FC<{ directory: Directory }> = ({ directory }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const hasDoctenaAgenda = (): boolean => {
    return !!directory.doctena_agenda_id;
  };
  const googleMapUrl = `https://www.google.com/maps/dir/?api=1&destination=${directory.address.number || ''} ${directory.address.street || ''} ${directory.address.postal_code || ''} ${
    directory.address.city || ''
  } ${directory.address.country || ''}`;

  const externalLink = () => {
    return (
      <SvgIcon
        sx={{
          fontSize: 20,
          color: 'text.secondary',
          filter: 'brightness(1.4)',
          ml: 1,
        }}
        component={ExternalLinkIcon}
      />
    );
  };

  return (
    <Grid container sx={{ p: '16px 32px' }}>
      <Grid item xs={12} md={4} alignSelf='center'>
        <Typography variant='subtitle1' marginBottom={1}>
          {directory.specialities[0].name}
        </Typography>
        <Typography variant='subtitle2' sx={{ pb: 2, color: redesignColors.grey.grey1 }}>
          {directory.practice_name}
        </Typography>
        <Box display='flex' flexDirection='column' alignItems='flex-start' gap={1}>
          <Button
            variant='link'
            startIcon={<SvgLocation />}
            sx={{
              '.MuiButton-startIcon': {
                marginLeft: 0,
              },
              textAlign: 'left',
            }}
            onClick={() => {
              window.open(googleMapUrl, '_blank');
            }}
          >
            {directory.address.number} {directory.address.street ? `${directory.address.street}, ` : ''}
            {directory.address.postal_code} {directory.address.city}
            {directory.address.country ? `, ${directory.address.country}` : ''}
            {externalLink()}
          </Button>
          {directory.phone && (
            <Button
              variant='link'
              startIcon={<SvgPhone />}
              sx={{
                '.MuiButton-startIcon': {
                  marginLeft: 0,
                },
              }}
              target='_blank'
              rel='noreferrer'
              href={`tel:${directory.phone}`}
            >
              {directory.phone}
            </Button>
          )}
          {directory.website && (
            <Button
              variant='link'
              startIcon={<SvgWeb />}
              sx={{
                '.MuiButton-startIcon': {
                  marginLeft: 0,
                },
              }}
              target='_blank'
              rel='noreferrer'
              href={`https://${directory.website}`}
            >
              {directory.website}
              {externalLink()}
            </Button>
          )}
        </Box>
        <Box paddingY={4}>
          <Button
            startIcon={<SvgOpenMap />}
            variant='outlined'
            onClick={() => {
              window.open(googleMapUrl, '_blank');
            }}
          >
            {t('findDoctor.result.action.computeItinerary')}
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <FindDoctorCollapseResultGoogleMap lat={directory.address.latitude!} lng={directory.address.longitude!} />
      </Grid>
      <Grid item xs={12} md={4} display='flex' flexDirection='column' gap={4} sx={{ height: '100%' }}>
        {hasDoctenaAgenda() && (
          <>
            <Box
              component='img'
              alt='Logo doctena'
              src={DoctenaLogo}
              sx={{
                width: '135px',
              }}
            />
            <Typography gutterBottom>{t('findDoctor.result.doctena')}</Typography>
            {directory.public_uri && (
              <ContextLink
                to='DOCTENA_APPOINTMENT'
                params={{
                  public_uri: directory.public_uri,
                  locale: locale,
                  doctorTrad: t('findDoctor.result.action.doctor.urlLang'),
                }}
                external
              >
                <Button variant='contained'>{t('common.action.bookAppointment')}</Button>
              </ContextLink>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FindDoctorCollapseResult;
