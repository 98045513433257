import { Avatar, Badge, Divider, Menu, MenuItem, Stack, SvgIcon, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useIsPatient, useRelatives } from 'hooks';
import { Person, RelationInfo } from 'models/profile.model';
import { ReactComponent as StarIcon } from 'resources/icons/star.svg';
import { ReactComponent as ActiveBlueIcon } from 'resources/icons/active_blue.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { GlobalState } from 'store/reducers';
import { redesignColors } from 'resources/theme/theme.colors';
import UsersList from 'components/_layout/navigation/UsersList';

const HeaderMenu: FC<{
  handleClose: any;
  open: boolean;
  anchorEl: null | HTMLElement;
  handleChangeUser: (user: RelationInfo | Person) => void;
}> = ({ handleClose, open, anchorEl, handleChangeUser }) => {
  const { t } = useTranslation();

  const isPatient = useIsPatient();

  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const iconSx = { color: redesignColors.grey.grey2, mr: 1 };

  return (
    <Menu
      id='profile-menu'
      anchorEl={anchorEl}
      open={open}
      onClick={handleClose}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        sx: { padding: '8px 0 !important' },
      }}
    >
      <Stack direction='column' alignItems='stretch'>
        <UsersList handleChangeUser={handleChangeUser} />

        {isPatient && (
          <>
            <ContextLink to='PROFILE'>
              <MenuItem>
                <PersonIcon sx={iconSx} />
                {t('navigation.header.profile')}
              </MenuItem>
            </ContextLink>

            {!currentUser?.is_relative ? (
              <ContextLink to='PROFILE_RELATIVE'>
                <MenuItem sx={{ padding: '8px 16px 8px 48px !important' }}> {t('navigation.header.relative')}</MenuItem>
              </ContextLink>
            ) : (
              <MenuItem sx={{ padding: '8px 16px 8px 48px !important' }} onClick={authenticateUser && (() => handleChangeUser(authenticateUser))}>
                {t('navigation.header.backToMainAccount')}
              </MenuItem>
            )}
          </>
        )}
        <ContextLink to='LOGOUT'>
          <MenuItem>
            <LogoutIcon sx={iconSx} />
            {t('navigation.header.logout')}
          </MenuItem>
        </ContextLink>
      </Stack>
    </Menu>
  );
};

export default HeaderMenu;
