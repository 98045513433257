import { Address, PatientInfo } from 'store/reducers/newAppointemtnFlow.reducer';

export enum NewAppointmentFlowActionType {
  SET_ADULTS_NUMBER = 'SET_ADULTS_NUMBER',
  SET_CHILDREN_NUMBER = 'SET_CHILDREN_NUMBER',
  SET_PATIENT_INFO = 'SET_PATIENT_INFO',
  REMOVE_PATIENT_INFO = 'REMOVE_PATIENT_INFO',
  SET_ADDRESS = 'SET_ADDRESS',
  SET_SLOT = 'SET_SLOT',
  SET_PRESCRIPTIONS = 'SET_PRESCRIPTIONS',
  CLEAR_NEW_APPOINTMENT_FLOW = 'CLEAR_NEW_APPOINTMENT_FLOW',
}

export type NewAppointmentFlowAction =
  | {
      type: NewAppointmentFlowActionType.SET_ADULTS_NUMBER;
      adultsNumber: number;
    }
  | {
      type: NewAppointmentFlowActionType.SET_CHILDREN_NUMBER;
      childrenNumber: number;
    }
  | {
      type: NewAppointmentFlowActionType.SET_PATIENT_INFO;
      patientInfo: PatientInfo;
    }
  | {
      type: NewAppointmentFlowActionType.REMOVE_PATIENT_INFO;
      id: string | number;
    }
  | {
      type: NewAppointmentFlowActionType.SET_ADDRESS;
      address: Address;
    }
  | {
      type: NewAppointmentFlowActionType.SET_SLOT;
      slot: Date | null;
    }
  | {
      type: NewAppointmentFlowActionType.SET_PRESCRIPTIONS;
      prescriptions: File[];
    }
  | {
      type: NewAppointmentFlowActionType.CLEAR_NEW_APPOINTMENT_FLOW;
    };
