import { FC, useEffect, useMemo, useState } from 'react';
import { Card } from '@mui/material';
import GenericTable, { GenericPaginator } from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import TableContentSkeleton from 'components/table-content-skeleton';
import DesktopResultsTable from './DesktopResultsTable';
import DesktopResultsTableNew from './DesktopResultsTableNew';
import { ResultStatus, resultTaggedEl } from 'models/result.model';

const DesktopResults: FC<{
  results: any[];
  hideRedesign: boolean;
  selectedStatus: ResultStatus;
  title: string | JSX.Element;
  pagination: GenericPaginator;
  isLoading: boolean;
  onPaginationChange: (pagination: GenericPaginator) => void;
}> = ({ results, selectedStatus, pagination, onPaginationChange, isLoading, hideRedesign }) => {
  const [orderBy, setOrderBy] = useState<string>('sample_date');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const resultsRef = useMemo(() => results, [results]);

  const handleSort = (property: string) => {
    let orderL = 'asc';

    return () => {
      setOrder(orderL === 'asc' ? 'desc' : 'asc');
      setOrderBy(property);
      orderL = orderL === 'asc' ? 'desc' : 'asc';
    };
  };

  const tableHeaders: GenericHeader[] = useMemo(
    () =>
      hideRedesign
        ? [
            {
              id: 'prescription_date',
              label: 'common.table.tableHead.prescription_date',
            },
            { id: 'sampleDate', label: 'common.table.tableHead.sample_date', sort: !hideRedesign ? handleSort('sample_date') : undefined },
            { id: 'fileReference', label: 'common.table.tableHead.reference_number' },
            { id: 'patient', label: 'common.table.tableHead.patient' },
            { id: 'dateOfBirth', label: 'common.table.tableHead.birth_date' },
            { id: 'cns', label: 'common.table.tableHead.cns_number' },
            { id: 'issuer', label: 'common.table.tableHead.issuer', sort: !hideRedesign ? handleSort('issuer') : undefined },
            { id: 'progress', label: 'results.list.table.progress' },
            { id: 'timLeft', label: 'results.list.table.time_left' },
            { id: 'actions', label: undefined },
          ]
        : [
            { id: 'sampleDate', label: 'common.table.tableHead.sample_date', sort: !hideRedesign ? handleSort('sample_date') : undefined },
            { id: 'fileReference', label: 'common.table.tableHead.reference_number' },
            { id: 'patient', label: 'common.table.tableHead.patient' },
            { id: 'issuer', label: 'common.table.tableHead.issuer', sort: !hideRedesign ? handleSort('issuer') : undefined },
            { id: 'progress', label: 'results.list.table.progress' },
            { id: 'timLeft', label: 'results.list.table.time_left' },
            { id: 'tag', label: 'results.list.table.tag' },
            { id: 'actions', label: undefined },
          ],
    [hideRedesign, selectedStatus],
  );

  useEffect(() => {
    resultsRef.sort((a: any, b: any) => {
      if (orderBy === 'sample_date') {
        return order === 'asc' ? new Date(a.sampling_time).getTime() - new Date(b.sampling_time).getTime() : new Date(b.sampling_time).getTime() - new Date(a.sampling_time).getTime();
      }

      if (orderBy === 'issuer') {
        return order === 'asc' ? a.issuer?.first_name.localeCompare(b.issuer?.first_name) : b.issuer?.first_name.localeCompare(a.issuer?.first_name);
      }
    });
  }, [orderBy, order, resultsRef]);

  return (
    <Card sx={{ padding: 0 }}>
      <GenericTable headers={tableHeaders} pagination={pagination} setPagination={onPaginationChange}>
        {!isLoading && resultsRef ? (
          resultsRef.length ? (
            resultsRef.map((result: resultTaggedEl, key: number) =>
              hideRedesign ? <DesktopResultsTable result={result as any} key={key} /> : <DesktopResultsTableNew result={result as any} key={key} />,
            )
          ) : (
            <NoResultsTableRow colSpan={tableHeaders.length} noResultText={'common.noResult'} />
          )
        ) : (
          <TableContentSkeleton columns={tableHeaders.length} rows={4} />
        )}
      </GenericTable>
    </Card>
  );
};

export default DesktopResults;
