import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, DialogTitle, Grid, IconButton, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { PasswordUpdateProps } from 'models/profile.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PasswordUpdate: FC<{ closeModal: () => void }> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<PasswordUpdateProps>();
  const [showPassword, toggleShowPassword] = useState<boolean>(false);
  const [confirmShowPassword, toggleConfirmShowPassword] = useState<boolean>(false);
  const { notification } = useNotification();

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const handleClickConfirmShowPassword = () => {
    toggleConfirmShowPassword(!confirmShowPassword);
  };

  const onSubmit: SubmitHandler<PasswordUpdateProps> = useCallback(
    (data) => {
      api.users
        .updatePassword(data)
        .then((response) => {
          closeModal();
          notification(t('profile.updatePassword.notification.success'), 'success');
        })
        .catch((e) => {
          notification(t('profile.updatePassword.notification.error'), 'error');
        });
    },
    [closeModal, notification, t],
  );

  return (
    <>
      <DialogTitle sx={{ p: 0, pb: 1 }}>{t('profile.update.actions.modify.password')}</DialogTitle>
      <Typography pb={1} variant='body2'>
        {t('profile.update.actions.confirm.password')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0} flexDirection='column'>
          <Grid item xs={12} py={1}>
            <TextField
              variant='filled'
              {...register('password')}
              type={showPassword ? 'text' : 'password'}
              label={t('profile.updatePassword.form.newPassword')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClickShowPassword} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
          <Grid item xs={12} py={1}>
            <TextField
              variant='filled'
              {...register('password_confirm')}
              type={confirmShowPassword ? 'text' : 'password'}
              label={t('profile.updatePassword.form.confirmNewPassword')}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClickConfirmShowPassword} edge='end'>
                      {confirmShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
          </Grid>
          <Grid item xs={12} mt={2} textAlign='right'>
            <Button onClick={closeModal} sx={{ mr: 1 }}>
              {t('common.action.cancel')}
            </Button>
            <Button type='submit'> {t('profile.update.actions.save.password')}</Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PasswordUpdate;
