import { Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import LoginBtn from './components/LoginBtn';
import LoginDialog from './components/LoginDialog';
import CustomTextField from './components/CustomTextField';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AdultIcon } from 'resources/icons/bionext-person.svg';
import { ReactComponent as ChildIcon } from 'resources/icons/bionext-child.svg';
import { APPOINTMENT_STORAGE, AppointmentStorage } from 'components/appointment/types/Storage';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';

interface IntroductionStepProps {
  setNextButtonActive: (state: boolean) => void;
}

const IntroductionStep: FC<IntroductionStepProps> = ({ setNextButtonActive }) => {
  const { t } = useTranslation();
  const { getState, dispatch } = useStore();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [adultsNumber, setAdultsNumber] = useState<number | null>(0);
  const [childrenNumber, setChildrenNumber] = useState<number | null>(0);

  const handleDialogClose = () => {
    setLoginDialogOpen(false);
  };

  useEffect(() => {
    setNextButtonActive(false);
  }, [setNextButtonActive]);

  useEffect(() => {
    if (adultsNumber || childrenNumber) {
      setNextButtonActive(true);
    }

    if (adultsNumber === 0 && childrenNumber === 0) {
      setNextButtonActive(false);
    }
  }, [adultsNumber, childrenNumber, setNextButtonActive]);

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;

    if (storage.adultsNumber) {
      setAdultsNumber(storage.adultsNumber);
    }

    if (storage.childrenNumber) {
      setChildrenNumber(storage.childrenNumber);
    }
  }, [setAdultsNumber, setChildrenNumber, getState]);

  useEffect(() => {
    dispatch({
      type: NewAppointmentFlowActionType.SET_ADULTS_NUMBER,
      adultsNumber: adultsNumber || 0,
    });
  }, [adultsNumber, dispatch]);

  useEffect(() => {
    dispatch({
      type: NewAppointmentFlowActionType.SET_CHILDREN_NUMBER,
      childrenNumber: childrenNumber || 0,
    });
  }, [childrenNumber, dispatch]);

  return (
    <Stack spacing={3}>
      <Stack spacing={2} sx={{ backgroundColor: '#D9F5FF', padding: '16px 24px 16px 24px', borderRadius: '8px' }}>
        <LoginBtn setLoginDialogOpen={setLoginDialogOpen} />
        <LoginDialog handleClose={handleDialogClose} isOpen={loginDialogOpen} />
      </Stack>

      <CustomTextField
        labelComponent={() => (
          <Stack direction='row' spacing={1} alignItems='start' sx={{ mb: '8px' }}>
            <AdultIcon />
            <Stack direction='column' spacing={0}>
              <Typography sx={{ fontWeight: 600 }} variant='body1'>
                {t('new_appointment.stepper.steps.introduction.adults')}
              </Typography>
              <Typography variant='body2'>{t('new_appointment.stepper.steps.introduction.adults_text')}</Typography>
            </Stack>
          </Stack>
        )}
        value={adultsNumber}
        setValue={setAdultsNumber}
      />
      <CustomTextField
        labelComponent={() => (
          <Stack direction='row' spacing={1} alignItems='start' sx={{ mb: '8px' }}>
            <ChildIcon />
            <Stack direction='column' spacing={0}>
              <Typography sx={{ fontWeight: 600 }} variant='body1'>
                {t('new_appointment.stepper.steps.introduction.child')}
              </Typography>
              <Typography variant='body2'>{t('new_appointment.stepper.steps.introduction.child_text')}</Typography>
            </Stack>
          </Stack>
        )}
        value={childrenNumber}
        setValue={setChildrenNumber}
      />
    </Stack>
  );
};

export default IntroductionStep;
