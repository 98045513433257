import { LoadingButton } from '@mui/lab';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconActive } from 'resources/icons/active.svg';
import { redesignColors } from 'resources/theme/theme.colors';

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => any;
};

const ConfirmDialog: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { xs: '300px', lg: '440px' },
          maxWidth: 'fit-content',
          borderRadius: '28px',
          p: 3,
        },
      }}
    >
      <IconActive style={{ marginBottom: 2 }} />
      <DialogTitle sx={{ position: 'relative', px: 1 }}>
        <Box component='h2' sx={{ fontSize: '24px', fontWeight: '500', mb: 2 }}>
          {t('feedback.confirm_dialog.title')}
        </Box>
        <Box sx={{ fontSize: '16px', fontWeight: '400', color: redesignColors.grey.grey1, mb: 3 }}>{t('feedback.confirm_dialog.description')}</Box>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ display: 'grid', gridTemplateRows: 'auto', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton variant='text' onClick={() => setOpen(false)}>
              {t('feedback.confirm_dialog.close')}
            </LoadingButton>
            {/* <LoadingButton type='submit' variant='text' fullWidth={isMobileView} loading={submitting}>
                {t('feedback.confirm_dialog.app_store_submit')}
              </LoadingButton> */}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default ConfirmDialog;
