import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

interface NoResultsTableRowProps {
  noResultText?: string;
  colSpan?: number;
}

const NoResultsTableRow: FC<NoResultsTableRowProps> = ({ noResultText = 'utils.table.no_results', colSpan = 25 }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}>
        <Typography variant='body1' align='center' fontWeight={500}>
          {t(noResultText)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default NoResultsTableRow;
