import { Grid, Typography } from '@mui/material';
import { Address } from 'models/profile.model';
import { FC } from 'react';
import { addressToString, isAddress } from 'utils/func/Address.func';
import { useTranslation } from 'react-i18next';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';

type arrayPerson = {
  key: string;
  value: number;
};

function formatCnsString(input: string) {
  let digits = input.replace(/\D/g, '');

  let groups = [4, 4, 3, 2];
  let formatted = '';
  let index = 0;

  for (let size of groups) {
    if (index >= digits.length) break;

    if (formatted.length > 0) formatted += ' ';
    formatted += digits.slice(index, index + size);
    index += size;
  }

  return formatted;
}
const ProfileData: FC<any> = ({ person }) => {
  const { t } = useTranslation();
  function getProfileData(person: any): arrayPerson[] {
    return [
      {
        key: t('profile.data.birth_name'),
        value: person.birth_name,
      },
      {
        key: t('profile.data.gender'),
        value: person.gender === 'm' ? t('profile.data.male') : t('profile.data.female'),
      },
      {
        key: t('profile.data.birth_date'),
        value: convertDate(person.birth_date, false, 'yyyy-MM-dd'),
      },
      {
        key: t('profile.data.cns'),
        value: person.cns && formatCnsString(person.cns),
      },
      {
        key: t('profile.data.phone'),
        value: person.phone,
      },
      {
        key: t('profile.data.mobile_phone'),
        value: person.mobile,
      },
      {
        key: t('profile.data.fax'),
        value: person.fax,
      },
      {
        key: t('profile.data.email'),
        value: person.email,
      },
      {
        key: t('profile.data.personal_address'),
        value: addressToString(person.addresses.home),
      },
      {
        key: t('profile.data.work_address'),
        value: addressToString(person.addresses.work),
      },
    ];
  }

  /**
   * @desc display data according to its type
   * @param value the data to display
   * @returns the displayed data
   */
  function displayData(value: string | number | Address | undefined): string | React.ReactNode {
    if (isAddress(value)) {
      return addressToString(value);
    } else {
      return !!value ? `${value}` : '-';
    }
  }

  return (
    <Grid container spacing={1}>
      {getProfileData(person).map((data, key) => {
        return (
          <Grid container columnSpacing={1} spacing={0} mb={1} item key={data.key}>
            <Grid item xs={12} sm={6}>
              <Typography variant='body2' color={redesignColors.grey.grey1}>
                {data.key}:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='body2' fontWeight='500'>
                {displayData(data.value)}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProfileData;
