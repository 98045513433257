import { Card, CardActionArea, CardContent, Grid } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import CardHomepageServices from 'components/homepage-service';
import ContextLink from 'components/_base/ContextLink';
import { useWhatsNew } from 'hooks';
import { FC, useState } from 'react';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctor.svg';
import MyDSPLogo from 'resources/images/logo_mydsp.svg';
import { ReactComponent as CatalogueAnalysesIcon } from 'resources/icons/catalogue_analyses.svg';
import { ReactComponent as LabomobileIcon } from 'resources/icons/collection_service.svg';
import { ReactComponent as PreregistrationIcon } from 'resources/icons/preregister.svg';
import { ReactComponent as CalendarIcon } from 'resources/icons/calendar.svg';
import HomepageAppointments from './components/HomepageAppointments';
import HomepagePreregistrations from './components/HomepagePreregistrations';
import HomepageInvoices from './components/HomepageInvoices';
import HomepageResults from './components/HomepageResults';
import { useTranslation } from 'react-i18next';
import HomepageWhatsNew from './components/HomepageWhatsNew';
import { CONST_WHATSNEW_FLAG } from 'utils/Constants';

const Home: FC = () => {
  const { t } = useTranslation();
  const whatsnew = useWhatsNew();
  const [showWhatsNew, setShowWhatsNew] = useState(whatsnew);

  const closeWhatsNew = () => {
    localStorage.setItem(CONST_WHATSNEW_FLAG, '1');
    setShowWhatsNew('1');
  };

  return (
    <Grid container spacing={0} sx={{ flexWrap: 'nowrap', flexGrow: 1 }}>
      <BodyTemplate title={t('bodyTemplate.dashboard')} hideHeaderOnMobile>
        <Grid container spacing={3}>
          {!showWhatsNew && (
            <Grid item xs={12}>
              <HomepageWhatsNew close={closeWhatsNew} />
            </Grid>
          )}
          <Grid item xs={12} sm={6} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='PREREGISTRATION' sx={{ p: 0, width: '100%', '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardContent sx={{ display: 'flex' }}>
                  <CardHomepageServices logo={PreregistrationIcon} title={t('homepage.preRegistration.title')} description={t('homepage.preRegistration.body')} isLargeScreen bigFont />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='BOOK_APPOINTMENT' sx={{ width: '100%', p: 0, '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardContent sx={{ display: 'flex' }}>
                  <CardHomepageServices logo={CalendarIcon} title={t('landing_page.card.appointment.title')} description={t('landing_page.card.appointment.subtitle')} isLargeScreen bigFont />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} display='flex' alignItems='stretch'>
            <HomepagePreregistrations />
          </Grid>
          <Grid item xs={12} md={6} display='flex' alignItems='stretch'>
            <HomepageAppointments />
          </Grid>
          <Grid item xs={12} md={6} display='flex' alignItems='stretch'>
            <HomepageResults />
          </Grid>
          <Grid item xs={12} md={6} display='flex' alignItems='stretch'>
            <HomepageInvoices />
          </Grid>

          <Grid item xs={12} sm={4} md={3} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='CENTERS' sx={{ width: '100%', p: 0, '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardHomepageServices logo={LabomobileIcon} title={t('homepage.ourCenters.title')} bigFont />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='ANALYSIS_CATALOG' sx={{ p: 0, width: '100%', '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardContent sx={{ display: 'flex' }}>
                  <CardHomepageServices logo={CatalogueAnalysesIcon} title={t('homepage.catalog.title')} description='' isLargeScreen bigFont />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='MY_DSP' sx={{ width: '100%', p: 0, '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardHomepageServices image={MyDSPLogo} title={t('homepage.myDSP.title')} bigFont />
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={3} display='flex' alignItems='stretch'>
            <Card component={ContextLink} to='FIND_DOCTOR' sx={{ width: '100%', p: 0, '> button': { height: '100%' } }}>
              <CardActionArea sx={{ py: 4, px: 3 }}>
                <CardHomepageServices logo={DoctorIcon} title={t('homepage.findDoctor.title')} bigFont />
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </BodyTemplate>
    </Grid>
  );
};

export default Home;
