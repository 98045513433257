import theme_color from 'resources/theme/theme.colors';

import { createTheme } from '@mui/material';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: '28px',
            backgroundColor: theme_color.palette.background.paper,
            p: 3,
          },
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          fontWeight: 500,
        },
      },
    },
  },
});

export default theme;
