import { Stepper, Step as MuiStep, StepLabel, Stack, styled, StepConnector, stepConnectorClasses, stepLabelClasses, Breadcrumbs } from '@mui/material';
import React, { createElement, FC, ReactElement, useState } from 'react';
import ContextLink from 'components/_base/ContextLink';
import StepLayout from './StepLayout';
import { useTranslation } from 'react-i18next';
import type { Step } from './types/Step';
import { useIsMobileView } from 'hooks';

interface StepperProps {
  steps: Step[];
  showBreadcrumb?: boolean;
  breadcrumbs: ReactElement[];
}

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
}));

const CustomStepperConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    top: 11,
  },
}));

const CustomStepper: FC<StepperProps> = ({ steps, showBreadcrumb, breadcrumbs }) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [nextButtonActive, setNextButtonActive] = useState(true);

  const isMobileView = useIsMobileView();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const isFirstStep = () => {
    return activeStep === 0;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step: Step, i: number) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNextButtonActive(true);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   setCompleted({
  //     ...completed,
  //     [activeStep]: true,
  //   });
  //   handleNext();
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  const Render = steps[activeStep].component;

  const renderProps = {
    setNextButtonActive,
    handleBack,
    ...steps[activeStep].props,
  };

  return (
    <Stack sx={{ padding: '16px 24px 16px', width: '100%', display: 'flex', justifyContent: 'center' }} spacing={8}>
      {!steps[activeStep].hideStepper && (
        <Stepper
          activeStep={activeStep}
          sx={{
            alignItems: 'flex-start',
            width: {
              xs: '100%',
              sm: '100%',
              md: '95%',
            },
            mb: 4,
            '.MuiStepLabel-labelContainer span': {
              fontSize: '16px',
              transform: 'translateX(40%)',
            },
            '.MuiStepLabel-labelContainer .Mui-active': {
              fontWeight: 700,
              transform: 'translateX(40%)',
            },
            '.MuiStep-root': {
              paddingLeft: 0,
              paddingRight: 0,
            },
          }}
          connector={<CustomStepperConnector />}
        >
          {steps.map((step, index) => {
            if (!step.hideStepper) {
              return (
                <MuiStep key={index} completed={completed[index]}>
                  <StyledStepLabel>{isMobileView ? ' ' : step.label}</StyledStepLabel>
                </MuiStep>
              );
            }

            return null;
          })}
        </Stepper>
      )}

      <Stack sx={{ width: '100%' }} spacing={2}>
        {' '}
        {/** TODO: Refactor breadcrumbs to dynamic rendering from the props */}
        {showBreadcrumb && <Breadcrumbs aria-label='breadcrumbs'>{breadcrumbs.map((bc) => bc)}</Breadcrumbs>}
        {Render &&
          (steps[activeStep].wrapStep ? (
            <StepLayout
              activeNextButton={nextButtonActive}
              setActiveStep={handleStep(activeStep + 1)}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              handleBack={handleBack}
              component={<Render {...renderProps} />}
            />
          ) : (
            <Render {...renderProps} />
          ))}
      </Stack>
    </Stack>
  );
};

export default CustomStepper;
