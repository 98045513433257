import { Avatar, Badge, Divider, MenuItem, SvgIcon, Typography } from '@mui/material';
import { useIsMobileView, useIsPatient, useRelatives } from 'hooks';
import { Person, RelationInfo } from 'models/profile.model';
import { FC } from 'react';
import { ReactComponent as StarIcon } from 'resources/icons/star.svg';
import { ReactComponent as ActiveBlueIcon } from 'resources/icons/active_blue.svg';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { getFullName, getInitials } from 'utils/func/Person.func';
import { redesignColors } from 'resources/theme/theme.colors';

const UsersList: FC<{
  handleChangeUser: (user: RelationInfo | Person) => void;
}> = ({ handleChangeUser }) => {
  const isMobileView = useIsMobileView();

  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const isPatient = useIsPatient();

  const relatives = useRelatives()?.grantees;

  return (
    <>
      {isPatient && (
        <>
          {authenticateUser && (
            <UserMenuItem isAuthenticateUser person={authenticateUser} onClick={() => handleChangeUser(authenticateUser)} isCurrentUser={currentUser?.id === authenticateUser?.patient_id} />
          )}
          {relatives?.length &&
            authenticateUser &&
            relatives?.map(({ grantor }) => <UserMenuItem person={grantor} onClick={() => handleChangeUser(grantor)} isCurrentUser={currentUser?.id === grantor.id} />)}
          <Divider sx={{ my: isMobileView ? 1 : 0.5, width: isMobileView ? '90%' : '100%', margin: '0 auto' }} />
        </>
      )}
    </>
  );
};

const UserMenuItem = ({ person, onClick, isCurrentUser, isAuthenticateUser }: { person: RelationInfo | Person; onClick: () => void; isCurrentUser?: boolean; isAuthenticateUser?: boolean }) => (
  <MenuItem onClick={onClick} disableRipple>
    {isCurrentUser ? (
      <Badge
        overlap='circular'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={<SvgIcon component={ActiveBlueIcon} inheritViewBox sx={{ fontSize: 16, mr: 0.25, color: redesignColors.blue.main }} />}
      >
        <Avatar
          sx={{
            fontSize: '18px',
            mr: 1,
          }}
        >
          {getInitials(person)}
        </Avatar>
      </Badge>
    ) : (
      <Avatar
        sx={{
          fontSize: '18px',
          mr: 1,
        }}
      >
        {getInitials(person)}
      </Avatar>
    )}

    {isAuthenticateUser && <SvgIcon component={StarIcon} inheritViewBox sx={{ fontSize: 16, mr: 0.25, color: redesignColors.blue.main }} />}
    <Typography variant='body1' fontWeight='500'>
      {getFullName(person)}
    </Typography>
  </MenuItem>
);
export default UsersList;
