import { Button, Stack, styled } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface StepLayoutProps {
  setActiveStep: () => void;
  handleBack: () => void;
  isLastStep: () => boolean;
  isFirstStep: () => boolean;
  activeNextButton: boolean;
  component: ReactElement;
}

const stackStyles = { width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 0, justifyContent: 'space-between', gap: '50px' };

const buttonStyles = {
  mt: 0,
  border: '1px solid #7A9099',
  borderRadius: '8px',
  padding: '12px 32px 12px 32px',
};

const StyledButton = styled(Button)(({ theme, variant }) => ({
  ...buttonStyles,
  border: variant === 'outlined' ? 'solid 1px black' : 'none',
  width: '20%',
  color: 'black',
  backgroundColor: variant === 'contained' ? theme.palette.primary.main : 'none',
  '&:hover': {
    color: 'black',
    backgroundColor: theme.palette.primary.dark,
  },
}));

const StepLayout: FC<StepLayoutProps> = ({ setActiveStep, handleBack, isLastStep, isFirstStep, activeNextButton, component }) => {
  const { t } = useTranslation();
  const isLast = isLastStep();
  const isFirst = isFirstStep();

  return (
    <Stack sx={{ ...stackStyles, justifyContent: 'space-between', minHeight: '500px', width: '100%' }}>
      {component}

      <Stack
        sx={{
          ...stackStyles,
          justifyContent: 'space-between',
          flexDirection: !isFirst ? 'row' : 'row-reverse',
          width: {
            xs: '100%',
            sm: '70%',
          },
        }}
      >
        {!isFirst && (
          <StyledButton sx={{ width: 'fit-content' }} variant='outlined' onClick={() => handleBack()}>
            {t('checkin.stepper.button.previous_step')}
          </StyledButton>
        )}

        {!isLast && (
          <StyledButton variant='contained' onClick={() => setActiveStep()} disabled={!activeNextButton}>
            {t('checkin.stepper.button.next')}
          </StyledButton>
        )}
      </Stack>
    </Stack>
  );
};

export default StepLayout;
