import api from 'api';
import { useAuthenticated } from 'hooks';
import { FavoriteLab } from 'pages/patient/centers/types';
import { useEffect, useState } from 'react';

const getFavoriteLabs = () => api.bnl.getFavoriteLabs();
const useFavorites = (): [FavoriteLab[], (value: string) => Promise<void>, (value: string) => Promise<void>] => {
  const [favorites, setFavorites] = useState<FavoriteLab[]>([]);
  const isAuthenticated = useAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    getFavoriteLabs().then((res) => setFavorites(res));
  }, [isAuthenticated]);

  const addFavorite = async (labId: string) => {
    await api.bnl.addFavoriteLab({ lab_id: labId });
    const labs = await getFavoriteLabs();
    setFavorites(labs);
  };

  const deleteFavorite = async (labId: string) => {
    await api.bnl.deleteFavoriteLab({ lab_id: labId });
    const labs = await getFavoriteLabs();
    setFavorites(labs);
  };

  return [favorites, addFavorite, deleteFavorite];
};

export default useFavorites;
