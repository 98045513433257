import { Cluster } from '@googlemaps/markerclusterer';
import { redesignColors } from 'resources/theme/theme.colors';

const createClusterIcon = (count: number) => {
  const size = Math.min(40 + count * 2, 80);

  return `
        <svg width="${size}" height="${size}" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" fill="${redesignColors.blue.dark}"/>
          <text x="50%" y="50%" text-anchor="middle" dy=".3em" fill="white"  font-family="Montserrat,Verdana" font-size="12" font-weight="bold">${count}</text>
        </svg>
      `;
};
const customClusterRenderer = (map: google.maps.Map) => ({
  render: ({ count, position }: Cluster) => {
    return new google.maps.Marker({
      position,
      map,
      icon: {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(createClusterIcon(count))}`,
        scaledSize: new google.maps.Size(40, 40),
      },
    });
  },
});

export default customClusterRenderer;
