import { Stack } from "@mui/material";
import Summary from "components/summary";
import dayjs from "dayjs";
import { FC } from "react";
import { useStore } from "react-redux";
import { AppointmentFlowState } from "store/reducers/newAppointemtnFlow.reducer";

const SummaryStep: FC = () => {
   const {getState} = useStore();
   const state : AppointmentFlowState = getState().newAppointmentFlowReducer;


   return (
      <Stack spacing={2}>
         <Summary
            summaryFields={{
               labTitle: state.address.address,
               labAddress: state.address.additionalInfo,
               procedure: 'Sampling',
               duration: '30 minutes',
               date: dayjs(state.slot).toDate(),
               timeSlot: dayjs(state.slot).format('HH:mm'),
               prescriptions: state.patients.map(patient => ({
                  pacient: `${patient.firstName} ${patient.lastName}`,
                  doctor: '',
                  file: state.prescriptions ? state.prescriptions.filter(p => p.patientId === patient.id)[0]?.files.map(f => f.name).join(', ') : '',
                  validUntilDate: new Date(),
               })) || [],
            }}
         />
      </Stack>
   )
};

export default SummaryStep;