import { Dialog, DialogContent, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useCurrentUser, useNotification } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { redesignColors } from 'resources/theme/theme.colors';
import CloseIcon from '@mui/icons-material/Close';

const QrCodeModal: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const currentUser = useCurrentUser();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          position: 'relative',
          borderRadius: '16px',
          width: '312px',
          py: 2,
          background: theme.palette.common.white,
        },
      }}
      open
      onClose={onClose}
    >
      <IconButton sx={{ position: 'absolute', right: 8, top: 8 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant='h3' sx={{ fontSize: '20px', fontWeight: '500' }} mt={2} mb={4} textAlign='center'>
          {currentUser?.first_name} {currentUser?.last_name}
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <QRCode value={currentUser?.id?.toString() || ''} size={264} />

            <Typography sx={{ ml: 0.5, color: redesignColors.blue.dark, fontWeight: '500' }} mt={2} textAlign='center'>
              {t('navigation.header.bionext_card')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default QrCodeModal;
