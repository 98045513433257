import MyLocationIcon from '@mui/icons-material/MyLocation';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, Button, Card, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, IconButton, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useAuthenticated, useContextRedirection, useIsMobileView, useLocale, useNotification } from 'hooks';
import { Center, CenterIncludedFile, CenterIncludedService, CenterIncludedUpdate, CentersFormValues, CityChoice, LaboratoireTypeEnum, ScheduleChoice, SpecialityChoice } from 'models/centers.model';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { ReactComponent as SortIcon } from 'resources/icons/sort.svg';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import ClearIcon from '@mui/icons-material/Clear';
import CenterDetails from './components/CenterDetails';
import CentersList from './components/CentersList';
import MapCenters from './components/MapCenters';
import { redesignColors } from 'resources/theme/theme.colors';
import FilterDrawer from './components/FilterDrawer';
import { ReactComponent as IconSearch } from 'resources/icons/search.svg';
import { ReactComponent as NotificationsIcon } from 'resources/icons/notifications.svg';
import { defaultValues, distanceFilter, scheduleFilter, sortFromFarToNear as sortFromNearToFar } from './utils/filters';
import { CenterInfo, Position } from './types';
import RoutePaths from 'utils/RoutePaths';
import ConfirmDialog from 'components/confirm-dialog';
import useFavorites from 'hooks/useFavorites';
import { useSearchParams } from 'react-router-dom';
import useLastCheckLabUpdateDate from './useLastCheckUpdateDate';
import { Holiday } from 'models/holiday.model';

const ScheduleChoices: ScheduleChoice[] = [
  { value: 'MOR', label: 'Matin' },
  { value: 'AFT', label: 'Après-midi' },
  { value: 'SAT', label: 'Samedi' },
];

const Centers: FC = () => {
  const { lastCheckLabUpdateDate } = useLastCheckLabUpdateDate();
  const isAuthenticated = useAuthenticated();
  const [searchParams] = useSearchParams();
  const [favorites, addFavoriteLab, deleteFavoriteLab] = useFavorites();
  const [open, setOpen] = useState(false);
  const [isOnlyFavorites, setIsOnlyFavorites] = useState(false);
  const isMobileView = useIsMobileView();
  const navigate = useContextRedirection();

  //  Translations
  const { t } = useTranslation();
  const locale = useLocale();

  // User's current position
  const [myPosition, setMyPosition] = useState<Position | undefined>(undefined);

  // Form
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isLoadingGeolocation, setLoadingGeolocation] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fromNearToFar, setFromNearToFar] = useState(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isHolidaysLoading, setIsHolidaysLoading] = useState<boolean>(true);

  const [cities, setCities] = useState<CityChoice[]>([]);
  const [filterData, setFilterData] = useState<CentersFormValues | null>(null);
  const [specialities, setSpecialities] = useState<SpecialityChoice[]>([]);
  const [schedules, setSchedules] = useState<ScheduleChoice[]>([]);
  const { notification } = useNotification();
  // Data to display
  const [centersInfo, setCentersInfo] = useState<CenterInfo[]>([]);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const [markersState, setMarkersState] = useState<boolean[]>(centersInfo.map((position) => false));

  const [selectedCenterInfo, setSelectedCenterInfo] = useState<CenterInfo>();

  const centerDetailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedCenterInfo || !centersInfo.length) {
      return;
    }
    const centerId = searchParams.get('centerId');
    const centerInfo = centersInfo.find((info) => info.center.id === centerId);
    if (!centerInfo) {
      return;
    }
    setSelectedCenterInfo(centerInfo);
  }, [searchParams, centersInfo]);

  // Init data and form options
  useEffect(() => {
    setSchedules(ScheduleChoices);
    api.bnl
      .searchCenters({ lang: locale })
      .then(async (val) => {
        setCentersInfo(val);
        setCities(
          Array.from(new Set(val.map((info) => info.center.attributes.field_prelevement_ville)))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSpecialities(
          Array.from(new Set(val.map((info) => info.services.map((service) => service.attributes.name)).flat()))
            .sort((a, b) => a.localeCompare(b))
            .map((info) => ({ value: info, label: info })),
        );
        setSubmitting(false);
      })
      .catch(() => notification(t('centers.notification.error'), 'error'));
  }, [locale, notification, t]);

  useEffect(() => {
    api.holidays
      .getHolidays()
      .then(async (val) => {
        setHolidays(val);
      })
      .catch(() => notification(t('centers.notification.error'), 'error'))
      .finally(() => setIsHolidaysLoading(false));
  }, [locale, notification, t]);

  useEffect(() => {
    const data = filterData || defaultValues;
    const { labomobile, laboratoire, pharmacy } = data;
    if (!isSearching && searchValue) {
      return;
    }
    setSubmitting(true);
    const laboratoireTypes = [labomobile ? LaboratoireTypeEnum.labomobile : null, laboratoire ? LaboratoireTypeEnum.laboratoire : null, pharmacy ? LaboratoireTypeEnum.pharmacy : null].filter(
      Boolean,
    ) as string[];
    api.bnl
      .searchCenters({
        lang: locale,
        city: data.city,
        specialities: data.specialities,
        title: isSearching && searchValue.length ? searchValue : '',
        PMR: data.PRMAccessRequired,
        parking: data.publicParkingRequired,
        laboratoireTypes,
      })
      .then(async (val) => {
        const specials = data?.specialities?.filter((spec) => spec !== 'all');
        const favoritesIds = favorites.map((lab) => lab.lab_id);
        const filterFavorites = (info: any) => (isOnlyFavorites && favoritesIds?.length ? favoritesIds?.includes(info?.center?.id) : true);
        const filterSpecials = (info: any) => (specials?.length ? info?.services?.some((service: CenterIncludedService) => specials.includes(service?.attributes?.name || '')) : true);
        const filteredVal = val.filter((info) => {
          return distanceFilter(info, data.distance, myPosition) && scheduleFilter(info, data.schedule, data.day, data.time, holidays) && filterFavorites(info) && filterSpecials(info);
        });
        const updatedValues = myPosition ? sortFromNearToFar(filteredVal, myPosition, !fromNearToFar) : filteredVal;

        setCentersInfo(updatedValues);
        setSubmitting(false);
        setIsSearching(false);
      });
  }, [filterData, holidays, locale, fromNearToFar, myPosition, searchValue, isSearching, favorites, isOnlyFavorites]);

  const isNewLabUpdatesExisted = useMemo(() => {
    const items = centersInfo.reduce((acc, centerInfo) => [...acc, ...(centerInfo?.updates?.length ? centerInfo?.updates : [])], [] as CenterIncludedUpdate[]);
    const sortedItems = items.sort((first, second) => new Date(second.attributes.created).getTime() - new Date(first.attributes.created).getTime());
    const firstItemCreated = sortedItems[0]?.attributes?.created;
    if (!firstItemCreated && !lastCheckLabUpdateDate) {
      return false;
    }
    if (firstItemCreated && !lastCheckLabUpdateDate) {
      return true;
    }

    return lastCheckLabUpdateDate && firstItemCreated && new Date(firstItemCreated).getTime() > new Date(lastCheckLabUpdateDate).getTime();
  }, [centersInfo, lastCheckLabUpdateDate]);
  // Update markers on data change
  useEffect(() => {
    setMarkersState(centersInfo.map((position) => false));
  }, [centersInfo]);

  useEffect(() => {
    if (selectedCenterInfo && !isMobileView && centerDetailsRef.current) {
      centerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [centerDetailsRef, isMobileView, selectedCenterInfo]);

  const getCurrentPosition = useCallback(() => {
    setLoadingGeolocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMyPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setLoadingGeolocation(false);
        },
        (positionError) => {
          notification(t('centers.notification.errorGeolocation'), 'error');
          setLoadingGeolocation(false);
        },
      );
    } else {
      notification(t('centers.notification.geolocationNotActivated'), 'error');
      setLoadingGeolocation(false);
    }
  }, [notification, t]);

  const handleOpenMapMarker = (index: number) => {
    const newMarkers = Array(markersState.length).fill(false);
    newMarkers[index] = true;
    setMarkersState(newMarkers);
    setSelectedCenterInfo(centersInfo[index]);
  };
  const handleCloseMapMarker = () => {
    const newMarkers = Array(markersState.length).fill(false);
    setMarkersState(newMarkers);
  };
  const handleSearch = () => {
    setSubmitting(true);
    setIsSearching(true);
  };

  return (
    <BodyTemplate
      title={t('bodyTemplate.centers')}
      breadcrumbs={
        !isAuthenticated
          ? [
              <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths['LANDING_PAGE'])}>
                {t('bodyTemplate.home')}
              </Typography>,
              <Typography sx={{ color: redesignColors.grey.grey2 }}>{t('bodyTemplate.centers')}</Typography>,
            ]
          : undefined
      }
    >
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign='right' display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'space-between' }}>
              <Box display='flex' alignItems='center' width='100%'>
                <TextField
                  value={searchValue}
                  variant='filled'
                  onChange={(e) => setSearchValue(e?.target?.value || '')}
                  label={`${t('common.action.search')}`}
                  sx={{ maxWidth: '526px', width: '60%' }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && searchValue.length > 0) {
                      event.preventDefault();

                      handleSearch();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SvgIcon component={IconSearch} />
                      </InputAdornment>
                    ),
                    endAdornment: searchValue.length > 0 && (
                      <IconButton
                        onClick={() => {
                          setSearchValue('');
                          handleSearch();
                        }}
                      >
                        <ClearIcon sx={{ fill: `${redesignColors.red} !important` }} />
                      </IconButton>
                    ),
                  }}
                />
                {searchValue.length > 0 && (
                  <LoadingButton
                    variant='contained'
                    type='submit'
                    loading={isSubmitting}
                    onClick={() => {
                      setSubmitting(true);
                      setIsSearching(true);
                    }}
                    sx={{ ml: 2, width: '120px' }}
                  >
                    {t('common.action.search')}
                  </LoadingButton>
                )}
              </Box>
              <Button
                variant='outlined'
                startIcon={isNewLabUpdatesExisted && <NotificationsIcon />}
                sx={{ minWidth: '212px' }}
                onClick={() => {
                  if (isNewLabUpdatesExisted) {
                    setOpen(true);
                    return;
                  }

                  navigate(RoutePaths.CENTERS_UPDATE);
                }}
              >
                {filterData && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
                {t('centers.lab_updates')}
              </Button>
            </Grid>
            <Grid item xs={12} display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'space-between' }}>
              <Box pt={2}>
                {!isMobileView && (
                  <Button
                    variant='outlined'
                    sx={{ border: `1px solid ${redesignColors.grey.grey4}`, width: 80, fontSize: '14px', position: 'relative' }}
                    startIcon={<FilterAltIcon sx={{ color: redesignColors.blue.main }} />}
                    onClick={() => setOpenDrawer(true)}
                  >
                    {filterData && <div style={{ position: 'absolute', top: -5, right: -5, width: '14px', height: '14px', background: redesignColors.red, borderRadius: '50%' }} />}
                    {t('centers.filter')}
                  </Button>
                )}
                {!!myPosition && !isMobileView && (
                  <Button
                    variant='outlined'
                    sx={{ border: `1px solid ${redesignColors.grey.grey4}`, ml: 2, width: 165, px: 1, fontSize: '14px' }}
                    startIcon={<SvgIcon component={SortIcon} sx={{ color: redesignColors.blue.main }} />}
                    onClick={() => setFromNearToFar((prev) => !prev)}
                  >
                    {fromNearToFar ? t('centers.near_to_far') : t('centers.far_to_near')}
                  </Button>
                )}
                {isAuthenticated && (
                  <FormControlLabel
                    sx={{ ml: 3 }}
                    control={<Checkbox checked={isOnlyFavorites} sx={{ mr: 0.5 }} />}
                    label={t('centers.favorite_labs')}
                    onChange={() => setIsOnlyFavorites((prev) => !prev)}
                  />
                )}
              </Box>
              <LoadingButton variant='contained' sx={{ minWidth: '212px' }} startIcon={<MyLocationIcon />} onClick={getCurrentPosition} disabled={isLoadingGeolocation} loading={isLoadingGeolocation}>
                {t('centers.geolocate')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={0}
                sx={{
                  ...(!isMobileView && { minHeight: '50vh' }),
                }}
              >
                <Grid item xs={isMobileView ? 12 : 3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <CentersList centersInfo={centersInfo} handleOpenMapMarker={handleOpenMapMarker} holidays={holidays} />
                  </Box>
                </Grid>
                {!isMobileView && (
                  <Grid item xs={9} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                    <MapCenters
                      resetZoomTrigger={+fromNearToFar}
                      centersInfo={centersInfo}
                      myPosition={myPosition}
                      markersState={markersState}
                      handleOpenMapMarker={handleOpenMapMarker}
                      handleCloseMapMarker={handleCloseMapMarker}
                      favorites={favorites}
                    />
                  </Grid>
                )}
              </Grid>
              <Backdrop
                open={isSubmitting || isHolidaysLoading}
                sx={{
                  position: 'absolute',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </Grid>
            {selectedCenterInfo && !isMobileView && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} ref={centerDetailsRef}>
                  <CenterDetails centerInfo={selectedCenterInfo} favorites={favorites} addFavoriteLab={addFavoriteLab} deleteFavoriteLab={deleteFavoriteLab} holidays={holidays} />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
      <FilterDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onApply={(data) => setFilterData(data)}
        cities={cities}
        specialities={specialities}
        schedules={schedules}
        defaultFilters={filterData || defaultValues}
        locationEnabled={!!myPosition}
        onClear={() => setFilterData(null)}
      />
      <ConfirmDialog
        title={t('centers.lab_updates_dialog.title')}
        subtitle={t('centers.lab_updates_dialog.subtitle')}
        submitTitle={t('centers.lab_updates_dialog.submitTitle')}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => navigate(RoutePaths.CENTERS_UPDATE)}
      />
    </BodyTemplate>
  );
};

export default Centers;
