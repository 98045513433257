import { Box, Button, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';
import { ResultNew, ResultStatus } from 'models/result.model';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { ReactComponent as EditTagIcon } from 'resources/icons/edit_tag.svg';
import { ReactComponent as ShareIcon } from 'resources/icons/share.svg';
import { ReactComponent as DownloadIcon } from 'resources/icons/download.svg';
import ThreeDotsMenu from 'components/three-dots-menu';
import TagDialog from './TagDialog';
import { resultStatusColors } from '../utils/colors';

const DesktopResultsTable: FC<{ result: ResultNew }> = ({ result }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const threeDotsActions = [
    {
      title: `${t('common.action.modify')} ${t('results.list.table.tag').toLowerCase()}`,
      icon: <SvgIcon component={EditTagIcon} sx={{ fontSize: 24, color: redesignColors.blue.main }} />,
      onClick: () => {
        setOpen(true);
      },
    },
    {
      title: t('common.action.download'),
      icon: <SvgIcon component={DownloadIcon} sx={{ fontSize: 24, color: redesignColors.blue.main }} />,
      onClick: () => {
        console.log('Download');
      },
    },
    {
      title: t('common.action.share'),
      icon: <SvgIcon component={ShareIcon} sx={{ fontSize: 24, color: redesignColors.blue.main }} />,
      onClick: () => {
        console.log('Share');
      },
    }
  ];

  return (
    <TableRow
      hover
      sx={{
        '& > td > p, & > td > a > p': {
          ...(result.consultation_time === null && {
            fontWeight: 'bold',
          }),
        },
      }}
    >
      <TableCell>
        <Typography variant='body1' sx={{ textDecoration: 'underline' }}>
          {`${convertDate(result.sampling_time, true, 'yyyy-MM-dd', 'HH:mm:ss')}` || t('common.missingData')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{result.reference || <MissingData />}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${result.patient.first_name} ${result.patient.last_name}` || <MissingData />}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body1'>{`${result.issuer?.first_name ?? ''} ${result.issuer?.last_name ?? ''}` || t('common.missingData')}</Typography>
      </TableCell>
        <TableCell>
          <Typography variant='body1' sx={{ color: resultStatusColors[result.status], fontWeight: '500' }}>
            {result.status === 'partial' ? t('results.list.table.partialLabel') : t('results.list.table.completeLabel')}
          </Typography>
        </TableCell>      
      <TableCell>
        <Typography variant='body1'>
          {<MissingData />}
        </Typography>
      </TableCell>
      <TableCell />

      <TableCell>
        <Box display='flex' alignItems='center'>
          {result.status === 'partial' ? (
            <IconButton onClick={() => setOpen(true)}>
              <SvgIcon 
                component={EditTagIcon} 
                sx={{ 
                  fontSize: 28, 
                  color: redesignColors.grey.grey2, 
                  '&:hover': {
                    color: redesignColors.blue.main
                  } 
                }} 
              />
            </IconButton>
          ) : (
            <ThreeDotsMenu actions={threeDotsActions} />
          )}

          <ContextLink
            to='DETAIL_RESULT'
            params={{
              id: result.reference,
            }}
          >
            <IconButton>
              <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
            </IconButton>
          </ContextLink>
        </Box>
      </TableCell>
      <TagDialog open={open} setOpen={setOpen} result={result} />
    </TableRow>
  );
};

export default DesktopResultsTable;
