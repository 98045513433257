import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import config from 'config';
import { useAuthenticated, useIsDoctor, useIsMobileView } from 'hooks';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MadeInLuxembourgLogo from 'resources/icons/made-in-luxembourg.svg';
import HostedLuxLogo from 'resources/icons/logo_hostedlux.svg';
import { ReactComponent as phoneIcon } from 'resources/icons/phone.svg';
import ContextLink from '../../_base/ContextLink';
import { useLocation } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';
import FeedbackDialog from './FeedbackDialog';

const Footer: FC = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAuthenticated();
  const isDoctor = useIsDoctor();
  const isMobile = useIsMobileView();
  const [open, setOpen] = useState<boolean>(false);

  const location = useLocation();
  const isAppointmentPage = location.pathname.endsWith(RoutePaths.BOOK_APPOINTMENT);

  return (
    <>
      <Box
        component='footer'
        sx={{
          padding: (theme) => theme.spacing(1, 3),
          borderTop: isAuthenticated ? (theme) => `1px solid ${theme.palette.divider}` : `1px`,
          mt: 'auto',
          fontWeight: 400,
        }}
      >
        <Grid container alignItems='center'>
          <Grid item xs>
            <Grid container direction='row' spacing={{ xs: 2, md: 5 }}>
              {!isAppointmentPage && (
                <>
                  {!isDoctor ? (
                    <Grid item>
                      <Stack direction='row'>
                        <ContextLink
                          to='PRIO_PHONE'
                          params={{
                            phoneNumber: isDoctor ? `${config.footer.doctorPriorityNumber}` : `${config.footer.priorityNumber}`,
                          }}
                          external
                        >
                          <Button variant='link' sx={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                            <SvgIcon fontSize='medium' component={phoneIcon} />
                            <Typography sx={{ mr: 1, mt: 0.25 }}>{'Tel.'}</Typography>
                            <Typography sx={{ lineHeight: 'unset' }}>{isDoctor ? config.footer.doctorPriorityNumber : config.footer.priorityNumber}</Typography>
                          </Button>
                        </ContextLink>
                      </Stack>
                    </Grid>
                  ) : null}
                </>
              )}
              <Grid
                item
                sx={{
                  ml: isMobile ? 0 : '96px',
                }}
              >
                <Grid container>
                  <Grid item>
                    <ContextLink to='CONTACT_US'>
                      <Button variant='link'>{t('navigation.footer.contact_us')}</Button>
                    </ContextLink>
                  </Grid>
                  <Grid item>
                    <Button variant='link' onClick={() => setOpen(true)}>
                      {t('navigation.footer.feedback')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <ContextLink to='GDPR'>
                      <Button variant='link'>{t('navigation.footer.gdpr')}</Button>
                    </ContextLink>
                  </Grid>
                  <Grid item>
                    <ContextLink to='DISCLAIMER'>
                      <Button variant='link'>{t('navigation.footer.disclaimer')}</Button>
                    </ContextLink>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item justifyContent='flex-end'>
            <ContextLink to='MADE_IN_LUXEMBOURG' external>
              <Box
                component='img'
                alt='Made in Luxembourg'
                src={MadeInLuxembourgLogo}
                sx={{
                  height: { xs: '36px', sm: '36px' },
                  pr: 2,
                }}
              />
            </ContextLink>
            <ContextLink to='HOSTED_IN_LUXEMBOURG' external>
              <Box
                component='img'
                alt='Hosted in Luxembourg'
                src={HostedLuxLogo}
                sx={{
                  height: { xs: '36px', sm: '36px' },
                }}
              />
            </ContextLink>
          </Grid>
        </Grid>
        {open && <FeedbackDialog open={open} setOpen={setOpen} />}
      </Box>
    </>
  );
};

export default Footer;
