import React from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { redesignColors } from 'resources/theme/theme.colors';

export type TabOption = {
  label: React.ReactNode;
  value: string;
};

type Props<T> = {
  value: T;
  onChange: (value: T) => void;
  tabs: TabOption[];
};

const Tabs = <T extends string>({ value, onChange, tabs }: Props<T>) => {
  return (
    <MuiTabs
      value={value}
      onChange={(e, value) => onChange(value)}
      variant='scrollable'
      sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}
      TabIndicatorProps={{
        sx: { background: 'transparent', display: 'flex', justifyContent: 'center', height: '3px' },
        children: <div className='MuiTabs-indicatorSpan' style={{ width: '65%', background: redesignColors.blue.main, borderRadius: '100px 100px 0 0' }} />,
      }}
    >
      {tabs.map((tab) => (
        <Tab label={tab.label} value={tab.value} />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
