import { APIConfiguration } from 'api';
import { BaseService } from './base.service';
import { Holiday } from 'models/holiday.model';

const holidaysMock = {
  jsonapi: {
    version: '1.0',
    meta: {
      links: {
        self: {
          href: 'http://jsonapi.org/format/1.0/',
        },
      },
    },
  },
  data: [
    {
      type: 'holidays--holiday',
      id: '112-3',
      attributes: {
        summary: 'New Year’s Day',
        dtstart: '20250101T000000',
        dtend: '20250102T000000',
        dtstart_tz: '20250101T000000',
        dtend_tz: '20250102T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '300-3',
      attributes: {
        summary: 'Easter Monday',
        dtstart: '20250421',
        dtend: '20250422',
        dtstart_tz: '20250421T000000',
        dtend_tz: '20250422T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '303-3',
      attributes: {
        summary: 'Labour Day',
        dtstart: '20250501',
        dtend: '20250502',
        dtstart_tz: '20250501T000000',
        dtend_tz: '20250502T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '221-3',
      attributes: {
        summary: 'Europe Day',
        dtstart: '20250509T000000',
        dtend: '20250510T000000',
        dtstart_tz: '20250509T000000',
        dtend_tz: '20250510T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '304-3',
      attributes: {
        summary: 'Ascension Day',
        dtstart: '20250529',
        dtend: '20250530',
        dtstart_tz: '20250529T000000',
        dtend_tz: '20250530T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '308-3',
      attributes: {
        summary: 'Pentecost Monday',
        dtstart: '20250609',
        dtend: '20250610',
        dtstart_tz: '20250609T000000',
        dtend_tz: '20250610T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '154-3',
      attributes: {
        summary: 'Public Celebration of the Birthday of HRH the Grand Duke',
        dtstart: '20250623T000000',
        dtend: '20250624T000000',
        dtstart_tz: '20250623T000000',
        dtend_tz: '20250624T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '155-3',
      attributes: {
        summary: 'Assumption Day',
        dtstart: '20250815T000000',
        dtend: '20250816T000000',
        dtstart_tz: '20250815T000000',
        dtend_tz: '20250816T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '157-3',
      attributes: {
        summary: 'All Saints’ Day Holiday',
        dtstart: '20251101T000000',
        dtend: '20251102T000000',
        dtstart_tz: '20251101T000000',
        dtend_tz: '20251102T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '158-3',
      attributes: {
        summary: 'First Day of Christmas',
        dtstart: '20251225T000000',
        dtend: '20251226T000000',
        dtstart_tz: '20251225T000000',
        dtend_tz: '20251226T000000',
      },
    },
    {
      type: 'holidays--holiday',
      id: '159-3',
      attributes: {
        summary: 'Second Day of Christmas',
        dtstart: '20251226T000000',
        dtend: '20251227T000000',
        dtstart_tz: '20251226T000000',
        dtend_tz: '20251227T000000',
      },
    },
  ],
  links: {
    self: {
      href: 'http://www-dev.bionext.lu/en/jsonapi/holidays',
    },
  },
};

class HolidayService extends BaseService {
  static readonly AVAILABLE_LANGUAGES = ['fr', 'en', 'pt', 'de'];

  async getHolidays(): Promise<Holiday[]> {
    return holidaysMock.data as Holiday[];
    // return axiosBION.get(`/${this.getLang()}${this.apiConfig.HOLIDAYS.GET}`).then((res: AxiosResponse<HolidaysGetResults, any>) => res.data.data);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new HolidayService(apiConfig);
};
