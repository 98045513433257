import { Button, IconButton, Stack, styled, SvgIcon, ToggleButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as plusIcon } from 'resources/icons/plus.svg';
import { ReactComponent as deleteIcon } from 'resources/icons/delete.svg';
import CheckMark from 'resources/icons/checkmark.svg';
import AddPatientModal from './AddPatientModal';
import { PatientType, Patient, AppointmentPatient } from './types/Patient';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedUser, useCurrentUserRelatives } from 'hooks';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { ca } from 'date-fns/locale';
import { Gender } from 'models/profile.model';

interface ChoosePatientsProps {
  setNextButtonActive: (state: boolean) => void;
}

const stackStyles = { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const buttonStyles = {
  mt: 0,
  border: '1px solid #7A9099',
  borderRadius: '8px',
  padding: '12px 32px 12px 32px',
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  height: '44px',
  ...buttonStyles,
  [`&.Mui-selected`]: {
    border: 'none',
    background: '98% 50% no-repeat url("' + CheckMark + '"), ' + theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ChoosePatients: FC<ChoosePatientsProps> = ({ setNextButtonActive }) => {
  const authenticatedUser = useAuthenticatedUser();
  const relatives = useCurrentUserRelatives();

  const { getState, dispatch } = useStore();

  const { t } = useTranslation();
  const [patients, setPatients] = useState<Patient[]>([]);

  const mapGender = (gender: Gender) => {
    switch (gender) {
      case Gender.MALE:
        return 'male';
      case Gender.FEMALE:
        return 'female';
      case Gender.MALE_FULL:
        return 'male';
      case Gender.FEMALE_FULL:
        return 'female';
      case Gender.MALE_V4:
        return 'male';
      case Gender.FEMALE_V4:
        return 'female';
    }
  };

  useEffect(() => {
    if (authenticatedUser) {
      if (relatives?.length) {
        const relativesPatients = relatives.map((relative, index) => {
          return {
            key: index,
            type: PatientType.Adult,
            firstName: relative.first_name,
            lastName: relative.last_name,
            cns: relative.cns,
            birthDate: new Date(relative.birth_date),
            email: relative.email,
            phone: relative.phone,
            selected: true,
            gender: mapGender(relative.gender),
            id: relative.id,
            canRemove: false,
          };
        });

        setPatients([...patients, ...relativesPatients]);
      } else {
        setPatients([
          {
            key: 0,
            type: PatientType.Adult,
            isCurrentUser: true,
            firstName: authenticatedUser.first_name,
            lastName: authenticatedUser.last_name,
            cns: authenticatedUser.cns,
            birthDate: new Date(authenticatedUser.birth_date),
            email: authenticatedUser.email,
            phone: authenticatedUser.phone,
            selected: true,
            id: authenticatedUser.id,
            canRemove: false,
            gender: mapGender(authenticatedUser.gender),
          },
        ]);
      }
    } else {
      setPatients([
        {
          key: 0,
          type: PatientType.Adult,
          selected: true,
          id: 'adult0',
          canRemove: true,
        },
      ]);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || !storage.patients) return;

    const updatedPatients = storage.patients.map((patient: AppointmentPatient, index: number) => {
      return {
        key: index,
        type: patient.type,
        firstName: patient?.firstName,
        lastName: patient?.lastName,
        birthDate: patient?.birthDate,
        email: patient?.email,
        phone: patient?.phone,
        cns: patient?.cns,
        selected: patient?.selected,
        id: patient?.id,
        canRemove: patient?.canRemove,
      };
    });

    setPatients(updatedPatients);
  }, [getState]);

  useEffect(() => {
    if (patients.length > 0 && patients.some((patient) => patient.selected)) {
      setNextButtonActive(true);
    } else {
      setNextButtonActive(false);
    }

    const adultsNumber = patients.filter((patient) => patient.type === PatientType.Adult && patient.selected).length;
    const childrenNumber = patients.filter((patient) => patient.type === PatientType.Child && patient.selected).length;

    dispatch({
      type: NewAppointmentFlowActionType.SET_ADULTS_NUMBER,
      adultsNumber,
    });

    dispatch({
      type: NewAppointmentFlowActionType.SET_CHILDREN_NUMBER,
      childrenNumber,
    });

    for (let i = 0; i < patients.length; i++) {
      if (!patients[i]) return;

      dispatch({
        type: NewAppointmentFlowActionType.SET_PATIENT_INFO,
        patientInfo: patients[i],
      });
    }
  }, [patients, setNextButtonActive, dispatch]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handlePatientsChange = (key: number) => {
    const updatedPatients = patients.map((patient) => {
      if (patient.key === key) {
        return { ...patient, selected: !patient.selected };
      }

      return patient;
    });
    setPatients(updatedPatients);
  };

  const handleAddPatient = (selected: PatientType) => {
    setModalOpen(false);

    setPatients([...patients, { key: patients.length, type: selected, selected: true, id: selected + patients.length, canRemove: true }]);
  };

  const handleDeletePatient = (key: number) => {
    const deletedPatient = patients.find((patient) => patient.key === key);
    const filteredPatient = patients.filter((patient) => patient.key !== key);

    if (deletedPatient) {
      dispatch({
        type: NewAppointmentFlowActionType.REMOVE_PATIENT_INFO,
        id: deletedPatient?.id,
      });
    }

    setPatients(
      filteredPatient.map((patient, index) => {
        patient.key = index;

        return patient;
      }),
    );
  };

  return (
    <>
      <Stack sx={stackStyles} spacing={2}>
        <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
          {t('checkin.stepper.steps.choose_patient.title')}
        </Typography>

        <Stack
          sx={{
            ...stackStyles,
            width: {
              xs: '100%',
              sm: '70%',
              md: '70%',
              lg: '50%',
            },
          }}
          spacing={2}
        >
          {patients.map((patient, index) => (
            <Stack key={index} sx={{ ...stackStyles, flexDirection: 'row', alignContent: 'center', gap: '16px' }}>
              <StyledToggleButton aria-label={`patient-toggle-${index}`} key={index} value={patient.type} selected={patient.selected} onChange={(e, v) => handlePatientsChange(index)}>
                {patient.firstName
                  ? `${patient.firstName} ${patient.lastName}`
                  : patient.type === PatientType.Adult
                  ? t('checkin.stepper.steps.choose_patient.adult')
                  : t('checkin.stepper.steps.choose_patient.child')}
              </StyledToggleButton>
              {patient.canRemove && (
                <IconButton sx={{ border: 'none', marginTop: 0 }} onClick={() => handleDeletePatient(index)}>
                  <SvgIcon component={deleteIcon} />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>

        <Button sx={{ border: 'none', color: '#0F6F99' }} startIcon={<SvgIcon component={plusIcon} />} onClick={() => setModalOpen(true)}>
          {t('checkin.stepper.steps.choose_patient.button.add_patient')}
        </Button>
      </Stack>

      <AddPatientModal open={isModalOpen} onClose={() => setModalOpen(false)} onAddPatient={handleAddPatient} />
    </>
  );
};

export default ChoosePatients;
