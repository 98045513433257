import { Center } from 'models/centers.model';

const formatCenterAddress = (center: Center) => {
  const nb = center.attributes.field_prelevement_numero_rue;
  const rue = center.attributes.field_prelevement_rue;
  const code = center.attributes.field_prelevement_code_postal;
  const ville = center.attributes.field_prelevement_ville;
  return `${nb}, ${rue} ${code} ${ville}`;
};

export default formatCenterAddress;
