import { Box, Card, CardContent, IconButton, SvgIcon, TableCell, TableRow, Typography } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import GenericTable from 'components/generic-table';
import { GenericHeader } from 'components/generic-table/GenericTableHeader';
import NoResultsTableRow from 'components/no-results-table-row';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { ReactComponent as AlertIcon } from 'resources/icons/alert.svg';
import { FC } from 'react';
import { redesignColors } from 'resources/theme/theme.colors';

interface ListProps {}

const tableHeaders: GenericHeader[] = [
  { id: 'date_prescription', label: 'e_prescriptions.list.headers.date_prescription', sx: { paddingLeft: 5.5 } },
  { id: 'patient', label: 'e_prescriptions.list.headers.patient' },
  { id: 'doctor', label: 'e_prescriptions.list.headers.doctor' },
  { id: 'date_validity', label: 'e_prescriptions.list.headers.date_validity' },
  { id: 'action', label: undefined },
];

const mockItems = [
  {
    id: 1,
    date_prescription: '2024-12-21 10:00 AM',
    patient: 'John DOU',
    doctor: 'Doctor name',
    date_validity: '2024-12-21',
  },
  {
    id: 2,
    date_prescription: '2024-12-21 10:00 AM',
    patient: 'John DOU',
    doctor: 'Doctor name',
    date_validity: '2024-12-21',
    warning: true,
  },
];

const EprescriptionsList: FC<ListProps> = ({}) => {
  return (
    <Card sx={{ padding: 0 }}>
      <CardContent>
        <GenericTable headers={tableHeaders} noPagination>
          {mockItems.length ? (
            mockItems.map((ePrescription, key) => {
              return (
                <TableRow hover key={key}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      {ePrescription?.warning ? <SvgIcon component={AlertIcon} sx={{ fontSize: 24, color: redesignColors.red, mr: 1 }} /> : <Box width='24px' mr={1} />}
                      <Typography>{ePrescription.date_prescription} </Typography>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography>{ePrescription.patient} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{ePrescription.doctor} </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{ePrescription.date_validity} </Typography>
                  </TableCell>

                  <TableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ContextLink
                        to='PRESCRIPTION_DETAILS'
                        params={{
                          id: ePrescription.id,
                        }}
                      >
                        <IconButton>
                          <SvgIcon component={RightArowIcon} sx={{ fontSize: 28, color: redesignColors.grey.grey2 }} />
                        </IconButton>
                      </ContextLink>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <NoResultsTableRow colSpan={tableHeaders.length} />
          )}
        </GenericTable>
      </CardContent>
    </Card>
  );
};

export default EprescriptionsList;
