import { Box, Breadcrumbs, Container, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IconBack } from 'resources/icons/back.svg';
import theme, { redesignColors } from 'resources/theme/theme.colors';

type BodyTemplateProps = {
  title?: ReactNode;
  children: ReactNode;
  hideHeaderOnMobile?: boolean;
  backButton?: boolean;
  breadcrumbs?: React.ReactNode[];
  icon?: FunctionComponent;
  action?: React.ReactNode;
};

const BodyTemplate: (p: BodyTemplateProps) => ReactElement<BodyTemplateProps> = ({ title, children, icon, hideHeaderOnMobile = false, backButton, action, breadcrumbs }) => {
  const navigate = useNavigate();

  return (
    <Grid item container spacing={0} sx={{ backgroundColor: 'transparent', flexGrow: 1 }} alignContent='baseline'>
      {!!breadcrumbs?.length && (
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: {
              xs: theme.spacing(2),
              md: theme.spacing(3),
            },
          }}
        >
          <Breadcrumbs aria-label='breadcrumbs' sx={{ color: redesignColors.dark }}>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
      )}

      {title && (
        <Grid item xs={12} display={hideHeaderOnMobile ? { xs: 'none', md: 'flex' } : 'flex'}>
          <Box
            display='flex'
            alignItems='center'
            width='100%'
            sx={{
              padding: {
                xs: theme.spacing(2),
                md: theme.spacing(3),
              },
              position: 'relative',
              backgroundColor: 'transparent',
            }}
          >
            {backButton && (
              <Box sx={{ height: 'auto', mr: 1 }}>
                <IconButton onClick={() => navigate(-1)}>
                  <SvgIcon component={IconBack} sx={{ fontSize: { xs: 16, sm: 24 } }} />
                </IconButton>
              </Box>
            )}
            <Box display='flex' width='100%' alignItems='center' justifyContent='space-between'>
              <Box display='flex' alignItems='center'>
                <Typography variant='h2' textAlign='center' sx={{ fontSize: { xs: 22, sm: 24 } }}>
                  {title}
                </Typography>
                {icon && <SvgIcon component={icon} sx={{ fontSize: { xs: 16, sm: 24 }, ml: 2 }} />}
              </Box>
              {action && <Box>{action}</Box>}
            </Box>
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            padding: {
              xs: theme.spacing(2),
              sm: theme.spacing(3),
            },
          }}
        >
          {children}
        </Container>
      </Grid>
    </Grid>
  );
};

export default BodyTemplate;
