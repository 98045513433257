import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { Box, Slider, Typography } from '@mui/material';
import { ReactComponent as TerribleIcon } from 'resources/icons/feedback/terrible.svg';
import { ReactComponent as BadIcon } from 'resources/icons/feedback/bad.svg';
import { ReactComponent as NeutralIcon } from 'resources/icons/feedback/neutral.svg';
import { ReactComponent as GoodIcon } from 'resources/icons/feedback/good.svg';
import { ReactComponent as ExcellentIcon } from 'resources/icons/feedback/excellent.svg';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';

type RatingItem = {
  Emoji: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  mark: number;
  rating: number;
  color: string;
  translateCode: string;
};

type RatingProps = { rating: number; setRating: (rating: number) => void };

const StyledRating = styled(Rating)(({ theme }) => ({
  width: 320,
  justifyContent: 'space-between',
  alignItems: 'center',
  '&>span': {
    display: 'flex',
    width: '64px',
    justifyContent: 'center',
  },
  '&>span:nth-child(2)': {
    justifyContent: 'flex-start',
  },
  '&>span:nth-child(4)': {
    justifyContent: 'flex-end',
  },
  '& .MuiRating-iconEmpty svg': {
    transform: 'scale(0.7) !important',
    opacity: 0.6,
  },
  '& .MuiRating-icon': {
    fontSize: '48px',
    transform: 'scale(1)',
    transition: 'transform 0.3s',
    margin: '0 0 8px 0',
  },
}));

const rainbowBackground = 'linear-gradient(90deg, rgba(255,106,103,1) 0%, rgba(255,162,75,1) 25%, rgba(255,211,52,1) 50%, rgba(159,221,87,1) 75%, rgba(48,228,128,1) 100%)';
const StyledSlider = styled(Slider)(() => ({
  width: 288,
  height: 8,
  padding: 0,
  marginTop: 2,

  '& .MuiSlider-track': {
    border: 0,
    background: rainbowBackground,
  },
  '& .MuiSlider-mark': {
    width: 2,
    height: 6,
    background: 'rgba(0,0,0,0.2)',
    opacity: 0.8,
  },
  '& .MuiSlider-rail': {
    border: 0,
    background: redesignColors.grey.grey4,
  },
  '& .MuiSlider-thumb': {
    width: '16px',
    height: '16px',
    border: '1px solid black',
  },
}));

const ratingItems: RatingItem[] = [
  {
    Emoji: TerribleIcon,
    translateCode: 'feedback.emoji_rating.terrible',
    mark: 5,
    color: '#FF6A66',
    rating: 1,
  },
  {
    Emoji: BadIcon,
    translateCode: 'feedback.emoji_rating.bad',
    mark: 25,
    color: '#FFA24B',
    rating: 2,
  },
  {
    Emoji: NeutralIcon,
    translateCode: 'feedback.emoji_rating.neutral',
    mark: 50,
    color: '#FFD334',
    rating: 3,
  },
  {
    Emoji: GoodIcon,
    translateCode: 'feedback.emoji_rating.good',
    mark: 75,
    color: '#A0DC56',
    rating: 4,
  },
  {
    Emoji: ExcellentIcon,
    translateCode: 'feedback.emoji_rating.excellent',
    mark: 95,
    color: '#2EE581',
    rating: 5,
  },
];

export default function EmojiRating({ rating, setRating }: RatingProps) {
  const { t } = useTranslation();

  const selectedValue = React.useMemo(() => {
    return ratingItems.find((item) => item.rating === rating);
  }, [rating]);

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <StyledRating
        readOnly
        value={selectedValue?.rating}
        name='highlight-selected-only'
        defaultValue={4}
        IconContainerComponent={IconContainer}
        getLabelText={(value: number) => '' as string}
        highlightSelectedOnly
      />
      <StyledSlider
        disabled={false}
        value={selectedValue?.mark}
        onChange={(_, newValue: number | number[]) => {
          const changedValue = ratingItems.find((rating) => rating.mark === newValue);
          setRating(changedValue?.rating as number);
        }}
        slotProps={{ thumb: { style: { color: selectedValue?.color as any } } }}
        sx={{
          '& .MuiSlider-rail': {
            background: selectedValue?.mark === ratingItems[ratingItems.length - 1].mark ? rainbowBackground : redesignColors.grey.grey4,
            opacity: selectedValue?.mark === ratingItems[ratingItems.length - 1].mark ? 1 : 0.38,
          },
        }}
        marks={Object.values(ratingItems).map((item) => ({ value: item.mark }))}
        min={0}
        max={100}
        step={null}
      />
      <Typography fontWeight='500' mt={2}>
        {t(selectedValue?.translateCode as string)}
      </Typography>
    </Box>
  );
}

function IconContainer(props: IconContainerProps) {
  const { value, className, ...other } = props;
  const Emoji = ratingItems.find((item) => item.rating === value)?.Emoji;
  return (
    Emoji && (
      <Box {...other} key={value} className={className}>
        <Emoji style={{ height: '1em', width: '1em' }} />
      </Box>
    )
  );
}
