import { Box, Card, CardContent, Typography, Grid, Button } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import config from 'config';
import { ReactComponent as RightArowIcon } from 'resources/icons/right_arrow.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import dayjs from 'dayjs';
import { DataProps } from 'models/news.model';

const NewsCard: FC<{ newsItem: DataProps }> = ({ newsItem }) => {
  const { t, i18n } = useTranslation();
  const { created, title, path } = newsItem.news.attributes;

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        boxShadow: 'none',
        borderRadius: '8px',
        width: '100%',
        padding: '16px',
      }}
    >
      <CardContent sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Grid item sx={{ paddingRight: '24px' }}>
          <Typography variant='body2' fontSize='14px' color={redesignColors.grey.grey2}>
            {dayjs(created).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant='body1' pb={3} fontWeight='500'>
            {title}
          </Typography>
          <ContextLink to='BIONEXT_PATH' externalDynamicParams={`/${path.langcode}${path.alias}`} external>
            <Button variant='link' endIcon={<RightArowIcon />} sx={{ color: redesignColors.blue.dark }}>
              {t('news_page.card.read_more')}
            </Button>
          </ContextLink>
        </Grid>
        <Grid item>
          <img
            src={`${config.websiteBionextApiUrl}${newsItem.file?.attributes.uri.url}`}
            alt={`${t('news_page.card.altLabelPicture')}`}
            style={{
              width: '109px',
              height: '109px',
              borderRadius: '8px',
              objectFit: 'cover',
              border: `1px solid ${redesignColors.grey.grey4}`,
            }}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};
export default NewsCard;
