import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, SvgIcon, TextField } from '@mui/material';
import api from 'api';
import { useContextRedirection, useId, useNotification } from 'hooks';
import { AuthenticationRequest } from 'models/user.model';
import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IconEmail } from 'resources/icons/messagerie.svg';
import { loginUser } from 'store/actions/session.actions';
import { tryCatch, ValuesTypeExtractor } from 'utils';

interface LoginFormProps {
  redirectionUrl?: string;
  suffix?: string;
}

const LoginForm: FC<LoginFormProps> = ({ redirectionUrl = '/home', suffix }) => {
  const [showPassword, toggleShowPassword] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  type ValuesType = ValuesTypeExtractor<typeof handleSubmit>;
  const id = useId<ValuesType>(suffix);
  const navigate = useContextRedirection();
  const dispatch = useDispatch();
  const { notification } = useNotification();
  const { t } = useTranslation();
  const location = useLocation();

  const handleClickShowPassword = () => {
    toggleShowPassword(!showPassword);
  };

  const { register, handleSubmit } = useForm<AuthenticationRequest>();
  const onSubmit: SubmitHandler<AuthenticationRequest> = useCallback(
    (data) => {
      setSubmitting(true);
      const { email, password } = data;
      tryCatch(
        async () => {
          await Promise.all([
            api.authentication.authenticate({
              username: email,
              password: password,
            }),
          ]);

          dispatch(loginUser());
          navigate(location.state?.redirectionUrl ?? redirectionUrl);
        },
        () => {
          notification(t('notifications.authentification.failedPassword'), 'error');
        },
        () => {
          setSubmitting(false);
        },
      );
    },
    [navigate, dispatch, notification, redirectionUrl, t, location.state?.redirectionUrl],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack
        direction='column'
        spacing={3}
        sx={{
          minWidth: '100%',
          minHeight: '100%',
          justifyContent: 'center',
          marginTop: '0px',
        }}
      >
        <Stack direction='column' spacing={2}>
          <TextField
            variant='filled'
            sx={{
              backgroundColor: '#FFFFFF',
            }}
            {...register('email')}
            name={id('email')}
            type={'email'}
            label={t('landing_page.login.email')}
            fullWidth
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SvgIcon component={IconEmail} sx={{ fontSize: 24 }} />
                </InputAdornment>
              ),
              autoComplete: 'email',
            }}
          />
          <Stack direction='column' spacing={1} alignItems='end'>
            <TextField
              variant='filled'
              sx={{
                backgroundColor: '#FFFFFF',
                marginBottom: '4%',
              }}
              {...register('password')}
              name={id('password')}
              type={showPassword ? 'text' : 'password'}
              label={t('landing_page.login.password')}
              fullWidth
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={Lock} sx={{ fontSize: 24 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClickShowPassword} edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction='column' spacing={1} alignItems='end'>
            <LoadingButton type='submit' variant='contained' fullWidth loading={isSubmitting}>
              {t('landing_page.login.login')}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default LoginForm;
