import { Grid } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useIsRelative } from 'hooks';
import { Person, ShortenPerson } from 'models/profile.model';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import IndexMyProfile from './IndexMyProfile';
import IndexProfileTabSwitcher, { RelativeTab } from './IndexProfileTabSwitcher';
import IndexRelativeProfile from './IndexRelativeProfile';
import ConfirmDialog from 'components/confirm-dialog';

const Profile: FC<{ profileType: RelativeTab }> = ({ profileType }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<RelativeTab>(profileType);

  const currentUser = useSelector((state: GlobalState) => state.session.currentUser as ShortenPerson);
  const relativesCurrentUser = useSelector((state: GlobalState) => state.session.relativesCurrentUser as Person[]);
  const isRelative = useIsRelative();

  useEffect(() => {
    setSelectedTab(profileType);
  }, [profileType]);

  return (
    <BodyTemplate title={profileType === 'me' ? t('bodyTemplate.myProfile') : t('bodyTemplate.myRelatives')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        {!isRelative && <IndexProfileTabSwitcher selectedTab={selectedTab} setSelectedTab={setSelectedTab} />}
        {profileType === 'me' ? <IndexMyProfile currentUser={currentUser} /> : <IndexRelativeProfile relativesCurrentUser={relativesCurrentUser} />}
      </Grid>
    </BodyTemplate>
  );
};

export default Profile;
