import React from 'react';
import { MenuItem, Menu, IconButton, SvgIcon, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export type Action = {
  title: React.ReactNode;
  icon?: React.ReactNode;
  color?: 'primary' | 'secondary';
  onClick: () => Promise<void> | void;
};

type Props = {
  actions: Action[];
};

const ThreeDotsMenu = ({ actions }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = ({ title, icon, onClick }: Action, id: number) => (
    <MenuItem
      key={`menu-item-${id}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px !important',
        '&:hover': {
          fontWeight: '500',
        },
      }}
      onClick={async () => {
        await onClick();
        handleClose();
      }}
    >
      <Box width='24px' height='24px' mr={0.5}>
        {icon && icon}
      </Box>
      {title}
    </MenuItem>
  );

  if (!actions.length) {
    return null;
  }
  return (
    <div>
      <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} MenuListProps={{ sx: { padding: '0 0 0 4px !important', width: 'fit-content' } }}>
        {actions.map(renderMenuItem)}
      </Menu>
    </div>
  );
};

export default ThreeDotsMenu;
