import { Button, Grid, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as PlusIcon } from 'resources/icons/plus.svg';
import { useIsPatient, useIsRelative, useRelatives } from 'hooks';
import { Relation, RelationUsualInfo, ShortenPerson } from 'models/profile.model';
import { getFullName } from 'utils/func/Person.func';
import ProfileCard from '../ProfileCard';
import AccessCard from '../shared/AccessCard';
import { useTranslation } from 'react-i18next';
import ContextLink from 'components/_base/ContextLink';

const MyProfile: FC<{ currentUser: ShortenPerson }> = ({ currentUser }) => {
  const { t } = useTranslation();
  const relatives = useRelatives();
  const isPatient = useIsPatient();
  const isRelative = useIsRelative();

  const grantees: RelationUsualInfo[] = [];
  const grantors: RelationUsualInfo[] = [];

  const getGrantees = relatives && relatives?.grantors;
  const getGrantors = relatives && relatives?.grantees;

  getGrantees &&
    getGrantees?.map((granteeInfo: Relation) => {
      const { grantee, id } = granteeInfo;
      return (
        grantees &&
        grantees.push({
          id: grantee.id,
          invitationId: id,
          first_name: grantee.first_name,
          last_name: grantee.last_name,
          birth_date: grantee.birth_date,
        })
      );
    });

  getGrantors &&
    getGrantors?.map((grantorInfo: Relation) => {
      const { grantor, id } = grantorInfo;
      return (
        grantors &&
        grantors.push({
          id: grantor.id,
          invitationId: id,
          first_name: grantor.first_name,
          last_name: grantor.last_name,
          birth_date: grantor.birth_date,
        })
      );
    });

  return (
    <>
      <Grid item xs={12} md={6}>
        {currentUser && <ProfileCard title={getFullName(currentUser)} person={currentUser} showEditProfileButton={!isRelative} showDeleteButton={false} />}
      </Grid>
      {isPatient && !isRelative && (
        <Grid item xs={12} md={6} display={{ md: 'block' }}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <AccessCard
                title={
                  <Typography variant='body1' fontWeight='700'>
                    {t('profile.results.access_mine')}
                  </Typography>
                }
                emptyText={t('profile.results.access_mine_empty')}
                emptyAction={
                  <ContextLink to='PROFILE_ADD_RELATIVE_FORM'>
                    <Button startIcon={<SvgIcon component={PlusIcon} />} variant='contained' color='primary'>
                      {t('profile.relatives.add.add_relative')}
                    </Button>
                  </ContextLink>
                }
                accessers={grantors}
              />
            </Grid>

            <Grid item xs={12}>
              <AccessCard
                title={
                  <Typography variant='body1' fontWeight='700'>
                    {t('profile.results.access_my_relatives')}
                  </Typography>
                }
                emptyText={t('profile.results.access_my_relatives_empty')}
                accessers={grantees}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MyProfile;
