import { Box, CircularProgress, Grid, IconButton, InputAdornment, SvgIcon, TextField, Typography, useTheme } from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { ReactComponent as IconSearch } from 'resources/icons/search.svg';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { ReactComponent as IconBionext } from 'resources/icons/bionext-tv-logo.svg';
import { useLocale, useNotification } from 'hooks';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VideoCard from './components/VideoCard';
import { Video } from 'models/video.model';
import { redesignColors } from 'resources/theme/theme.colors';
import { LoadingButton } from '@mui/lab';

const MIN_SEARCH_VALUE = 3;

const BionextTv: FC = () => {
  const theme = useTheme();
  const [videos, setVideos] = useState<Video[]>([]);
  const [searchingVideos, setSearchingVideos] = useState<Video[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const locale = useLocale();
  const { notification } = useNotification();
  useEffect(() => {
    api.videos
      .getVideos(locale)
      .then((res: Video[]) => {
        setVideos(res);
        setSearchingVideos(res);
      })
      .catch(() => notification(t('tv.notification.error'), 'error'))
      .finally(() => {
        setIsLoading(false);
      });
  }, [locale, notification, t]);

  useEffect(() => {
    if (searchValue.length >= MIN_SEARCH_VALUE && isSearching) {
      setSearchingVideos(videos.filter((video) => video.title.toLowerCase().includes(searchValue.toLowerCase())));
      setIsSearching(false);
      return;
    }
  }, [isSearching, searchValue]);

  const renderBody = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6} md={4}>
          <Box display='flex' alignItems='center'>
            <TextField
              name='searchVideo'
              value={searchValue}
              variant='filled'
              onChange={(e) => setSearchValue(e.target.value)}
              required
              label={t('common.action.search')}
              fullWidth
              onKeyDown={(event) => {
                if (event.key === 'Enter' && searchValue.length >= MIN_SEARCH_VALUE) {
                  event.preventDefault();

                  setIsSearching(true);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SvgIcon component={IconSearch} />
                  </InputAdornment>
                ),
                endAdornment: searchValue.length > 0 && (
                  <InputAdornment
                    position='end'
                    onClick={() => {
                      setSearchValue('');
                      setIsSearching(true);
                      setSearchingVideos(videos);
                    }}
                  >
                    <IconButton>
                      <SvgIcon component={IconClose} sx={{ width: '16px', height: '16px', color: `${redesignColors.red}!important` }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  background: theme.palette.common.white,
                  '&&.Mui-focused': {
                    background: theme.palette.common.white,
                  },
                },
              }}
            />
            {searchValue.length > 0 && (
              <LoadingButton
                variant='contained'
                disabled={isSearching || searchValue.length < MIN_SEARCH_VALUE}
                onClick={() => {
                  setIsSearching(true);
                }}
                sx={{ ml: 2, width: '120px' }}
              >
                {t('common.action.search')}
              </LoadingButton>
            )}
          </Box>
        </Grid>
        <Grid item display={{ xs: 'none', md: 'block' }} xs={12} sm={6} md={8} />
        <Grid item xs={12}>
          {searchValue.length >= MIN_SEARCH_VALUE && !!videos.length && videos.length !== searchingVideos.length && (
            <Typography variant='body2'>
              {t('bionext_tv.search_results')} {videos.length}
            </Typography>
          )}
        </Grid>
        {!!searchingVideos.length ? (
          searchingVideos?.map((video) => {
            return (
              <Grid item xs={12} md={3} key={video.id}>
                <VideoCard video={video} />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <Typography variant='body1' color='black' fontWeight='500'>
              {t('bionext_tv.not_found_results', { searchValue })}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <BodyTemplate
      title={
        <>
          <IconBionext />
        </>
      }
    >
      {isLoading ? (
        <Box display='flex' justifyContent='center' mt='25%'>
          <CircularProgress />
        </Box>
      ) : (
        renderBody()
      )}
    </BodyTemplate>
  );
};

export default BionextTv;
