import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const GenericDatePicker: FC<{
  control?: any;
  name: string;
  disabled?: boolean;
  helperText?: string;
  onValidate?: any;
  required?: boolean;
  error?: boolean;
  maxDate?: Date;
  dataFormat?: 'ISO' | 'DATE';
  label?: string;
  defaultValue?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
}> = ({ label, control, maxDate = new Date(), defaultValue, dataFormat = 'DATE', name, error, disabled = false, helperText, onValidate, required, variant = 'filled' }) => {
  const { t, i18n } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        // pattern: BIRTHDATE,
        validate: onValidate,
      }}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      render={({ field }) => (
        <LocalizationProvider adapterLocale={i18n.language}>
          <DatePicker
            {...field}
            value={field.value ? new Date(field.value) : null}
            label={label ? label : t('patients.search.fields.birth_date')}
            openTo='year'
            views={['year', 'month', 'day']}
            disabled={disabled}
            maxDate={maxDate}
            onChange={(date) => {
              if (date instanceof Date && !isNaN(date.getTime())) {
                if (dataFormat === 'ISO') {
                  field.onChange(date?.toISOString());
                } else {
                  field.onChange(date);
                }
              }
            }}
            slotProps={{ textField: { variant: variant, fullWidth: true, required: required, error: error, helperText: helperText } }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default GenericDatePicker;
