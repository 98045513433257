import { Alert, Grid, SvgIcon } from '@mui/material';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PatientInfoCard from 'pages/patient/new-appointment/components/PatientInfoCard';
import { RdvPatient } from 'models/appointment.model';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';

const InformationStep: FC<{
  handleIsActiveNextButton: () => void;
  setPatientRdv: (data: RdvPatient) => void;
  patientRdv: RdvPatient | null;
}> = ({ handleIsActiveNextButton, setPatientRdv, patientRdv }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const allFieldIsValid =
      patientRdv?.birthname &&
      patientRdv?.gender &&
      patientRdv?.firstname &&
      patientRdv?.phone &&
      patientRdv?.verifyemail &&
      patientRdv?.email &&
      patientRdv?.verifyemail === patientRdv?.email &&
      patientRdv?.birthdate;
    if (allFieldIsValid) {
      handleIsActiveNextButton();
    }
  }, [patientRdv]);

  useEffect(() => {
    setPatientRdv({ ...(patientRdv || {}), gender: 'male' } as RdvPatient);
  }, []);

  return (
    <Grid item xs={12} sm={8} md={6} sx={{ margin: '0 auto', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px', width: '100%', mb: 2 }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.opening.male_only')}
      </Alert>
      <PatientInfoCard
        type='Patient'
        onlyMale
        values={patientRdv || undefined}
        setValue={(key: string, value: string | Date) => setPatientRdv({ ...(patientRdv || {}), [key]: value } as RdvPatient)}
      />
    </Grid>
  );
};

export default InformationStep;
