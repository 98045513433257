import { Alert, Box, Button, Grid, Step, StepLabel, Stepper, SvgIcon, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthenticated, useContextRedirection, useIsMobileView, useNotification } from 'hooks';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectAccountDialog from 'components/connect-account-dialog';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import RoutePaths from 'utils/RoutePaths';
import { redesignColors } from 'resources/theme/theme.colors';
import SelectFlowStep from './steps/SelectFlowStep';
import AddressStep from './steps/AddressStep';
import AvailaleSlot from './steps/AvailableSlotStep';
import InformationStep from './steps/InformationStep';
import SummaryStep from './steps/SummaryStep';
import ConfirmDialog from 'components/confirm-dialog';
import { Rdv, RdvPatient } from 'models/appointment.model';
import { CenterInfo } from 'pages/patient/centers/types';
import PrescriptionsStep from '../../../components/appointment/PrescriptionsStep';
import IntroductionStep from './steps/IntroductionStep';

enum DialogType {
  delete = 'delete',
  error_submit = 'error_submit',
  success_submit = 'success_submit',
}

type Step = { title: string; component: JSX.Element; nextButtonLabel?: string };

const dialogs = {
  delete: {
    title: 'fertility.cancel_dialog.title',
    subtitle: 'fertility.cancel_dialog.subtitle',
    submitTitle: 'common.action.delete',
    hideCancel: false,
  },
  error_submit: {
    title: 'fertility.error_submit_dialog.title',
    subtitle: 'fertility.error_submit_dialog.subtitle',
    submitTitle: 'common.action.understood',
    hideCancel: true,
  },
  success_submit: {
    title: 'fertility.success_submit_dialog.title',
    subtitle: 'fertility.success_submit_dialog.subtitle',
    submitTitle: 'common.action.understood',
    hideCancel: true,
  },
};

const initialRdvState = {
  coordInfo: null,
  slot: null,
  center: null,
  prescriptions: [],
};

const Fertility: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const isMobileView = useIsMobileView();
  const isAuthenticated = useAuthenticated();
  const { notification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [isActiveNextButton, setIsActiveNextButton] = useState(false);
  const [rdv, setRdv] = useState<Rdv>(initialRdvState);
  const [patientRdv, setPatientRdv] = useState<RdvPatient | null>(null);
  const [centerInfo, setCenterInfo] = useState<CenterInfo | null>(null);
  const [date, setDate] = useState<Date | null>(null);

  const [isOpeningPage, setIsOpeningPage] = useState(true);
  const [showInfoPage, setShowInfoPage] = useState(true);
  const [openDialogType, setOpenDialogType] = useState<DialogType | null>(null);

  const steps: Step[] = useMemo(() => {
    const handleNext = () => {
      setActiveStep((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
    };

    const handleBack = () => {
      setActiveStep((activeStep) => activeStep - 1);
    };
    const handleIsActiveNextButton = () => {
      setIsActiveNextButton(true);
    };

    const navigationProps = { handleNext, handleBack, handleIsActiveNextButton };

    const stepper = [
      {
        title: t('fertility.address.title'),
        component: <AddressStep {...navigationProps} centerInfo={centerInfo} setCenterInfo={setCenterInfo} />,
      },
      {
        title: t('fertility.available_slot.title'),
        component: <AvailaleSlot {...navigationProps} setDate={setDate} date={date} centerInfo={centerInfo} />,
      },
      {
        title: t('fertility.information.title'),
        component: <InformationStep {...navigationProps} setPatientRdv={setPatientRdv} patientRdv={patientRdv} />,
        nextButtonLabel: 'common.action.continue',
      },
      {
        title: t('fertility.prescriptions.title'),
        component: <PrescriptionsStep {...navigationProps} patientRdv={patientRdv} setNextButtonActive={() => setIsActiveNextButton(true)} />,
        nextButtonLabel: 'common.action.continue',
      },
      {
        title: t('fertility.summary.title'),
        component: <SummaryStep {...navigationProps} date={date} centerInfo={centerInfo} patientRdv={patientRdv} />,
        nextButtonLabel: 'common.action.confirm',
      },
    ];

    if (isAuthenticated) {
      stepper.unshift({
        title: t('fertility.introduction.title'),
        component: <IntroductionStep {...navigationProps} />,
      });
    }
    return stepper;
  }, [t, loading, centerInfo, patientRdv, date]);
  const isLastStep = activeStep === steps.length - 1;

  return (
    <BodyTemplate>
      <Grid container justifyContent='center'>
        {!isOpeningPage && (
          <Grid item xs={12} mb={2}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, key) => (
                <Step key={key} completed={key < activeStep}>
                  <StepLabel>{isMobileView ? '' : step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        )}

        {isOpeningPage && !isAuthenticated && (
          <>
            <Grid item xs={12} md={3} />
            <ConnectAccountDialog redirect={RoutePaths['HOME']} />
            <Grid item xs={12} md={3} />
          </>
        )}
        {isOpeningPage &&
          (showInfoPage ? (
            <>
              {!isMobileView && <Grid item md={3} />}
              <Grid item xs={12} md={6}>
                <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px' }} icon={<SvgIcon component={InfoIcon} />}>
                  {t('fertility.opening.male_only')}
                </Alert>
                <Typography fontSize='18px' fontWeight='700' py={2}>
                  {t('fertility.opening.what_is')}
                </Typography>
                <Typography variant='body2'>{t('fertility.opening.what_is_description')}</Typography>
                <Button
                  variant='contained'
                  sx={{ display: 'block', margin: '40vh auto 0 !important' }}
                  onClick={() => {
                    setShowInfoPage(false);
                  }}
                >
                  {t('fertility.opening.book_button')}
                </Button>
              </Grid>
              {!isMobileView && <Grid item md={3} />}
            </>
          ) : (
            <SelectFlowStep
              handleNext={() => {
                setIsOpeningPage(false);
              }}
            />
          ))}

        {!isOpeningPage && (
          <>
            <Grid item xs={12} container position='relative' display='flex' flexDirection='column'>
              <Button
                sx={{ color: redesignColors.red, position: 'absolute', right: 0, top: '16px' }}
                startIcon={<CloseIcon sx={{ fill: redesignColors.red }} />}
                onClick={() => setOpenDialogType(DialogType.delete)}
              >
                {t('fertility.opening.cancel_button')}
              </Button>
              {steps[activeStep].component}
            </Grid>
          </>
        )}

        {!showInfoPage && (
          <Grid item xs={12} sm={8}>
            <Box display='flex' justifyContent='space-between' sx={isOpeningPage ? { mt: '40vh' } : {}}>
              {!showInfoPage && (
                <Button
                  variant='outlined'
                  sx={{ width: isMobileView ? 150 : 219 }}
                  onClick={() => {
                    if (isOpeningPage) {
                      setShowInfoPage(true);
                      return;
                    }
                    if (activeStep === 0) {
                      setIsOpeningPage(true);
                      return;
                    }
                    setActiveStep((prev) => prev - 1);
                    setIsActiveNextButton(true);
                  }}
                >
                  {isAuthenticated && (isOpeningPage || activeStep === 0) ? t('common.action.back') : t('fertility.back_button')}
                </Button>
              )}
              {!isOpeningPage && (
                <Button
                  variant='contained'
                  disabled={isLastStep ? false : activeStep === steps.length - 1 || !isActiveNextButton}
                  sx={{ width: isMobileView ? 150 : 219 }}
                  onClick={() => {
                    if (isLastStep) {
                      setOpenDialogType(DialogType.error_submit);
                      return;
                    }
                    setActiveStep((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
                    setIsActiveNextButton(false);
                  }}
                >
                  {t(steps[activeStep]?.nextButtonLabel || 'common.action.next')}
                </Button>
              )}
            </Box>
          </Grid>
        )}
        {openDialogType && (
          <ConfirmDialog
            open
            title={t(dialogs[openDialogType].title)}
            subtitle={t(dialogs[openDialogType].subtitle)}
            submitTitle={t(dialogs[openDialogType].submitTitle)}
            hideCancel={dialogs[openDialogType]?.hideCancel}
            onClose={() => setOpenDialogType(null)}
            onSubmit={() => {
              navigate(RoutePaths.HOME);
            }}
          />
        )}
      </Grid>
    </BodyTemplate>
  );
};

export default Fertility;
