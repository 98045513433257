export enum FeedbackCategory {
  LastVisit = 'last_visit',
  OtherContact = 'other_contact',
  MylabApp = 'mylab_app',
  Other = 'other',
}

export type CreateFeedbackRequest = {
  rate: number;
  category: FeedbackCategory;
  datetime: string;
  center: string;
  comment: string;
  contact: boolean;
};
