import { Grid } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import PatientList from './list';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const Eprescriptions: FC = () => {
  const { t } = useTranslation();

  return (
    <BodyTemplate title={t('bodyTemplate.patient.e_prescriptions')}>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid item xs={12}>
          <PatientList />
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default Eprescriptions;
