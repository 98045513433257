import { Grid, SvgIcon, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { DirectoryFormValues, SearchType } from 'models/directory.model';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DoctorSvg } from 'resources/icons/doctor.svg';
import { ReactComponent as CenterSvg } from 'resources/icons/center.svg';
import AllowLocationDialog from 'components/allow-location-dialog';

interface FindHeaderProps {
  control: Control<DirectoryFormValues, any>;
}

const FindHeader: FC<FindHeaderProps> = ({ control }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Grid container spacing={isMobileView ? 1 : 3}>
      <Grid item xs={12}>
        <Typography variant='h2'>{t('findDoctor.searchBar.label')}</Typography>
      </Grid>
      <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
        <Controller
          name={'type'}
          control={control}
          render={({ field }) => (
              <ToggleButtonGroup {...field} orientation={isMobileView ? 'vertical' : 'horizontal'} fullWidth={isMobileView} exclusive>
                <ToggleButton sx={{ px: 4, py: 1.5, width: '348px', height: '45px', borderRadius: '8px', position: 'relative' }} value={SearchType.PROFESSIONAL}>
                  <SvgIcon component={DoctorSvg} sx={{ position: 'absolute', left: '8px' }} />
                  {t('findDoctor.searchBar.actions.professional')}
                </ToggleButton>
                <ToggleButton sx={{ px: 4, py: 1.5, width: '348px', height: '45px', borderRadius: '8px', position: 'relative' }} value={SearchType.HEALTH_INSTITUTION}>
                  <SvgIcon component={CenterSvg} sx={{ position: 'absolute', left: '8px' }} />
                  {t('findDoctor.searchBar.actions.institution')}
                </ToggleButton>
              </ToggleButtonGroup>
          )}
        />
        <AllowLocationDialog />
      </Grid>
    </Grid>
  );
};

export default FindHeader;
