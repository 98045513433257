import { Box, Button, Card, CardActions, CardContent, Grid, SvgIcon, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { ReactComponent as TokenIcon } from 'resources/icons/token.svg';
import { ReactComponent as WithoutTokenIcon } from 'resources/icons/without_token.svg';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import AddRelativeWithTokenForm from '../shared/AddRelativeWithTokenForm';
import AddRelativeForm from './form';
import { useIsMobileView } from 'hooks';

enum RelativeType {
  withToken = 'withToken',
  withoutToken = 'withoutToken',
}

const AddRelative: FC = () => {
  const isMobileView = useIsMobileView();

  const { t } = useTranslation();
  const [selected, setSelected] = useState<RelativeType>(RelativeType.withToken);

  return (
    <BodyTemplate title={t('bodyTemplate.addRelative')} backButton>
      <Grid container spacing={2}>
        <Grid item xs={12} display='flex' alignItems='stretch' flexDirection='column'>
          <Card sx={{ width: '100%', borderRadius: '8px 8px 0 0', paddingBottom: 0 }}>
            <CardContent>
              {Object.values(RelativeType).map((type, index) => (
                <Button
                  startIcon={
                    <SvgIcon
                      sx={{
                        position: 'absolute',
                        left: 8,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: 'primary.contrastText',
                      }}
                      component={type === RelativeType.withToken ? TokenIcon : WithoutTokenIcon}
                    />
                  }
                  variant={type === selected ? 'contained' : 'outlined'}
                  sx={{ height: 44, width: 328, mr: index === 0 ? 2 : 0, mt: isMobileView ? 1 : 0 }}
                  onClick={() => setSelected(type)}
                >
                  {t(`profile.relatives.add.${type}`)}
                </Button>
              ))}
            </CardContent>
          </Card>
          {selected === RelativeType.withToken ? (
            <AddRelativeWithTokenForm />
          ) : (
            <CardActions>
              <AddRelativeForm />
            </CardActions>
          )}
        </Grid>
      </Grid>
    </BodyTemplate>
  );
};

export default AddRelative;
