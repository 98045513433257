import { useContextRedirection } from 'hooks';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeLeftDrawer } from 'store/actions/navMenu.actions';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { clearAllPrescriptionProfile } from 'store/actions/prescriptionProfile.actions';
import { logoutUser } from 'store/actions/session.actions';
import RoutePaths from 'utils/RoutePaths';

const SecurityLogout: FC = () => {
  const dispatch = useDispatch();
  const navigate = useContextRedirection();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(closeLeftDrawer());
    dispatch(clearAllPrescriptionProfile());
    dispatch(logoutUser());
    dispatch({
      type: NewAppointmentFlowActionType.CLEAR_NEW_APPOINTMENT_FLOW,
    });
    navigate(RoutePaths['LANDING_PAGE']);
  }, [dispatch, navigate, t]);

  return null;
};

export default SecurityLogout;
