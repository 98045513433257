import { CircularProgress } from '@mui/material';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import { FC } from 'react';
import CustomCalendarHeader from './custom-calendar-header';
import { redesignColors } from 'resources/theme/theme.colors';

const CustomDateCalendar: FC<DateCalendarProps<Date>> = (props) => {
  return (
    <DateCalendar
      {...props}
      sx={{
        width: 416,
        '& .MuiPickersDay-root': {
          borderRadius: '8px',
          width: 48,
          height: 48,
          fontSize: '16px',
          fontWeight: '500',
          color: redesignColors.dark,
          '&:hover': {
            backgroundColor: `${redesignColors.grey.grey4} !important`,
          },
          '&:focus': {
            backgroundColor: `inherit`,
          },
        },
        '& .MuiPickersYear-yearButton': {
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: `${redesignColors.grey.grey4} !important`,
          },
          '&:focus': {
            backgroundColor: `inherit`,
          },
        },
        '& .MuiDayCalendar-weekDayLabel': {
          color: redesignColors.dark,
          width: 48,
          height: 48,
          fontSize: '16px',
        },
        '& .MuiPickersSlideTransition-root': {
          height: 256,
        },
        '& .MuiPickersDay-today': {
          backgroundColor: `${redesignColors.blue.blue3} !important`,
          borderWidth: 0,
        },
        '& .Mui-selected': {
          backgroundColor: 'transparent',
          border: `2px solid ${redesignColors.blue.main} !important`,
        },
        '& .MuiPickersArrowSwitcher-button': {
          color: redesignColors.dark,
        },
        '& .MuiDayCalendar-header': {
          borderBottom: `1px solid ${redesignColors.grey.grey2}`,
        },
        ...props.sx,
      }}
      slots={{
        ...props.slots,
        calendarHeader: CustomCalendarHeader,
      }}
      slotProps={{
        previousIconButton: {
          size: 'medium',
        },
        nextIconButton: {
          size: 'medium',
        },
        leftArrowIcon: {
          fontSize: 'medium',
        },
        rightArrowIcon: {
          fontSize: 'medium',
        },
        ...props.slotProps,
      }}
      renderLoading={() => <CircularProgress />}
    />
  );
};

export default CustomDateCalendar;
