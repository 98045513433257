import { Alert, Button, Grid, SvgIcon, Typography } from '@mui/material';
import { useCurrentUser, useIsMobileView } from 'hooks';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusSquareIcon } from 'resources/icons/plus_square.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';

const IntroductionStep: FC<{
  handleNext: () => void;
  handleIsActiveNextButton: () => void;
}> = ({ handleNext, handleIsActiveNextButton }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const currentUser = useCurrentUser();

  useEffect(() => {
    handleIsActiveNextButton();
  }, []);
  return (
    <Grid item xs={12} sm={6} md={4} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography fontSize='20px' fontWeight='700' textAlign='center' pb={3}>
        {t('fertility.introduction.choose_patient')}
      </Typography>
      <Alert
        severity='info'
        sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px', width: isMobileView ? '100%' : '440px', mb: 2 }}
        icon={<SvgIcon component={InfoIcon} />}
      >
        {t('fertility.opening.male_only')}
      </Alert>
      <Button variant='outlined' sx={{ width: isMobileView ? '100%' : '440px', pointerEvents: 'none' }} onClick={handleNext}>
        {currentUser?.first_name} {currentUser?.last_name}
      </Button>
      <Button startIcon={<SvgIcon component={PlusSquareIcon} inheritViewBox />} sx={{ mt: 3 }} onClick={handleNext}>
        {t('fertility.introduction.add_patient')}
      </Button>
    </Grid>
  );
};

export default IntroductionStep;
