import { Button, Stack, Typography } from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { SavedAddress, SavedAddressType } from './types/SavedAddress';
import { ReactComponent as RoundedHomeIcon } from 'resources/icons/rounded_home.svg';
import { ReactComponent as RoundedWorkIcon } from 'resources/icons/rounded_work.svg';
import { ReactComponent as RoundedOtherIcon } from 'resources/icons/rounded_other_address.svg';

interface SavedAddressProps {
  setSelectedAddress: (address: string) => void;
}

//Only for logged in user
const SavedAddresses: FC<SavedAddressProps> = ({ setSelectedAddress }) => {
  const [savedAddresses, setSavedAddresses] = useState<SavedAddress[]>([]);

  useEffect(() => {
    // Make actual API call here
    setSavedAddresses([
      {
        id: 0,
        address: '1, rue de la Gare',
        type: SavedAddressType.Home,
        city: 'Luxemburg',
        postcode: 1234,
      },
      {
        id: 1,
        address: '2, rue de la Gare',
        type: SavedAddressType.Work,
        city: 'Luxemburg',
        postcode: 1234,
      },
      {
        id: 2,
        address: '3, rue de la Gare',
        type: SavedAddressType.Other,
        city: 'Luxemburg',
        postcode: 1234,
      },
      {
        id: 3,
        address: '3, rue de la Gare',
        type: SavedAddressType.Other,
        city: 'Luxemburg',
        postcode: 1234,
      },
      {
        id: 4,
        address: '3, rue de la Gare',
        type: SavedAddressType.Other,
        city: 'Luxemburg',
        postcode: 1234,
      },
    ]);
  }, [setSavedAddresses]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const address = document.getElementById('saved_address_address')?.textContent;
    const postCodeCity = document.getElementById('saved_address_postcode_city')?.textContent;

    setSelectedAddress(`${address} ${postCodeCity}`);
  };

  return (
    <Stack
      sx={{
        width: '100%',
      }}
      spacing={2}
    >
      {savedAddresses.map((address) => (
        <Button
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            gap: '10px',
            padding: '8px 0px 8px',
            textDecoration: 'none',
          }}
          key={address.id}
          onClick={handleClick}
        >
          {address.type === SavedAddressType.Home && <RoundedHomeIcon />}
          {address.type === SavedAddressType.Work && <RoundedWorkIcon />}
          {address.type === SavedAddressType.Other && <RoundedOtherIcon />}

          <Stack>
            <Typography id='saved_address_address' variant='body1'>
              {address.address}
            </Typography>
            <Typography id='saved_address_postcode_city' variant='body1' sx={{ color: 'gray' }}>
              {address.postcode} {address.city}
            </Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

export default SavedAddresses;
