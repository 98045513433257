import { Alert, Box, Button, Card, CardContent, Divider, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import React, { ChangeEvent, ChangeEventHandler, createRef, FC, useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from 'resources/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';
import { RdvPatient } from 'models/appointment.model';
import { useNotification } from 'hooks';
import { MAX_SIZE_FILE } from 'utils/Constants';
import ConfirmDialog from 'components/confirm-dialog';
import { useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { AppointmentPatient, PatientFiles } from './types/Patient';

const PatientPrescriptionCard: FC<{
  patient: AppointmentPatient;
  files: PatientFiles[];
  refr: React.RefObject<HTMLInputElement> | null;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>, patientId: string | number) => void;
  handleUploadFile: () => void;
  setFiles: (state: any) => void;
}> = ({ patient, files, refr, handleFileChange, handleUploadFile, setFiles }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ width: '100%', mt: 2 }}>
      <CardContent sx={{ borderRadius: '8px' }}>
        <Typography variant='body1' fontWeight='700'>
          {patient.firstName} {patient.lastName}
        </Typography>
        <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey4}` }} />
        {files.map(
          (patientFiles) =>
            patientFiles.patientId === patient.id &&
            patientFiles.files.map((file) => (
              <div key={file.name}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography fontWeight='500'>{file.name}</Typography>
                  <IconButton
                    onClick={() => {
                      setFiles((prev: PatientFiles[]) => {
                        return prev.map((f) => {
                          if (f.patientId === patient.id) {
                            return {
                              patientId: patient.id,
                              files: f.files.filter((ff) => ff.name !== file.name),
                            };
                          }

                          return f;
                        });
                      });
                    }}
                  >
                    <SvgIcon component={DeleteIcon} inheritViewBox fontSize='small'></SvgIcon>
                  </IconButton>
                </Box>
                <Divider sx={{ my: 2, width: '100%', border: `0.5px solid ${redesignColors.grey.grey4}` }} />
              </div>
            )),
        )}
        <input type='file' ref={refr} style={{ display: 'none' }} onChange={(event) => handleFileChange(event, patient.id)} />
        <Button sx={{ width: '100%' }} startIcon={<SvgIcon component={PlusIcon} />} variant='outlined' onClick={handleUploadFile}>
          {t('fertility.prescriptions.add_prescription')}
        </Button>
      </CardContent>
    </Card>
  );
};

const PrescriptionsStep: FC<{
  handleNext: () => void;
  patientRdv: RdvPatient | null;
  setNextButtonActive?: () => void;
  isNewAppointment?: boolean;
}> = ({ patientRdv, setNextButtonActive, isNewAppointment }) => {
  const { dispatch, getState } = useStore();
  const [appointmentPatients, setAppointmentPatients] = useState<AppointmentPatient[]>([]);
  const refs = useRef(getState().newAppointmentFlowReducer.patients.map(() => createRef<HTMLInputElement>()) || createRef<HTMLInputElement>());
  const [files, setFiles] = useState<PatientFiles[]>([]);
  const { t } = useTranslation();
  const { notification } = useNotification();

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage) return;

    if (storage.prescriptions.length > 0) {
      setFiles(storage.prescriptions);
    }

    if (storage.patients.length > 0) {
      setAppointmentPatients(storage.patients);
    }
  }, [setFiles, getState]);

  useEffect(() => {
    dispatch({
      type: NewAppointmentFlowActionType.SET_PRESCRIPTIONS,
      prescriptions: files,
    });
  }, [files, dispatch]);
  useEffect(() => {
    if (setNextButtonActive && files[0].files.length > 1) {
      setNextButtonActive();
    }
  }, [files]);

  const searchPatientFiles = (patientId: number | string, fileName: string) => {
    return files.find((f) => f.patientId === patientId && f.files.find((ff) => ff.name === fileName));
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, patientId: string | number) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file || !(file.type === 'application/pdf' || file.type.startsWith('image/')) || file.size > MAX_SIZE_FILE) {
      notification(t('appointment.book.steps.prescriptions.notification.error.upload'), 'error');

      event.target.value = '';
      return;
    }

    const patientFiles = searchPatientFiles(patientId, file.name);

    if (patientFiles) {
      notification(t('appointment.book.steps.prescriptions.notification.error.exists', { replace: { prescription: file.name } }), 'error');

      event.target.value = '';
      return;
    }

    setFiles((prev) => {
      const patientFiles = prev.find((f) => f.patientId === patientId);

      if (patientFiles) {
        return prev.map((f) => {
          if (f.patientId === patientId) {
            return {
              patientId,
              files: [...f.files, file],
            };
          }

          return f;
        });
      }

      return [...prev, { patientId, files: [file] }];
    });
  };
  const handleUploadFile = (index: number) => {
    refs?.current?.current?.click();
  };
  return (
    <Grid item xs={12} sm={10} md={8} sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px' }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.prescriptions.upload_prescriptions')}
        <br />
        {t('fertility.prescriptions.thank_you')}
      </Alert>
      {isNewAppointment &&
        appointmentPatients &&
        appointmentPatients.map((patient, index) => (
          <PatientPrescriptionCard
            key={patient.id}
            patient={patient}
            files={files}
            refr={refs.current[index]}
            handleFileChange={handleFileChange}
            handleUploadFile={() => handleUploadFile(index)}
            setFiles={setFiles}
          />
        ))}

      {!isNewAppointment && patientRdv && (
        <PatientPrescriptionCard
          patient={{
            id: (patientRdv.id as number) || '',
            firstName: patientRdv.firstname,
            lastName: patientRdv.lastname as string,
            email: patientRdv.email,
            phone: patientRdv.phone,
            gender: patientRdv.gender,
            birthDate: patientRdv.birthdate as Date,
            birthName: patientRdv.birthname,
            emailConfirm: patientRdv.email,
          }}
          files={files}
          refr={refs.current}
          handleFileChange={handleFileChange}
          handleUploadFile={() => handleUploadFile(0)}
          setFiles={setFiles}
        />
      )}
    </Grid>
  );
};

export default PrescriptionsStep;
