import { FC } from 'react';
import LabInfo from './components/LabInfo';
import LabMockImg from 'resources/images/lab-mock.jpg';
import { ReactComponent as ParkingIcon } from 'resources/icons/parking.svg';
import { ReactComponent as FoodIcon } from 'resources/icons/food.svg';
import { ReactComponent as AccessabilityIcon } from 'resources/icons/accessability.svg';
import { ReactComponent as WifiIcon } from 'resources/icons/wifi.svg';

const labomobileMock = {
  image: LabMockImg,
  open: false,
  name: 'Bridel LABOMOBILE',
  address: '82, rue de Luxembourg \n8140 Bridel',
  distance: '0.5km',
  closeAt: 'Will close at 18:30 PM',
  email: 'centermail@mail.com',
  telephone: '(+352) 27-321 485',
  dailyHours: {
    Monday: '08:30 - 12:00/14:00 - 18:30',
    Tuesday: '08:30 - 12:00/14:00 - 18:30',
    Wednesday: '08:30 - 12:00/14:00 - 18:30',
    Thursday: '08:30 - 12:00/14:00 - 18:30',
    Friday: '08:30 - 12:00/14:00 - 18:30',
    Saturday: '08:00-12:00',
    Sunday: 'day off',
  },
  specialties: [
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
    '010-Blood test/samples',
  ],
  services: [
    {
      icon: <ParkingIcon />,
      name: 'Parking',
    },
    {
      icon: <FoodIcon />,
      name: 'Cafe',
    },
    {
      icon: <AccessabilityIcon />,
      name: 'Accessible',
    },
    {
      icon: <WifiIcon />,
      name: 'Wifi',
    },
  ],
};

//Temp component for mock. Will be deleted after API connection
const Labomobile: FC = () => {
  return <LabInfo title='LABOMOBILE' lab={labomobileMock} />;
};

export default Labomobile;
