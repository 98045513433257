import { Alert, Box, Button, Card, CardContent, Grid, IconButton, SvgIcon, useTheme } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContextLink from 'components/_base/ContextLink';
import { ReactComponent as DownloadIcon } from 'resources/icons/download.svg';
import { ReactComponent as DeleteIcon } from 'resources/icons/delete.svg';
import { ReactComponent as AlertIcon } from 'resources/icons/alert.svg';
import { redesignColors } from 'resources/theme/theme.colors';
import api from 'api';
import { useNotification } from 'hooks';
import ConfirmDialog from 'components/confirm-dialog';
import { set } from 'date-fns';

const EprescriptionsDetails: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const [pdfData, setPdfData] = useState<Blob>();
  const [open, setOpen] = useState<boolean>(false);

  // useEffect(() => {
  //   api.prescriptions
  //     .getPrescriptionFileForDoctor()
  //     .then((data) => {
  //       setPdfData(data);
  //     })
  //     .catch(() => notification(t('prescriptions.types.error'), 'error'));
  // }, [notification, t]);

  const files = pdfData && new Blob([pdfData], { type: 'application/pdf' });
  const fileURL = files && URL.createObjectURL(files);

  return (
    <BodyTemplate title={t('bodyTemplate.patient.e_prescriptions')}>
      <Card>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={12} display='flex' justifyContent='space-between' flexDirection={{ xs: 'column', md: 'row' }}>
              <Box>
                <ContextLink to='PRESCRIPTIONS'>
                  <IconButton>
                    <ArrowBackIcon />
                  </IconButton>
                </ContextLink>
              </Box>
              <Box>
                <Box display='flex' justifyContent='flex-end' flexDirection={{ xs: 'column', sm: 'row' }}>
                  <Button startIcon={<SvgIcon component={DownloadIcon} />} variant='outlined' sx={{ m: 1 }}>
                    {t('e_prescriptions.details.download_button')}
                  </Button>
                  <Button startIcon={<SvgIcon component={DeleteIcon} sx={{ fill: redesignColors.dark }} />} variant='outlined' sx={{ m: 1 }} onClick={() => setOpen(true)}>
                    {t('e_prescriptions.details.delete_button')}
                  </Button>
                </Box>
                <Box display='flex' justifyContent='flex-end' flexDirection={{ xs: 'column', sm: 'row' }}>
                  <ContextLink to='APPOINTMENT'>
                    <Button variant='outlined' sx={{ m: 1 }}>
                      {t('e_prescriptions.details.book_appointment_button')}
                    </Button>
                  </ContextLink>

                  <ContextLink to='CENTERS'>
                    <Button variant='outlined' sx={{ m: 1 }}>
                      {t('e_prescriptions.details.choose_lab_button')}
                    </Button>
                  </ContextLink>
                  <ContextLink to='APPOINTMENT'>
                    <Button variant='outlined' sx={{ m: 1 }}>
                      {t('e_prescriptions.details.choose_labomobile_button')}
                    </Button>
                  </ContextLink>
                </Box>
                <Box display='flex' justifyContent='flex-end' flexDirection={{ xs: 'column', sm: 'row' }}>
                  <ContextLink to='FERTILITY'>
                    <Button variant='outlined' sx={{ m: 1 }}>
                      {t('e_prescriptions.details.book_fertility_button')}
                    </Button>
                  </ContextLink>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Alert
                severity='error'
                sx={{ backgroundColor: redesignColors.red, fontSize: '14px', fontWeight: 500, borderRadius: '8px', color: theme.palette.common.white }}
                icon={<SvgIcon component={AlertIcon} sx={{ color: theme.palette.common.white }} />}
              >
                {t('e_prescriptions.details.expires_at', { expiresDays: 15 })}
              </Alert>
            </Grid>
          </Grid>
          {/* {pdfData && (
            <Grid item xs={12}>
              <iframe id='myIframeFile' src={fileURL} title={t('invoices.details.title_iframe')} style={{ border: 0 }} width='100%' height='800px' />
            </Grid>
          )} */}
        </CardContent>
      </Card>
      <ConfirmDialog
        title={t('e_prescriptions.details.delete_dialog.title')}
        subtitle={t('e_prescriptions.details.delete_dialog.subtitle')}
        submitTitle={t('common.action.delete')}
        cancelTitle={t('common.action.close')}
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={async () => api.ePrescriptions.cancelEprescription(1)}
      />
    </BodyTemplate>
  );
};

export default EprescriptionsDetails;
