import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { ReactComponent as NotificationIcon } from 'resources/icons/notification.svg';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CenterListItem from '../centers/components/CenterListItem';
import { ReactComponent as TimeUpdatesIcon } from 'resources/icons/time_updates.svg';
import { ReactComponent as AlertIcon } from 'resources/icons/alert.svg';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import dayjs from 'dayjs';
import { Center, CenterIncludedFile, CenterIncludedService, CenterIncludedUpdate, CenterUpdateCategory } from 'models/centers.model';
import api from 'api';
import { getStatus, getStatusDescription } from '../centers/utils';
import { useAuthenticated, useContextRedirection, useLocale, useNotification } from 'hooks';
import { redesignColors } from 'resources/theme/theme.colors';
import RoutePaths from 'utils/RoutePaths';
import useLastCheckLabUpdateDate from '../centers/useLastCheckUpdateDate';
import { Holiday } from 'models/holiday.model';

export type CenterUpdateInfo = {
  center: Center;
  file: CenterIncludedFile;
  services: CenterIncludedService[];
  updates: CenterIncludedUpdate[];
};

type UpdateItem = CenterIncludedUpdate & {
  status: string;
  statusDescription: string;
  title: string;
  centerId: string;
  created: string;
};
const getIcon = (category: CenterUpdateCategory) => {
  switch (category) {
    case CenterUpdateCategory.working_hours:
      return TimeUpdatesIcon;
    case CenterUpdateCategory.announcements:
      return AlertIcon;
    case CenterUpdateCategory.information:
      return InfoIcon;
    default:
      return null;
  }
};

const CentersUpdates: FC = () => {
  const { lastCheckLabUpdateDate, setLastCheckLabUpdateDate } = useLastCheckLabUpdateDate();

  const navigate = useContextRedirection();
  const { t } = useTranslation();
  const { notification } = useNotification();
  const locale = useLocale();

  const isAuthenticated = useAuthenticated();

  const [centerInfos, setCenterInfos] = useState<CenterUpdateInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isHolidaysLoading, setIsHolidaysLoading] = useState<boolean>(true);
  const [holidays, setHolidays] = useState<Holiday[]>([]);

  useEffect(() => {
    api.bnl
      .getCenters()
      .then((res) => {
        setCenterInfos(res);
      })
      .finally(() => setIsLoading(false));
  }, [isLoading]);

  useEffect(() => {
    api.holidays
      .getHolidays()
      .then(async (val) => {
        setHolidays(val);
      })
      .catch(() => notification(t('centers.notification.error'), 'error'))
      .finally(() => setIsHolidaysLoading(false));
  }, [locale, notification, t]);
  const updateItems = useMemo(() => {
    const items = centerInfos
      .map((centerInfo) => {
        const status = getStatus(centerInfo.center, holidays);
        const statusDescription = getStatusDescription(status, centerInfo.center.attributes.field_prelevement_horaires, t);

        return centerInfo?.updates?.map((updateItem) => ({ ...updateItem, status, statusDescription, title: centerInfo.center.attributes.title, centerId: centerInfo.center.id }));
      })
      .flat(1) as UpdateItem[];
    return items.filter(Boolean).sort((first, second) => new Date(second.attributes.created).getTime() - new Date(first.attributes.created).getTime());
  }, [centerInfos]);

  useEffect(() => {
    const firstItemCreated = updateItems[0]?.attributes?.created;
    if (!firstItemCreated && !lastCheckLabUpdateDate) {
      return;
    }
    const dateString = new Date().toISOString();
    if (firstItemCreated && !lastCheckLabUpdateDate) {
      setLastCheckLabUpdateDate(dateString);
      return;
    }
    if (lastCheckLabUpdateDate && firstItemCreated && new Date(firstItemCreated).getTime() > new Date(lastCheckLabUpdateDate).getTime()) {
      setLastCheckLabUpdateDate(dateString);
    }
  }, [updateItems]);

  const renderBody = () => {
    if ((isLoading || isHolidaysLoading) && !updateItems?.length) {
      return (
        <Box sx={{ margin: '15% auto 0' }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!updateItems?.length) {
      return (
        <Box mx={4} mt={2}>
          <Typography variant='body1'>{t('centers.updates.no_updates')}</Typography>
        </Box>
      );
    }
    return updateItems?.map((updateItem) => (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CenterListItem
              key={`update-item-${updateItem.id}`}
              icon={getIcon(updateItem.attributes.field_laboratory_category)}
              title={updateItem.title}
              timeLabel={dayjs(new Date(updateItem.attributes.created)).format('YYYY-MM-DD HH:MM')}
              status={updateItem.status}
              statusDescription={updateItem.statusDescription}
              onClick={() => navigate(`${RoutePaths['CENTERS']}?centerId=${updateItem.centerId}`)}
            />
          </Grid>
        </Grid>
      </Grid>
    ));
  };
  return (
    <BodyTemplate
      title={t('bodyTemplate.centers_updates')}
      backButton
      icon={NotificationIcon}
      breadcrumbs={[
        !isAuthenticated ? (
          <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths['LANDING_PAGE'])}>
            {t('bodyTemplate.home')}
          </Typography>
        ) : undefined,
        <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths['CENTERS'])}>
          {t('bodyTemplate.centers')}
        </Typography>,
        <Typography sx={{ color: redesignColors.grey.grey2 }}>{t('bodyTemplate.centers_updates')}</Typography>,
      ]}
    >
      <Grid container spacing={2}>
        {renderBody()}
      </Grid>
    </BodyTemplate>
  );
};

export default CentersUpdates;
