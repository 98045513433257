import { Box, Card, CardContent, CardHeader, IconButton, SvgIcon, Typography } from '@mui/material';
import { RelationUsualInfo } from 'models/profile.model';
import { ReactComponent as deleteIcon } from 'resources/icons/delete.svg';
import { FC, useState } from 'react';
import { convertDate } from 'utils/func/Date.func';
import { redesignColors } from 'resources/theme/theme.colors';
import ConfirmDialog from 'components/confirm-dialog';
import api from 'api';
import { useTranslation } from 'react-i18next';
import { useContextRedirection, useNotification } from 'hooks';
import RoutePaths from 'utils/RoutePaths';
import { getFullName } from 'utils/func/Person.func';

interface AccessCardProps {
  title: any;
  accessers: RelationUsualInfo[];
  emptyText: React.ReactNode;
  emptyAction?: React.ReactNode;
}

const AccessCard: FC<AccessCardProps> = ({ title, accessers, emptyText, emptyAction }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const navigate = useContextRedirection();
  const [modalRemoveRelative, openModalRemoveRelative] = useState(false);
  const [currentRelative, setCurrentRelative] = useState<RelationUsualInfo>({
    id: 0,
    first_name: '',
    last_name: '',
    birth_date: '',
    invitationId: 0,
  });

  const handleCloseRemoveModal = () => openModalRemoveRelative(false);

  const removeRelativeFromPatient = () => {
    api.patients
      .disableRelativeFromPatient(currentRelative.invitationId)
      .then(() => {
        notification(t('profile.results.notification.success'), 'success');
        handleCloseRemoveModal();
        navigate(RoutePaths['HOME']);
      })
      .catch(() => notification(t('profile.results.notification.error'), 'error'));
  };

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box mt={1}>
          {accessers.length ? (
            accessers.map((grantedAccess, i) => {
              return (
                <Box display='flex' justifyContent='space-between' alignItems='center' py={1} sx={{ borderBottom: `1px solid ${redesignColors.grey.grey4}` }}>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='body1' mr={1}>{`${grantedAccess.last_name} ${grantedAccess.first_name}`}</Typography>
                    {grantedAccess.birth_date && <Typography variant='body1'>{`- ${convertDate(grantedAccess.birth_date, false, 'yyyy-MM-dd')}`}</Typography>}
                  </Box>
                  <IconButton
                    onClick={() => {
                      openModalRemoveRelative(true);
                      setCurrentRelative(grantedAccess);
                    }}
                  >
                    <SvgIcon component={deleteIcon} sx={{ fill: redesignColors.red }} />
                  </IconButton>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography variant='body1' fontWeight='500' mb={1.5}>
                {emptyText}
              </Typography>
              <Typography variant='body1' fontWeight='500'>
                {emptyAction}
              </Typography>
            </Box>
          )}
        </Box>
        <ConfirmDialog
          title={t('profile.results.modal.title', {
            replace: { name: getFullName(currentRelative) },
          })}
          subtitle={t('profile.results.modal.subtitle')}
          submitTitle={t('common.action.delete')}
          open={modalRemoveRelative}
          onClose={() => openModalRemoveRelative(false)}
          onSubmit={() => removeRelativeFromPatient()}
        />
      </CardContent>
    </Card>
  );
};

export default AccessCard;
