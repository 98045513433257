import { Box, IconButton, SvgIcon } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconBack } from 'resources/icons/back.svg';


const BackButton: FC<{title: string}> = ({title}) => {
   const navigate = useNavigate();
   return (
      <Box sx={{
         padding: 0,
         display: 'flex',
         justifyContent: 'space-between',
         gap: '16px',
         color: 'black',
         fontWeight: 'bold',
      }} >
         <IconButton sx={{padding: 0}} onClick={() => navigate(-1)}>
            <SvgIcon component={IconBack} sx={{ fontSize: { xs: 16, sm: 24 } }} />
         </IconButton>

         {title}
      </Box>
   );
};

export default BackButton;