import { useEffect, useState } from 'react';
import { HIDE_RESULTS_REDESIGN } from 'utils/Constants';

const useHideRedesign = (): { hideRedesign: boolean | null; toggleRedesign: (value: boolean) => void } => {
  const [hideRedesign, setHideRedesign] = useState<boolean | null>(null);

  useEffect(() => {
    const storageItem = localStorage.getItem(HIDE_RESULTS_REDESIGN);

    if (storageItem) {
      const parsed = JSON.parse(storageItem) as boolean;

      setHideRedesign(parsed);
    }
  }, []);

  const toggleRedesign = (hide: boolean) => {
    const storageItem = localStorage.getItem(HIDE_RESULTS_REDESIGN) || JSON.stringify(null);
    const parsed = JSON.parse(storageItem) as boolean | undefined;
    if (parsed === undefined) {
      return;
    }
    setHideRedesign(hide);

    localStorage.setItem(HIDE_RESULTS_REDESIGN, JSON.stringify(hide));
  };

  return { hideRedesign, toggleRedesign };
};

export default useHideRedesign;
