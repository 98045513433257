import { LeftDrawerItem } from 'models/utils.model';
import { ReactComponent as BloodCenterIcon } from 'resources/icons/sidebar/blood-centers.svg';
import { ReactComponent as CatalogueAnalyseIcon } from 'resources/icons/sidebar/catalogu-analysis.svg';
import { ReactComponent as AppointmentIcon } from 'resources/icons/sidebar/appointment.svg';
import { ReactComponent as DoctorIcon } from 'resources/icons/doctor.svg';
import { ReactComponent as InvoiceIcon } from 'resources/icons/sidebar/invoices.svg';
import { ReactComponent as DashboardIcon } from 'resources/icons/sidebar/home.svg';
import { ReactComponent as MyDSPIcon } from 'resources/icons/sidebar/my-dsp.svg';
import { ReactComponent as ResultIcon } from 'resources/icons/sidebar/results.svg';
import { ReactComponent as NewsIcon } from 'resources/icons/sidebar/news.svg';
import { ReactComponent as CheckInIcon } from 'resources/icons/sidebar/check-in.svg';
import { ReactComponent as BionextTVIcon } from 'resources/icons/sidebar/bionext-tv.svg';
import { ReactComponent as FetrilityIcon } from 'resources/icons/sidebar/fertility.svg';
import { ReactComponent as PrescriptionsIcon } from 'resources/icons/sidebar/prescriptions.svg';
import { ReactComponent as FAQIcon } from 'resources/icons/sidebar/faq.svg';

export const leftDrawerMenuPatient = (locale: string): LeftDrawerItem[] => [
  {
    title: 'bodyTemplate.dashboard',
    link: 'HOME',
    icon: DashboardIcon,
  },
  {
    title: 'bodyTemplate.checkin',
    link: 'CHECKIN',
    icon: CheckInIcon,
  },
  {
    title: 'landing_page.card.appointment.title',
    link: 'APPOINTMENT',
    icon: AppointmentIcon,
  },
  {
    title: 'bodyTemplate.patient.blood_centers',
    link: 'CENTERS',
    icon: BloodCenterIcon,
  },

  {
    title: 'bodyTemplate.patient.fertility',
    link: 'FERTILITY',
    icon: FetrilityIcon,
  },

  {
    title: 'common.results',
    link: 'RESULTS',
    icon: ResultIcon,
  },
  {
    title: 'bodyTemplate.news',
    link: 'NEWS',
    icon: NewsIcon,
  },
  {
    title: 'bodyTemplate.patient.bionext_tv',
    link: 'BIONEXT_TV',
    icon: BionextTVIcon,
  },
  {
    title: 'bodyTemplate.doctor.analyses',
    link: 'ANALYSIS_CATALOG',
    icon: CatalogueAnalyseIcon,
  },
  {
    title: 'bodyTemplate.patient.invoices',
    link: 'INVOICES_OPEN',
    icon: InvoiceIcon,
  },
  {
    title: 'bodyTemplate.patient.e_prescriptions',
    link: 'PRESCRIPTIONS',
    icon: PrescriptionsIcon,
  },

  {
    title: 'bodyTemplate.patient.myDSP',
    link: 'MY_DSP',
    icon: MyDSPIcon,
  },
  {
    title: 'bodyTemplate.patient.find_doctor',
    link: 'FIND_DOCTOR',
    icon: DoctorIcon,
  },
  {
    title: 'bodyTemplate.faq',
    link: 'FAQ',
    icon: FAQIcon,
  },
];
