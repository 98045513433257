import { Theme } from '@emotion/react';
import { ArrowBack } from '@mui/icons-material';
import { Button, Divider, Grid, Stack, SxProps, ToggleButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useIsMobileView } from 'hooks';
import { FC } from 'react';

type TimeSlotsProps = {
  title: string;
  slots: string[];
  disabledSlots?: string[];
  date: Date;
  handleBack: () => void;
  setSelectedSlot: (time: string) => void;
  selectedSlot: string;
  sx?: SxProps<Theme>;
};

const TimeSlots: FC<TimeSlotsProps> = ({ title, slots, date, handleBack, selectedSlot, setSelectedSlot, disabledSlots, sx }) => {
  const isMobileView = useIsMobileView();

  return (
    <Stack
      alignSelf='center'
      spacing={4}
      justifyContent='center'
      alignItems='start'
      sx={{
        ...sx,
        width: {
          xs: '100%',
          sm: '80%',
          md: '50%',
        },
      }}
    >
      <Button onClick={handleBack} startIcon={<ArrowBack sx={{ fill: 'black', fontSize: '20px' }} />}>
        <Typography sx={{ fontWeight: 500, fontSize: '20px' }} variant='h3'>
          {dayjs(date).format('MMMM DD YYYY')}
        </Typography>
      </Button>
      <Stack spacing={1} justifyContent='center' alignItems='center' width='100%'>
        <Typography alignSelf='center' sx={{ fontWeight: 700, fontSize: '20px' }} variant='body1'>
          {title}
        </Typography>
        <Divider sx={{ mt: 0, pt: 0, width: '100%', border: '0.5px solid grey' }} />

        <Grid container spacing={1} width='100%'>
          {slots.map((slot, index) => (
            <Grid item key={index}>
              <ToggleButton
                key={slot + index}
                onChange={(e, val) => setSelectedSlot(val)}
                selected={slot === selectedSlot}
                disabled={disabledSlots?.includes(slot)}
                value={slot}
                size='medium'
                sx={(theme) => ({
                  borderRadius: '8px',
                  border: '0.5px solid grey',
                  width: '100px',
                  height: '50px',
                  backgroundColor: 'white',
                  '&.Mui-selected': {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.light,
                    },
                  },
                  '&.Mui-disabled': { opacity: '0.5' },
                })}
              >
                <Typography variant='button'>{slot}</Typography>
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default TimeSlots;
