import ContextLink from 'components/_base/ContextLink';
import Address from 'components/appointment/Address';
import ChoosePatients from 'components/appointment/ChoosePatients';
import CustomStepper from 'components/appointment/CustomStepper';
import { useAuthenticatedUser } from 'hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IntroductionStep from './IntroductionStep';
import AvailableSlotStep from './AvailableSlotStep';
import InformationStep from './InformationStep';
import PrescriptionsStep from 'components/appointment/PrescriptionsStep';
import { useStore } from 'react-redux';
import SummaryStep from './SummaryStep';

const NewAppointment: FC = () => {
  const authenticatedUser = useAuthenticatedUser();
  const { t } = useTranslation();
  
  const steps = [
    {
      label: 'Introduction',
      component: authenticatedUser ? ChoosePatients : IntroductionStep,
      wrapStep: true,
    },
    {
      label: 'Address',
      component: Address,
      wrapStep: true,
      props: {
        isCheckin: false,
      },
    },
    {
      label: 'Select available slot',
      component: AvailableSlotStep,
      wrapStep: true,
    },
    {
      label: 'Information',
      component: InformationStep,
      wrapStep: true,
    },
    {
      label: 'Prescription',
      component: PrescriptionsStep,
      wrapStep: true,
      props: {
        isNewAppointment: true,
      }
    },
    {
      label: 'Summary',
      component: SummaryStep,
      wrapStep: true,
    }
  ];

  const breadcrumbs = [<ContextLink to='HOME'>{t('new_appointment.breadcrumbs.home')}</ContextLink>, <ContextLink to='APPOINTMENT'>{t('new_appointment.title')}</ContextLink>];

  return <CustomStepper steps={steps} showBreadcrumb={authenticatedUser ? false : true} breadcrumbs={breadcrumbs} />;
};

export default NewAppointment;
